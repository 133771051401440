import React, {useState, useEffect, JSX} from 'react';
import { useParams } from 'react-router-dom';
import { Phone, Clock, User } from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';
import { useLocation } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

interface Callback {
    id: string;
    proId: string;
    proName: string;
    timestamp: string;
    status: string;
    clientId: string;
    clientName: string;
    clientPhone: string;
    reasone?: string;
}

export default function Callbacks(): JSX.Element {
    const { userId } = useParams();
    const [callbacks, setCallbacks] = useState<Callback[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<'pending' | 'completed'>('pending');
    const [isPro, setIsPro] = useState<boolean>(false); // To track user role
    const location = useLocation();
    const { callbackRequest } = location.state || {}; //
    
    useEffect(() => {
        const fetchCallbacks = async () => {
            try {
                const apiUrl = callbackRequest === 'client'
                ? `${API_URL}/api/callbacks-client/${userId}`
                : `${API_URL}/api/callbacks/${userId}`;
                const response = await fetch(apiUrl);
                if (!response.ok) throw new Error('Failed to fetch callbacks');
                const data = await response.json();
                 // Sort callbacks by timestamp in descending order (latest first)
                const sortedData = data.sort((a: Callback, b: Callback) => 
                    new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                );
                console.log(data)
                setCallbacks(sortedData);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to load callbacks');
            } finally {
                setLoading(false);
            }
        };

        fetchCallbacks();
    }, [userId, callbackRequest]);

    const handleMarkCompleted = async (callbackId: string) => {
        try {
            const response = await fetch(`${API_URL}/api/callbacks/${callbackId}/complete`, {
                method: 'POST',
            });
            if (!response.ok) throw new Error('Failed to update callback status');

            // Update local state
            setCallbacks(callbacks.map(callback =>
                callback.id === callbackId
                    ? { ...callback, status: 'completed' }
                    : callback
            ));
        } catch (err) {
            alert(err instanceof Error ? err.message : 'Failed to update callback status');
        }
    };

    const formatRequestTime = (time: string) => {
        const date = new Date(time);
        return date.toLocaleString([], {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const pendingCallbacks = callbacks.filter(callback => callback.status === 'pending');
    const completedCallbacks = callbacks.filter(callback => callback.status === 'completed');

    return (
        <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
            <div className="w-full max-w-md space-y-6 py-12">
                {/* Logo and Title */}
                <div className="flex flex-col items-center mb-8">
                    <div className="relative w-32 h-32 mb-6">
                        <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
                        <img
                            src={klickieLogo}
                            alt="Klickie"
                            className="absolute inset-0 w-full h-full object-contain rounded-full z-10"
                        />
                    </div>
                    <h1 className="text-[#FFD700] text-4xl text-center font-['Klickie_Font',_Arial,_sans-serif] mb-4">
                        Callback Requests as {callbackRequest ? 'Customer' : 'Pro'}
                    </h1>
                </div>

                {/* Tab Buttons */}
                <div className="flex space-x-4 mb-6">
                    <button
                        onClick={() => setActiveTab('pending')}
                        className={`flex-1 py-2 px-4 rounded-xl font-semibold ${
                            activeTab === 'pending'
                                ? 'bg-[#4895AA] text-white'
                                : 'bg-white/10 text-white/60'
                        }`}
                    >
                        Pending
                    </button>
                    <button
                        onClick={() => setActiveTab('completed')}
                        className={`flex-1 py-2 px-4 rounded-xl font-semibold ${
                            activeTab === 'completed'
                                ? 'bg-[#4895AA] text-white'
                                : 'bg-white/10 text-white/60'
                        }`}
                    >
                        Completed
                    </button>
                </div>

                {loading ? (
                    <div className="text-white text-center">Loading callbacks...</div>
                ) : error ? (
                    <div className="text-red-500 text-center">{error}</div>
                ) : (
                    <div className="space-y-4">
                        {activeTab === 'pending' ? (
                            pendingCallbacks.length > 0 ? (
                                pendingCallbacks.map((callback) => (
                                    <div
                                        key={callback.id}
                                        className="bg-[#1A202C] rounded-xl p-4 space-y-2"
                                    >
                                        <div className="flex items-center space-x-2 text-[#FFD700]">
                                            <User className="w-5 h-5" />
                                            <span className="font-semibold">
                                                {/* {callback.proName} */}
                                                {callbackRequest ? callback.proName : callback.clientName}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-white/80">
                                            <Phone className="w-4 h-4" />
                                            <a
                                                href={`tel:${callback.proId}`}
                                                className="hover:text-[#FFD700]"
                                            >
                                                {/* {callback.proId} */}
                                                {callbackRequest ? callback.proId : callback.clientPhone}
                                            </a>
                                        </div>
                                        <div className="flex items-center space-x-2 text-white/60">
                                            <Clock className="w-4 h-4" />
                                            <span>Requested: {formatRequestTime(callback.timestamp)}</span>
                                        </div>
                                        {callback.reasone && (
                                            <div className="text-white/80 bg-white/5 p-2 rounded">
                                                {callback.reasone}
                                            </div>
                                        )}
                                        {callbackRequest !== 'client' && (
                                        <button
                                            onClick={() => handleMarkCompleted(callback.id)}
                                            className="w-full mt-2 bg-green-500/10 hover:bg-green-500/20 text-green-500 py-2 rounded-lg transition-colors"
                                        >
                                            Mark as Called
                                        </button>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="text-white/60 text-center py-8">
                                    No pending callbacks
                                </div>
                            )
                        ) : (
                            completedCallbacks.length > 0 ? (
                                completedCallbacks.map((callback) => (
                                    <div
                                        key={callback.id}
                                        className="bg-[#1A202C] rounded-xl p-4 space-y-2"
                                    >
                                        <div className="flex items-center space-x-2 text-[#FFD700]">
                                            <User className="w-5 h-5" />
                                            <span className="font-semibold">
                                                {/* {callback.proName} */}
                                                {callbackRequest ? callback.proName : callback.clientName}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-white/80">
                                            <Phone className="w-4 h-4" />
                                            <span>
                                                {/* {callback.proId} */}
                                                {callbackRequest ? callback.proId : callback.clientPhone}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-2 text-white/60">
                                            <Clock className="w-4 h-4" />
                                            <span>Requested: {formatRequestTime(callback.timestamp)}</span>
                                        </div>
                                        {callback.reasone && (
                                            <div className="text-white/80 bg-white/5 p-2 rounded">
                                                {callback.reasone}
                                            </div>
                                        )}
                                        <div className="text-sm text-green-500">
                                            Completed
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-white/60 text-center py-8">
                                    No completed callbacks
                                </div>
                            )
                        )}
                    </div>
                )}

                {/* Back Button */}
                <button
                    onClick={() => window.location.href = `/what-now/${userId}`}
                    className="w-full relative group mt-8"
                >
                    <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                    <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                        <div className="flex items-center justify-center">
                            <span>BACK</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
}