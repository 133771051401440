import React, {JSX, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Calendar, Clock, User } from 'lucide-react';

interface Cancellation {
    id: string;
    bookingId: string;
    userId: string;
    proId: string;
    proName: string;
    clientName: string;
    serviceName: string;
    startTime: string;
    endTime: string;
    reason?: string;
    status: 'pending' | 'approved' | 'rejected';
    timestamp: string;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

export default function Cancellations(): JSX.Element {
    const { userId } = useParams();
    const [cancellations, setCancellations] = useState<Cancellation[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchCancellations = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/cancellations/${userId}`);
                setCancellations(response.data || []);
            } catch (error) {
                console.error('Error fetching cancellations:', error);
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    setCancellations([]);
                } else {
                    setError('Failed to load cancellations');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchCancellations();
    }, [userId]);

    const handleAction = async (cancellationId: string, action: 'approve' | 'reject') => {
        try {
            await axios.post(`${API_URL}/api/cancellations/${cancellationId}/${action}`);
            // Refresh the list
            const response = await axios.get(`${API_URL}/api/cancellations/${userId}`);
            setCancellations(response.data);
        } catch (error) {
            console.error('Error handling cancellation:', error);
            setError('Failed to process cancellation');
        }
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    if (loading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    if (error) return <div className="flex justify-center items-center min-h-screen text-red-500">{error}</div>;

    return (
        <div className="min-h-screen bg-[#1E2738] text-white p-8">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8 text-center">Cancellations</h1>

                {cancellations.length === 0 ? (
                    <div className="text-center bg-[#4895AA]/20 p-8 rounded-xl">
                        <h2 className="text-2xl font-semibold mb-4">No Cancellations</h2>
                        <p className="text-gray-300">
                            You don't have any cancellation requests at the moment.
                        </p>
                    </div>
                ) : (
                    <div className="space-y-6">
                        {cancellations.map((cancellation) => (
                            <div
                                key={cancellation.id}
                                className="bg-[#4895AA]/10 p-6 rounded-xl relative group"
                            >
                                <div className="absolute inset-0 bg-[#FCBF11]/5 rounded-xl translate-x-1 translate-y-1 -z-10"></div>
                                
                                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
                                    <div className="flex-1">
                                        <h3 className="text-xl font-semibold mb-2">{cancellation.serviceName}</h3>
                                        <div className="space-y-2 text-gray-300">
                                            <div className="flex items-center">
                                                <Calendar className="w-5 h-5 mr-2" />
                                                {formatDate(cancellation.startTime)}
                                            </div>
                                            <div className="flex items-center">
                                                <Clock className="w-5 h-5 mr-2" />
                                                {formatTime(cancellation.startTime)} - {formatTime(cancellation.endTime)}
                                            </div>
                                            <div className="flex items-center">
                                                <User className="w-5 h-5 mr-2" />
                                                {cancellation.clientName}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="mt-4 md:mt-0 md:ml-6">
                                        <div className={`px-4 py-2 rounded-full text-sm font-semibold
                                            ${cancellation.status === 'pending' ? 'bg-yellow-500/20 text-yellow-300' : 
                                              cancellation.status === 'approved' ? 'bg-green-500/20 text-green-300' :
                                              'bg-red-500/20 text-red-300'}`}
                                        >
                                            {cancellation.status.toUpperCase()}
                                        </div>
                                    </div>
                                </div>

                                {cancellation.reason && (
                                    <div className="mt-4 p-4 bg-white/5 rounded-lg">
                                        <p className="text-sm text-gray-300">
                                            <span className="font-semibold">Reason:</span> {cancellation.reason}
                                        </p>
                                    </div>
                                )}

                                {cancellation.status === 'pending' && (
                                    <div className="mt-6 flex space-x-4">
                                        <button
                                            onClick={() => handleAction(cancellation.id, 'approve')}
                                            className="flex-1 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg transition-colors"
                                        >
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => handleAction(cancellation.id, 'reject')}
                                            className="flex-1 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg transition-colors"
                                        >
                                            Reject
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
} 
