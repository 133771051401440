import React, { useState } from 'react';
import authService, { Country } from '../services/authService';
import { countries } from '../utils/countries';
import PhoneInput from './PhoneInput';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

interface LoginModalProps {
  onLogin: (userId: string) => void;
  onClose: () => void;
  klickieUserName: string | null;
  isStaffLogin?: boolean;
}

export default function LoginModal({ onLogin, onClose, isStaffLogin = false, klickieUserName }: LoginModalProps) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedNumber, setFormattedNumber] = useState('');
  const [country, setCountry] = useState('BE');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [showNameInput, setShowNameInput] = useState(false);

  const formatPhoneNumber = (phone: string, countryCode: string) => {
    // Remove all non-digits
    const cleanPhone = phone.replace(/\D/g, '');
    // Remove leading 0 if present
    const withoutLeadingZero = cleanPhone.startsWith('0') ? cleanPhone.substring(1) : cleanPhone;
    // Get country prefix (e.g., "32" for BE)
    const selectedCountry = countries.find(c => c.code === countryCode);
    const prefix = selectedCountry?.prefix.replace('+', '') || '';
    // Return formatted number with + and country code
    return `+${prefix}${withoutLeadingZero}`;
  };

  const handleSubmitPhone = async () => {
    if (!formattedNumber) return;
    setError(null);

    // Always ensure phone number is normalized without leading 0
    const normalizedPhone = phoneNumber.replace(/\D/g, '').replace(/^0+/, '');
    const selectedCountry = countries.find(c => c.code === country);
    if (!selectedCountry) {
      setError('Invalid country selected');
      return;
    }

    try {
      if (isStaffLogin) {
        // Staff verification flow
        try {
          const response = await axios.post(`${API_URL}/api/verify-staff-status`, {
            phoneNumber: formattedNumber
          });

          if (response.data.success) {
            setShowVerification(true);
          } else {
            setError(response.data.error || 'Failed to send verification code');
          }
        } catch (err: any) {
          console.error('Error sending staff verification:', err);
          setError(err.response?.data?.error || 'Failed to send verification code');
        }
      } else {
        // Send verification code for existing customers
        try {
          await authService.requestVerificationCode(normalizedPhone, selectedCountry, 'customer', klickieUserName || undefined);
          setShowVerification(true);
          // Track phone number submission event
          if (window.clarity) {
            window.clarity("event", "phone_number_submitted", {
              userId: localStorage.getItem("userId") || formattedNumber || "unknown",
              phoneNumber: formattedNumber,
              klickieLoginUserName: klickieUserName
            });
          }
        } catch (err: any) {
          if (err.response?.status === 404) {
            // Instead of redirecting to WhatsApp, show name input
            setShowNameInput(true);
            setIsExistingCustomer(false);
          } else {
            setError('Failed to send verification code');
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred');
    }
  };

  const handleSubmitNewUser = async () => {
    if (!name || !phoneNumber) return;
    setError(null);

    try {
      const selectedCountry = countries.find(c => c.code === country);
      if (!selectedCountry) {
        setError('Invalid country selected');
        return;
      }

      const formattedPhone = formatPhoneNumber(phoneNumber, country);

      // Create new user directly
      const response = await axios.post(`${API_URL}/api/users`, {
        name,
        phoneNumber: formattedPhone,
        userType: 'PRO'
      });

      if (response.data.success) {
        localStorage.setItem('userId', formattedPhone);
        onLogin(formattedPhone);
        onClose();
      } else {
        setError('Failed to create account');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred');
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) return;
    setError(null);

    try {
      const selectedCountry = countries.find(c => c.code === country);
      if (!selectedCountry) {
        setError('Invalid country selected');
        return;
      }

      // Use the same normalized phone number format as when sending the code
      const normalizedPhone = phoneNumber.replace(/\D/g, '').replace(/^0+/, '');

      if (isStaffLogin) {
        // Staff verification flow
        try {
          const response = await axios.post(`${API_URL}/api/verify-staff-status`, {
            phoneNumber: formattedNumber,
            code: verificationCode
          });

          if (response.data.success && response.data.isStaff) {
            // Track successful verification event
            if (window.clarity) {
              window.clarity("event", "staff_verification_success", {
                userId: localStorage.getItem("userId") || "unknown",
              });
            }
            // Store session token and user data
            if (response.data.sessionToken) {
              localStorage.setItem('sessionToken', response.data.sessionToken);
            }
            if (response.data.user) {
              localStorage.setItem('userData', JSON.stringify(response.data.user));
            }
            // Only call onLogin if both session token and user data are stored
            if (response.data.sessionToken && response.data.user) {
              onLogin(formattedNumber);
              onClose();
            } else {
              setError('Invalid login response. Please try again.');
              if (window.clarity) {
                window.clarity("event", "error", {
                  userId: localStorage.getItem("userId") || "unknown",
                  error: 'Invalid login response. Please try again.',
                  type: 'staff_login_error',
                });
              }
            }
          } else {
            setError(response.data.error || 'Not authorized as staff');
            if (window.clarity) {
              window.clarity("event", "error", {
                userId: localStorage.getItem("userId") || "unknown",
                error: response.data.error || 'Not authorized as staff',
                type: 'staff_authorization_error',
              });
            }
          }
        } catch (err: any) {
          console.error('Error verifying staff code:', err);
          setError(err.response?.data?.error || 'Failed to verify code');
          if (window.clarity) {
            window.clarity("event", "error", {
              userId: localStorage.getItem("userId") || "unknown",
              error: err.response?.data?.error || 'Failed to verify code',
              type: 'staff_verification_error',
            });
          }
        }
      } else {
        // Regular customer verification flow
        const response = await authService.verifyCode(normalizedPhone, selectedCountry, verificationCode);
        if (response.success) {
          const fullPhoneNumber = authService.normalizePhoneNumber(normalizedPhone, selectedCountry);
          const url = window.location.href;
          if (url.includes("?")) {
            console.log("Question mark (?) is present in the URL");
            const cleanUrl = window.location.href.split("?")[0]; // Get the part before `?`
            // Update the browser URL without reloading
            window.history.replaceState(null, "", cleanUrl);
            console.log("Updated URL:", cleanUrl);
          } else {
            console.log("No question mark (?) found in the URL");
          }
          localStorage.setItem('userId', fullPhoneNumber);
          onLogin(fullPhoneNumber);
          onClose();
          // Track successful verification event
          if (window.clarity) {
            window.clarity("event", "customer_verification_success", {
              userId: fullPhoneNumber,
              phoneNumber: formattedNumber,
            });
          }
        }
        else {
          const errorMessage = response.error || 'Verification failed';
          if (window.clarity) {
            window.clarity("event", "error", {
              userId: localStorage.getItem("userId") || "unknown",
              error: errorMessage,
              type: 'customer_verification_error',
            });
          }
          setError(errorMessage);
        }
      }
    } catch (err) {
      console.error('Verification error:', err);
      setError('Invalid verification code');
      if (window.clarity) {
        window.clarity("event", "error", {
          userId: localStorage.getItem("userId") || "unknown",
          error: 'Unexpected error occurred during verification',
          type: 'unexpected_verification_error',
        });
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1E2738] rounded-3xl p-8 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          ✕
        </button>

        <h2 className="text-2xl font-bold text-[#FFD700] mb-6 text-center">
          {isStaffLogin ? "Staff Login" : "Please Identify"}
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500 rounded-xl text-red-500 text-sm">
            {error}
          </div>
        )}

        {!showVerification && !showNameInput && (
          <div className="space-y-4">
            <PhoneInput
              country={country}
              setCountry={setCountry}
              phoneNumber={phoneNumber}
              handlePhoneChange={(e) => setPhoneNumber(e.target.value)}
              isValid={phoneNumber.length > 0}
              onFormatted={setFormattedNumber}
            />
            <button
              onClick={handleSubmitPhone}
              className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
            >
              Continue
            </button>
          </div>
        )}

        {showNameInput && (
          <div className="space-y-4">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              className="w-full bg-[#2A3447] text-white py-3 px-6 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#4895AA]"
            />
            <button
              onClick={handleSubmitNewUser}
              className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
            >
              Create Account
            </button>
          </div>
        )}

        {showVerification && (
          <div className="space-y-4">
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter verification code"
              className="w-full bg-[#2A3447] text-white py-3 px-6 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#4895AA]"
            />
            <button
              onClick={handleVerifyCode}
              className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
            >
              Verify
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
