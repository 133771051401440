import React, { useState, useEffect, useRef } from 'react';
import { countries } from '../utils/countries';

interface PhoneInputProps {
    country: string;
    setCountry: (country: string) => void;
    phoneNumber: string;
    handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isValid: boolean;
    onFormatted?: (formattedNumber: string) => void;
    handleSubmit?: () => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
    country,
    setCountry,
    phoneNumber,
    handlePhoneChange,
    isValid,
    onFormatted
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Format the number for display
    const getInternationalFormat = () => {
        if (!phoneNumber) return '';
        const selectedCountry = countries.find(c => c.code === country);
        if (!selectedCountry) return '';
        
        // Remove all non-digits
        const cleaned = phoneNumber.replace(/\D/g, '');
        // Remove leading zero if present
        const withoutLeadingZero = cleaned.startsWith('0') ? cleaned.substring(1) : cleaned;
        
        return `${selectedCountry.prefix}${withoutLeadingZero}`;
    };

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                buttonRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // Call onFormatted whenever the formatted number changes
    useEffect(() => {
        const formatted = getInternationalFormat();
        if (formatted && onFormatted) {
            onFormatted(formatted);
        }
    }, [phoneNumber, country, onFormatted]);

    const handleCountryClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleCountrySelect = (code: string) => {
        setCountry(code);
        setIsOpen(false);
    };

    return (
        <div className="space-y-2" ref={containerRef}>
            <label className="block text-gray-300 mb-2">Phone Number</label>
            <div className="relative rounded-xl border border-black p-0.5 bg-gradient-to-r from-[#4895AA] to-[#4895AA]">
                <div className="absolute -bottom-1 -right-1 w-[calc(100%-2px)] h-[calc(100%-2px)] bg-[#FCBF11] rounded-xl -z-10"></div>
                <div className="absolute -bottom-1.5 -right-1.5 w-[calc(100%+2px)] h-[calc(100%+2px)] bg-[#FCBF11] rounded-xl -z-20"></div>
                <div className="flex bg-white rounded-[10px] border border-black overflow-hidden h-[50px]">
                    <div className="relative w-[80px] border-r border-black">
                        <button
                            ref={buttonRef}
                            type="button"
                            onClick={handleCountryClick}
                            className="w-full h-[50px] px-2 text-center focus:outline-none bg-transparent transition-colors hover:bg-[#4895AA]/10"
                            style={{
                                fontFamily: "'Good Headline Pro Cond', sans-serif",
                                fontSize: '20px'
                            }}
                        >
                            {country}
                        </button>
                        
                        {isOpen && (
                            <div 
                                ref={dropdownRef}
                                className="fixed w-[120px] bg-[#1E2738] border border-black rounded-xl shadow-lg overflow-y-auto"
                                style={{
                                    top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom + 2 : 0,
                                    left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : 0,
                                    maxHeight: '250px',
                                    zIndex: 9999
                                }}
                            >
                                <div className="relative p-0.5 bg-gradient-to-r from-[#4895AA] to-[#4895AA] rounded-xl">
                                    <div className="absolute -bottom-1 -right-1 w-[calc(100%-2px)] h-[calc(100%-2px)] bg-[#FCBF11] rounded-xl -z-10"></div>
                                    <div className="absolute -bottom-1.5 -right-1.5 w-[calc(100%+2px)] h-[calc(100%+2px)] bg-[#FCBF11] rounded-xl -z-20"></div>
                                    <div className="bg-[#1E2738] rounded-[10px] py-1">
                                        {countries.map((c) => (
                                            <button
                                                type="button"
                                                key={c.code}
                                                onClick={() => handleCountrySelect(c.code)}
                                                className={`w-full px-4 py-2 text-center transition-colors text-white
                                                    ${country === c.code ? 'bg-[#4895AA]/20' : 'hover:bg-[#4895AA]/10'}`}
                                                style={{
                                                    fontFamily: "'Good Headline Pro Cond', sans-serif",
                                                    fontSize: '18px'
                                                }}
                                            >
                                                {c.code}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        placeholder="123456789"
                        className="w-full h-[50px] px-4 text-left border-0 rounded-none focus:ring-0 bg-transparent"
                        style={{
                            fontFamily: "'Good Headline Pro Cond', sans-serif",
                            fontSize: '20px'
                        }}
                    />
                </div>
            </div>
            {phoneNumber && (
                <div className="text-sm text-gray-400">
                    Will be saved as: <span className="font-medium">{getInternationalFormat()}</span>
                </div>
            )}
        </div>
    );
};

export default PhoneInput;