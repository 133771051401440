// OnboardingPro Component - Dashboard Style Interface
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Minus, Plus, ChevronDown, ChevronUp, Check, X, ArrowLeft, Edit, Save, Trash2, Grid, User, Calendar, DollarSign, MapPin, Clock, Lightbulb, ChevronRight } from "lucide-react";

// Define the Service interface
interface Service {
  service_name: string;
  service_type: "Private";
  price: number;
  duration: number;
  quota: number;
  frequency: "One-time" | "Recurring";
  rule: "Without-consent" | "Consent";
  // optimizationStatus: "Without-optimization" | "With-optimization";
  // extraCost: number;
  address: string;
  description?: string; // Add description property as optional
}

// Define UI state interfaces
interface ExpandedSections {
  profileInfo: boolean;
  services: boolean;
}

interface EditingStates {
  [key: number]: {
    isEditingPrice: boolean;
    tempPrice: string;
    isEditingDuration: boolean;
    tempDuration: string;
    isEditingName: boolean;
    tempName: string;
    isExpanded: boolean;
  };
}

// Updated InputWrapper with cleaner styling
const InputWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="relative mb-3">
    <div className="absolute inset-0 bg-[#1A202C] rounded-lg z-10"></div>
    <div className="absolute inset-0 border border-gray-600 rounded-lg z-20"></div>
    {children}
  </div>
);

// Card component for dashboard style
const Card: React.FC<{ 
  title?: string; 
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}> = ({ title, children, className = "", onClick }) => (
  <div 
    className={`bg-[#1A202C] rounded-xl shadow-sm p-4 ${className} ${onClick ? 'cursor-pointer hover:shadow-md transition-shadow' : ''}`}
    onClick={onClick}
  >
    {title && <h3 className="text-xl font-semibold mb-3 text-white">{title}</h3>}
    {children}
  </div>
);

// Add predefined activities
const PREDEFINED_ACTIVITIES = [
  "Dog Walker",
  "Babysitter",
  "Padel Teacher",
  "Lawyer",
  "Plumber",
  "Electrician",
  "Tennis Teacher",
  "Piano Teacher",
  "Personal Trainer",
  "Yoga Instructor",
  "Language Teacher",
  "Math Tutor",
  "Swimming Instructor",
  "Life Coach",
  "Massage Therapist",
  "Guitar Teacher",
  "Dance Instructor",
  "Football Coach",
  "Basketball Coach",
  "Fitness Trainer",
  "Nutritionist",
  "Golf Instructor",
  "Boxing Coach",
  "Meditation Guide",
  "Art Teacher",
];

const OnboardingPro: React.FC = () => {
  // Revert to the original API URL
  const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

  const { userId } = useParams() as { userId: string };
  const [filteredActivities, setFilteredActivities] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  
  // State variables
  const [activity, setActivity] = useState("");
  const [services, setServices] = useState<Service[]>([]);
  const [profileName, setProfileName] = useState("");
  const [errors, setErrors] = useState<{
    [key: number]: { [field: string]: string };
  }>({});
  const [profileErrors, setProfileErrors] = useState<{
    [field: string]: string;
  }>({});

  const [isNewUser, setIsNewUser] = useState(true); // New user check
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState("0");
  
  const [proName, setProName] = useState("");
  const [error, setError] = useState("");
  
  // UI state for dashboard
  const [activeServiceIndex, setActiveServiceIndex] = useState<number | null>(null);
  const [isAddingService, setIsAddingService] = useState(false);
  
  // State for tracking unsaved changes
  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Replace the single editing states with objects keyed by service index
  const [editingStates, setEditingStates] = useState<EditingStates>({});

  const [servicesActivated, setServicesActivated] = useState<boolean>(false);

  const navigate = useNavigate();

  // Fetch existing user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/api/profile/${userId}`);
        const userData = response.data;
        console.log("User data:", userData);
        
        if (userData?.activity) {
          setActivity(userData.activity);
        }

        // Set the name regardless of whether activity exists
        if (userData?.name) {
          setProName(userData.name);
        }
       
        if (userData?.profileName) {
          setProfileName(userData.profileName);
        } else if (userData?.activity) {
          setProfileName(`${userData.activity} lessons`);
        }

        if (userData?.services?.length > 0) {
          console.log("Original services from API:", userData.services);
          // Map the service properties from the API response format to the Service interface format
          const mappedServices = userData.services.map((service: any) => {
            console.log(`Service ${service.name} rule from API:`, service.rule);
            return {
              service_name: service.name || "",
              service_type: "Private",
              price: service.price || 0,
              duration: service.duration || 0,
              quota: 0,
              frequency: "One-time" as const,
              rule: service.rule || "Without-consent", // Use the rule from the API response
              address: "",
              description: service.description || ""
            };
          });
          console.log("Mapped services:", mappedServices);
          setServices(mappedServices);
          setIsNewUser(false); // Mark as existing user
          setServicesActivated(true); // Automatically activate services section
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  // Function to toggle service expansion
  const toggleServiceExpansion = (index: number) => {
    setActiveServiceIndex(prev => prev === index ? -1 : index);
    
    // Initialize editing state for this service if it doesn't exist
    if (!editingStates[index]) {
      const service = services[index];
      setEditingStates(prev => ({
        ...prev,
        [index]: {
          isEditingPrice: false,
          tempPrice: service.price.toString(),
          isEditingDuration: false,
          tempDuration: service.duration.toString(),
          isEditingName: false,
          tempName: service.service_name,
          isExpanded: true
        }
      }));
    }
    
    setHasChanges(true);
  };

  // Check for unsaved changes
  const checkUnsavedChanges = () => {
    if (hasChanges) {
      setShowUnsavedChangesModal(true);
      return true;
    }
    return false;
  };

  // Handle back button click
  const handleBack = () => {
    if (hasChanges) {
      setShowUnsavedChangesModal(true);
    } else {
      navigate(-1);
    }
  };

  // Go back without saving
  const goBackWithoutSaving = () => {
    setShowUnsavedChangesModal(false);
    navigate(-1);
  };

  // Set hasChanges to true whenever a state change occurs
  useEffect(() => {
    setHasChanges(true);
  }, [activity, services, profileName, proName]);

  const handleActivityChange = (input: string) => {
    setActivity(input);

    // Clear activity-related error
    if (input.trim()) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        // Remove activity error if it exists in any service
        Object.keys(newErrors).forEach((key) => {
          if (
            newErrors[Number(key)]["activity"] === "Please enter your activity."
          ) {
            delete newErrors[Number(key)]["activity"];
            // Remove the service entry if it has no more errors
            if (Object.keys(newErrors[Number(key)]).length === 0) {
              delete newErrors[Number(key)];
            }
          }
        });
        return newErrors;
      });
    }

    if (input.length > 0) {
      const filtered = PREDEFINED_ACTIVITIES.filter((act) =>
        act.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredActivities(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredActivities([]);
      setShowSuggestions(false);
    }
  };

  const handleActivitySelect = (selectedActivity: string) => {
    setActivity(selectedActivity);
    setProfileName(`${selectedActivity} lessons`);
    setShowSuggestions(false);
  };

  // Validate the form inputs
  const validateForm = () => {
    let isValid = true;
    const newErrors: { [key: number]: { [key: string]: string } } = {};
    const newProfileErrors: { [key: string]: string } = {};

    // Validate profile name
    if (!proName?.trim()) {
      newProfileErrors.name = "Professional name is required";
      isValid = false;
    }

    // Activity is now optional, so we don't validate it
    // if (!activity.trim()) {
    //   newProfileErrors.activity = "Activity is required";
    //   isValid = false;
    // }

    // Validate services
    if (!services || services.length === 0) {
      // If no services, we should add at least one
      newProfileErrors.services = "At least one service is required";
      isValid = false;
    } else {
      services.forEach((service, index) => {
        newErrors[index] = {};

        // Check if service_name exists and is not empty
        if (!service?.service_name?.trim()) {
          newErrors[index].service_name = "Service name is required";
          isValid = false;
        }

        // Check if price is a valid number and not negative
        if (service?.price === undefined || service?.price === null || service?.price < 0) {
          newErrors[index].price = "Price cannot be negative";
          isValid = false;
        }

        // Check if duration is a valid number and greater than zero
        if (service?.duration === undefined || service?.duration === null || service?.duration <= 0) {
          newErrors[index].duration = "Duration must be greater than 0";
          isValid = false;
        }

        // Ensure rule is set properly
        if (!service?.rule || (service.rule !== "Consent" && service.rule !== "Without-consent")) {
          // Default to "Without-consent" if not set properly
          setServices(prevServices => {
            const updatedServices = [...prevServices];
            if (updatedServices[index]) {
              updatedServices[index] = {
                ...updatedServices[index],
                rule: "Without-consent"
              };
            }
            return updatedServices;
          });
        }
      });
    }

    console.log("Validation errors:", newErrors, newProfileErrors);
    setErrors(newErrors);
    setProfileErrors(newProfileErrors);
    return isValid;
  };

  // Handle adding a new service
  const addService = () => {
    const newIndex = services.length;
    setServices([
      ...services,
      {
        service_name: "",
        service_type: "Private",
        price: 0,
        duration: 0,
        quota: 1,
        frequency: "One-time" as "One-time" | "Recurring",
        rule: "Without-consent" as "Without-consent" | "Consent", // Explicitly set the rule
        // optimizationStatus: "Without-optimization",
        // extraCost: 0,
        address: "",
        description: ""
      },
    ]);
    setServicesActivated(true); // Automatically activate services section
    
    // Automatically expand the new service for editing
    setTimeout(() => {
      setActiveServiceIndex(newIndex);
      // Initialize editing state for this service
      setEditingStates(prev => ({
        ...prev,
        [newIndex]: {
          isEditingPrice: false,
          tempPrice: "0",
          isEditingDuration: false,
          tempDuration: "0",
          isEditingName: false,
          tempName: "",
          isExpanded: true
        }
      }));
      
      // Set validation error for the new service
      setErrors(prev => ({
        ...prev,
        [newIndex]: {
          service_name: "Service name is required"
        }
      }));
    }, 100);
  };

  // Handle removing a service
  const removeService = (index: number) => {
      setServices(services.filter((_, i) => i !== index));
  };

  // Handle changes in a service field
  const handleServiceChange = (
    index: number,
    field: keyof Service,
    value: string | number
  ) => {
    // Update the services array
    setServices((prevServices) => {
      const updatedServices = [...prevServices];
      updatedServices[index] = {
        ...updatedServices[index],
        [field]: value,
      };
      return updatedServices;
    });

    // Clear any errors for this field
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[index] && updatedErrors[index][field]) {
        delete updatedErrors[index][field];
      }
      return updatedErrors;
    });

    // Update editing state if needed
    if (field === "service_name") {
      setEditingStates(prev => ({
        ...prev,
        [index]: {
          ...prev[index],
          tempName: value as string
        }
      }));
    } else if (field === "price") {
      setEditingStates(prev => ({
        ...prev,
        [index]: {
          ...prev[index],
          tempPrice: value.toString()
        }
      }));
    } else if (field === "duration") {
      setEditingStates(prev => ({
        ...prev,
        [index]: {
          ...prev[index],
          tempDuration: value.toString()
        }
      }));
    }

    // Mark that changes have been made
    setHasChanges(true);
  };

  const sanitizeData = (userData: any) => {
    console.log("Sanitizing data with services:", services);
    
    const sanitizedData: any = {
      userId: userId,
      name: proName || "",
      profileName: profileName || "",
      activity: activity || "",
      services: services.map(service => ({
        name: service?.service_name || "",
        service_name: service?.service_name || "", // Include both name and service_name for compatibility
        price: parseFloat((service?.price || 0).toString()),
        duration: parseInt((service?.duration || 0).toString()),
        description: service?.description || "",
        service_type: service?.service_type || "Private",
        quota: service?.quota || 1,
        frequency: service?.frequency || "One-time",
        rule: service?.rule || "Without-consent", // Ensure rule is always set
        address: service?.address || ""
      }))
    };

    console.log("Sanitized data:", sanitizedData);
    return sanitizedData;
  };

  // Handle form submission for new users
  const handleSubmit = async () => {
    if (!validateForm()) {
      console.log("Form validation failed");
      return;
    }

    try {
      setIsSubmitting(true);
      const responses = await axios.get(`${API_URL}/api/profile/${userId}`);
      const userData = responses.data;

      const profileData = sanitizeData(userData);
      console.log("Submitting profile with data:", profileData);

      // Ensure all services have the rule property set
      profileData.services = profileData.services.map((service: any) => ({
        ...service,
        rule: service.rule || "Without-consent" // Default to "Without-consent" if not set
      }));

      const response = await axios.post(`${API_URL}/api/profile`, profileData);
      if (!response.data || !response.data.success) {
        throw new Error(response.data?.error || "Failed to save profile.");
      }

      // Redirect to what-now page
      setHasChanges(false);
      window.location.href = `/what-now/${userId}`;
    } catch (error: any) {
      console.error("Error submitting profile:", error);
      alert(
        error.response?.data?.error || error.message || "Error saving profile. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle updating profile for existing users
  const handleUpdate = async () => {
    if (!validateForm()) {
      console.log("Form validation failed");
      return;
    }

    try {
      setIsSubmitting(true);
      const responses = await axios.get(`${API_URL}/api/profile/${userId}`);
      const userData = responses.data;
      console.log("User data:", userData);

      const profileData = sanitizeData(userData);
      console.log("Updating profile with data:", profileData);

      // Ensure all services have the rule property set
      profileData.services = profileData.services.map((service: any) => ({
        ...service,
        rule: service.rule || "Without-consent" // Default to "Without-consent" if not set
      }));

      const response = await axios.post(
        `${API_URL}/api/update-profile`,
        profileData
      );
      if (!response.data || !response.data.success) {
        throw new Error(response.data?.error || "Failed to update profile.");
      }

      setHasChanges(false);
      alert("Profile updated successfully!");
    } catch (error: any) {
      console.error("Error updating profile:", error);
      alert(
        error.response?.data?.error || error.message || "Error updating profile. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

   // The handleNameChange method with validation
   const handleNameChange = (value: string) => {
    console.log("New proName:", value); // Debugging
    if (value.trim() === "") {
      setError("Pro name cannot be empty.");
    } else if (value.length > 50) {
      setError("Pro name must be less than 50 characters.");
    } else {
      setError("");
    }
    setProName(value);
  };
  

  // Add loading state to the return statement
  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#1E2738] flex flex-col items-center justify-center p-4">
        <div className="text-white text-xl">Loading your profile settings...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
      <div className="w-full max-w-6xl space-y-6 py-6">
        {/* Header */}
        <div className="flex justify-center items-center mb-6">
          <h1 className="text-[#FFD700] text-3xl font-bold text-center">
            Set up your Profile & Services
        </h1>
        </div>
        
        {/* Profile Card */}
        <Card className="mb-8 bg-[#1A202C] border-none">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold text-white">Profile</h2>
            <div className="bg-blue-900 text-blue-200 text-xs px-2 py-1 rounded-full">
              Required
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-300 text-sm font-medium mb-2">
                Enter Your Name
              </label>
        <InputWrapper>
        <input
          type="text"
          value={proName}
         onChange={(e) => handleNameChange(e.target.value)}
                  placeholder="Enter your name"
                  className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-lg focus:ring-0 bg-transparent font-medium text-white text-lg"
                  aria-label="Your name"
        />
      </InputWrapper>
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
              {profileErrors.name && <p className="text-red-500 text-sm mt-1">{profileErrors.name}</p>}
              <p className="text-xs text-gray-400 mt-1">
                This name will be displayed to other Klickie users when you book or offer services
              </p>
            </div>
            
            <div>
              <label className="block text-gray-300 text-sm font-medium mb-2 flex items-center">
                <span>What's Your (Side)Hustle?</span>
                <div className="ml-2 bg-gradient-to-r from-[#4895AA] to-[#FFD700] text-xs text-black font-bold px-2 py-0.5 rounded-full">
                  SMART FEATURE
                </div>
              </label>
              
          <div className="relative">
                <InputWrapper>
            <input
              type="text"
              value={activity}
              onChange={(e) => handleActivityChange(e.target.value)}
                    placeholder="e.g., Padel lessons"
                    className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-lg focus:ring-0 bg-transparent font-medium text-white text-lg"
                    aria-label="Your (side)hustle"
            />
                </InputWrapper>
                
            {showSuggestions && filteredActivities.length > 0 && (
                  <div className="absolute z-40 w-full mt-1 bg-[#2D3748] rounded-lg shadow-lg max-h-60 overflow-auto">
                {filteredActivities.map((suggestion, index) => (
                  <div
                    key={index}
                        className="px-4 py-2 cursor-pointer hover:bg-[#4A5568] text-gray-300"
                    onClick={() => handleActivitySelect(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
          </div>
              <p className="text-xs text-gray-400 mt-1">This activity will be shown to other users and is required for offering services.</p>
              {profileErrors.activity && <p className="text-red-500 text-sm mt-1">{profileErrors.activity}</p>}
              <div className="mt-2 bg-[#1A202C] p-3 rounded-lg border border-gray-700">
                <p className="text-sm text-white font-medium">Why share your (side)hustle?</p>
                <ul className="mt-1 space-y-1">
                  <li className="flex items-start">
                    <div className="text-[#4895AA] mr-1.5 w-4 flex-shrink-0 text-center">✓</div>
                    <p className="text-xs text-gray-300">Make your skills discoverable when they're needed most</p>
                  </li>
                  <li className="flex items-start">
                    <div className="text-[#4895AA] mr-1.5 w-4 flex-shrink-0 text-center">✓</div>
                    <p className="text-xs text-gray-300">No more "I wish I knew someone who could help with this"</p>
                  </li>
                  <li className="flex items-start">
                    <div className="text-[#4895AA] mr-1.5 w-4 flex-shrink-0 text-center">✓</div>
                    <p className="text-xs text-gray-300">Be the solution your neighbors have been looking for</p>
                  </li>
                </ul>
                <p className="text-xs text-gray-400 mt-2 italic">
                  From weekend (side)hustles to full-time passions, everyone has something they're great at!
                </p>
              </div>
            </div>
          </div>
        </Card>
        
        {/* Services Section */}
        <div className="mb-6">
          <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between mb-4">
            <h2 className="text-2xl font-bold text-[#FFD700] text-center mb-2 sm:mb-0">Your Services</h2>
            {servicesActivated && (
              <button
                onClick={() => setServicesActivated(false)}
                className="text-gray-400 hover:text-gray-300 text-sm flex items-center transition-colors"
              >
                <Minus className="h-4 w-4 mr-1" />
                Hide Services
              </button>
            )}
          </div>
          
          {!servicesActivated ? (
            <div className="bg-[#1A202C] rounded-lg p-6 border border-gray-700 transition-all duration-300 ease-in-out">
              <div className="flex flex-col items-center text-center mb-6">
                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#4895AA] to-[#FFD700] flex items-center justify-center mb-4">
                  <Grid className="h-8 w-8 text-black" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">Ready to offer your services?</h3>
                <p className="text-gray-300 max-w-lg mb-4">
                  Activate services to let others book you. Share your talents with your neighborhood and create new opportunities.
                </p>
                <button
                  onClick={() => setServicesActivated(true)}
                  className="px-6 py-3 bg-gradient-to-r from-[#4895AA] to-[#FFD700] text-black font-bold rounded-lg transform transition-transform duration-200 hover:scale-105"
                >
                  Activate Services
                </button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
                <div className="p-4 bg-[#2D3748] rounded-lg">
                  <Calendar className="h-6 w-6 text-[#4895AA] mx-auto mb-2" />
                  <h4 className="text-white font-medium mb-1">Flexible Scheduling</h4>
                  <p className="text-xs text-gray-400">Set your own availability and control your calendar</p>
                </div>
                <div className="p-4 bg-[#2D3748] rounded-lg">
                  <DollarSign className="h-6 w-6 text-[#4895AA] mx-auto mb-2" />
                  <h4 className="text-white font-medium mb-1">Set Your Prices</h4>
                  <p className="text-xs text-gray-400">You decide how much your services are worth</p>
                </div>
                <div className="p-4 bg-[#2D3748] rounded-lg">
                  <User className="h-6 w-6 text-[#4895AA] mx-auto mb-2" />
                  <h4 className="text-white font-medium mb-1">Connect Locally</h4>
                  <p className="text-xs text-gray-400">Build your reputation in your neighborhood</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* Service Cards Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {services.map((service, index) => (
                  <Card 
                    key={index} 
                    className={`bg-[#1A202C] border-none border-l-4 ${
                      errors[index] && Object.keys(errors[index]).length > 0 
                        ? 'border-red-500' 
                        : service.rule === "Consent" 
                          ? 'border-[#FFD700]' 
                          : 'border-[#4895AA]'
                    } ${activeServiceIndex === index ? 'ring-2 ring-blue-500' : ''}`}
                  >
                    {/* Service Card Header */}
                    <div className="flex justify-between items-center mb-3">
                      <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-[#4895AA] flex items-center justify-center text-white font-bold">
                          {index + 1}
                        </div>
                        <h3 className="ml-2 text-white text-lg font-medium truncate max-w-[150px]">
                          {service.service_name || `Service ${index + 1}`}
                        </h3>
                      </div>
                      <div className="flex items-center space-x-1">
                        <button
                          onClick={() => toggleServiceExpansion(index)}
                          className="text-gray-400 hover:text-blue-400 transition-colors p-1 flex items-center"
                          aria-label={activeServiceIndex === index ? "Collapse service details" : "Edit service details"}
                        >
                          {activeServiceIndex === index ? (
                            <>
                              <ChevronUp className="h-5 w-5 mr-1" />
                              <span className="text-sm">Done</span>
                            </>
                          ) : (
                            <>
                              <Edit className="h-4 w-4 mr-1" />
                              <span className="text-sm">Edit</span>
                            </>
                          )}
                        </button>
                <button
                  onClick={() => removeService(index)}
                          className="text-gray-400 hover:text-red-400 transition-colors p-1"
                          aria-label="Remove service"
                >
                          <Trash2 className="h-5 w-5" />
                </button>
                      </div>
                    </div>

                    {/* Service Card Summary (when collapsed) */}
                    {activeServiceIndex !== index && (
                      <div className="space-y-2">
                        {/* Show validation errors if they exist */}
                        {errors[index] && Object.keys(errors[index]).length > 0 && (
                          <div className="bg-red-900/30 border border-red-500 rounded-md p-2 mb-2">
                            <p className="text-red-400 text-sm font-medium mb-1">Please fix the following errors:</p>
                            <ul className="list-disc list-inside text-red-300 text-xs">
                              {Object.entries(errors[index]).map(([field, message]) => (
                                <li key={field}>{message}</li>
                              ))}
                            </ul>
                            <button 
                              onClick={() => toggleServiceExpansion(index)}
                              className="mt-1 text-xs text-blue-400 hover:text-blue-300 flex items-center"
                            >
                              <Edit className="h-3 w-3 mr-1" />
                              Edit service
                            </button>
                          </div>
                        )}
                        
                        <div className="flex items-center text-gray-300">
                          <Clock className="h-4 w-4 mr-2" />
                          <span>{service.duration} min</span>
                        </div>
                        <div className="flex items-center text-gray-300">
                          <DollarSign className="h-4 w-4 mr-2" />
                          <span>€{service.price}</span>
                        </div>
                        <div className="flex items-center text-gray-300">
                          <Calendar className="h-4 w-4 mr-2" />
                          <span>{service.frequency || "One-time"}</span>
                        </div>
                        {service.address && (
                          <div className="flex items-center text-gray-300">
                            <MapPin className="h-4 w-4 mr-2" />
                            <span className="truncate">{service.address}</span>
                          </div>
                        )}
                        <div className="mt-3 pt-2 border-t border-gray-700">
                          <span className={`text-sm px-2 py-1 rounded-full ${service.rule === "Consent" ? 'bg-yellow-900 text-yellow-200' : 'bg-blue-900 text-blue-200'}`}>
                            {service.rule === "Consent" ? 'Requires Approval' : 'Direct Booking'}
                          </span>
            </div>
                      </div>
                    )}
                    
                    {/* Service Card Expanded Form */}
                    {activeServiceIndex === index && (
                      <div className="space-y-4 mt-2 pt-2 border-t border-gray-700">
                        <div className="bg-blue-900/20 p-2 rounded-lg mb-2 flex items-center">
                          <Edit className="h-4 w-4 text-blue-400 mr-2" />
                          <span className="text-sm text-blue-300">Editing service details</span>
                        </div>
                        <div>
                          <label className="block text-gray-300 text-sm font-medium mb-1">
                            Service Name
                          </label>
            <InputWrapper>
              <input
                type="text"
                value={service.service_name}
                              onChange={(e) => handleServiceChange(index, "service_name", e.target.value)}
                placeholder="Service name (e.g., Private Padel Lesson)"
                              className="relative z-30 w-full h-[45px] px-4 text-left border-0 rounded-lg focus:ring-0 bg-transparent text-white"
                              aria-label={`Service ${index + 1} name`}
              />
            </InputWrapper>
            {errors[index]?.service_name && (
                            <p className="text-red-500 text-sm mt-1">{errors[index].service_name}</p>
            )}
                        </div>

                        <div>
                          <label className="block text-gray-300 text-sm font-medium mb-1">
                            Service Location (Optional)
                          </label>
              <InputWrapper>
                            <input
                              type="text"
                              value={service.address}
                              onChange={(e) => handleServiceChange(index, "address", e.target.value)}
                              placeholder="Where will this service take place?"
                              className="relative z-30 w-full h-[45px] px-4 text-left border-0 rounded-lg focus:ring-0 bg-transparent text-white"
                              aria-label={`Service ${index + 1} location`}
                            />
                          </InputWrapper>
                          <p className="text-xs text-gray-400 mt-1">
                            This will be shown to clients as a general location indicator (e.g., "City Center", "Online", "Client's location")
                          </p>
                        </div>
                        
                        <div className="grid grid-cols-2 gap-3">
                          <div>
                            <label className="block text-gray-300 text-sm font-medium mb-1">
                              Price
                            </label>
                            <div className="bg-[#2D3748] border border-gray-600 rounded-lg">
                              <div className="relative flex items-center">
                  <input
                    type="number"
                                value={service.price}
                                onChange={(e) => handleServiceChange(index, "price", Number(e.target.value))}
                    placeholder="Price"
                                className="w-full h-[45px] px-4 text-left border-0 rounded-lg focus:ring-0 bg-transparent text-white"
                                aria-label={`Service ${index + 1} price`}
                  />
                                <span className="absolute right-4 text-gray-400 font-medium">
                    €
                  </span>
                </div>
                            </div>
              {errors[index]?.price && (
                                <p className="text-red-500 text-sm mt-1">{errors[index].price}</p>
                              )}
                          </div>
                          
                          <div>
                            <label className="block text-gray-300 text-sm font-medium mb-1">
                              Duration
                            </label>
                            <div className="bg-[#1A202C] border border-gray-600 rounded-lg">
                              <div className="relative">
                                <button
                                  type="button"
                                  onClick={() => {
                                    const newEditingStates = { ...editingStates };
                                    if (!newEditingStates[index]) {
                                      newEditingStates[index] = {
                                        isEditingPrice: false,
                                        tempPrice: service.price.toString(),
                                        isEditingDuration: false,
                                        tempDuration: service.duration.toString(),
                                        isEditingName: false,
                                        tempName: service.service_name,
                                        isExpanded: false
                                      };
                                    }
                                    newEditingStates[index].isEditingDuration = !newEditingStates[index].isEditingDuration;
                                    setEditingStates(newEditingStates);
                                  }}
                                  className="w-full h-[45px] px-4 text-left flex items-center justify-between bg-transparent text-white"
                                  aria-label={`Service ${index + 1} duration`}
                                >
                                  <span>{service.duration === 0 ? 'Select duration' : service.duration === 60 ? '1 hour' : service.duration === 120 ? '2 hours' : `${service.duration} min`}</span>
                                  <ChevronDown className="h-4 w-4 text-gray-400" />
                                </button>
                                
                                {editingStates[index]?.isEditingDuration && (
                                  <div className="absolute top-full left-0 right-0 mt-1 bg-[#2D3748] border border-gray-600 rounded-lg shadow-lg z-50">
                                    <div className="py-1">
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 15);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        15 min
                                      </button>
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 30);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        30 min
                                      </button>
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 45);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        45 min
                                      </button>
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 60);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        60 min
                                      </button>
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 75);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        1h 15m
                                      </button>
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 90);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        1h 30m
                                      </button>
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 105);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        1h 45m
                                      </button>
                                      <button
                                        type="button"
                                        className="w-full px-4 py-2 text-left text-white hover:bg-[#3A4A63]"
                                        onClick={() => {
                                          handleServiceChange(index, "duration", 120);
                                          const newEditingStates = { ...editingStates };
                                          newEditingStates[index].isEditingDuration = false;
                                          setEditingStates(newEditingStates);
                                        }}
                                      >
                                        2 hours
                                      </button>
                </div>
                                  </div>
                                )}
                              </div>
                            </div>
              {errors[index]?.duration && (
                              <p className="text-red-500 text-sm mt-1">{errors[index].duration}</p>
              )}
            </div>
              </div>
                        
                        <div>
                          <label className="block text-gray-300 text-sm font-medium mb-1">
                            Booking Type
                          </label>
                          <div className="bg-[#2D3748] rounded-lg p-3">
                            <div className="space-y-2">
                              <div className="flex items-start">
                                <div 
                                  className={`flex-shrink-0 w-5 h-5 rounded-full border ${
                                    service.frequency === "One-time" 
                                      ? 'border-[#4895AA] bg-[#4895AA]' 
                                      : 'border-gray-500'
                                  } flex items-center justify-center mt-0.5 cursor-pointer`}
                                  onClick={() => handleServiceChange(index, "frequency", "One-time")}
                                >
                                  {service.frequency === "One-time" && (
                                    <div className="w-2 h-2 bg-white rounded-full"></div>
                                  )}
            </div>
                                <div className="ml-2">
                                  <p 
                                    className={`text-sm ${service.frequency === "One-time" ? 'text-white' : 'text-gray-300'} font-medium cursor-pointer`}
                                    onClick={() => handleServiceChange(index, "frequency", "One-time")}
                                  >
                                    One-time Session
                                  </p>
                                  <p className="text-xs text-gray-300">
                                    Clients book a single appointment that occurs once
                                  </p>
              </div>
                              </div>
                              
                              <div className="flex items-start opacity-60">
                                <div className="flex-shrink-0 w-5 h-5 rounded-full border border-gray-500 flex items-center justify-center mt-0.5">
                                </div>
                                <div className="ml-2">
                                  <p className="text-sm text-gray-300 font-medium">Recurring Sessions <span className="text-xs bg-gray-700 text-gray-300 px-1.5 py-0.5 rounded-full ml-1">Coming Soon</span></p>
                                  <p className="text-xs text-gray-400">
                                    Clients book appointments that repeat weekly at the same time
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div>
                          <label className="block text-gray-300 text-sm font-medium mb-1">
                            Booking Approval
                          </label>
                          <div className="bg-[#2D3748] rounded-lg p-4">
                            <div className="mb-3">
                              <div className="flex items-center justify-between">
                                <h3 className="text-white font-medium">Choose how you manage bookings</h3>
                                <div className="bg-gradient-to-r from-[#4895AA] to-[#FFD700] text-xs text-black font-bold px-2 py-1 rounded-full">
                                  Smart Flow
                                </div>
                              </div>
                              <p className="text-xs text-gray-300 mt-1">
                                Select the booking flow that works best for your business
                              </p>
                            </div>
                            
                            <div className="space-y-4 mt-3">
                              <div className={`p-3 rounded-lg border ${service.rule === "Without-consent" ? 'bg-[#1A202C] border-[#4895AA]' : 'bg-transparent border-gray-600'}`}>
                                <div className="flex items-start gap-3">
                                  <div 
                                    className={`flex-shrink-0 w-5 h-5 rounded-full border ${
                                      service.rule === "Without-consent" 
                                        ? 'border-[#4895AA] bg-[#4895AA]' 
                                        : 'border-gray-500'
                                    } flex items-center justify-center mt-1`}
                                    onClick={() => handleServiceChange(index, "rule", "Without-consent")}
                                  >
                                    {service.rule === "Without-consent" && (
                                      <div className="w-2 h-2 bg-white rounded-full"></div>
                                    )}
                                  </div>
                                  <div className="flex-grow">
                                    <label 
                                      className={`block font-medium cursor-pointer ${
                                        service.rule === "Without-consent" ? 'text-white' : 'text-gray-400'
                                      }`}
                                      onClick={() => handleServiceChange(index, "rule", "Without-consent")}
                                    >
                                      Direct Booking
                                    </label>
                                    <p className={`text-xs ${service.rule === "Without-consent" ? 'text-gray-300' : 'text-gray-400'} mt-1`}>
                                      Clients can book instantly without waiting for approval
                                    </p>
                                    
                                    {service.rule === "Without-consent" && (
                                      <div className="mt-2 space-y-1.5">
                                        <div className="flex items-center">
                                          <div className="text-[#4895AA] mr-1.5 w-4 flex-shrink-0 text-center">✓</div>
                                          <p className="text-xs text-gray-300">Faster booking process for clients</p>
                                        </div>
                                        <div className="flex items-center">
                                          <div className="text-[#4895AA] mr-1.5 w-4 flex-shrink-0 text-center">✓</div>
                                          <p className="text-xs text-gray-300">Higher conversion rate</p>
                                        </div>
                                        <div className="flex items-center">
                                          <div className="text-[#4895AA] mr-1.5 w-4 flex-shrink-0 text-center">✓</div>
                                          <p className="text-xs text-gray-300">Automated scheduling with no manual work</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              
                              <div className={`p-3 rounded-lg border ${service.rule === "Consent" ? 'bg-[#1A202C] border-[#FFD700]' : 'bg-transparent border-gray-600'}`}>
                                <div className="flex items-start gap-3">
                                  <div 
                                    className={`flex-shrink-0 w-5 h-5 rounded-full border ${
                                      service.rule === "Consent" 
                                        ? 'border-[#FFD700] bg-[#FFD700]' 
                                        : 'border-gray-500'
                                    } flex items-center justify-center mt-1`}
                                    onClick={() => handleServiceChange(index, "rule", "Consent")}
                                  >
                                    {service.rule === "Consent" && (
                                      <div className="w-2 h-2 bg-black rounded-full"></div>
                                    )}
                                  </div>
                                  <div className="flex-grow">
                                    <div className="flex items-center">
                                      <label 
                                        className={`block font-medium cursor-pointer ${
                                          service.rule === "Consent" ? 'text-white' : 'text-gray-400'
                                        }`}
                                        onClick={() => handleServiceChange(index, "rule", "Consent")}
                                      >
                                        Require Approval
                                      </label>
                                      <span className={`ml-2 text-xs ${service.rule === "Consent" ? 'bg-[#FFD700] text-black' : 'bg-gray-700 text-gray-300'} font-bold px-1.5 py-0.5 rounded-full`}>
                                        EXCLUSIVE FEATURE
                                      </span>
                                    </div>
                                    <p className={`text-xs ${service.rule === "Consent" ? 'text-gray-300' : 'text-gray-400'} mt-1`}>
                                      You control your calendar with full booking approval power
                                    </p>
                                    
                                    {service.rule === "Consent" && (
                                      <div className="mt-2 space-y-1.5">
                                        <div className="flex items-center">
                                          <div className="text-[#FFD700] mr-1.5 w-4 flex-shrink-0 text-center">★</div>
                                          <p className="text-xs text-gray-300">Receive notifications for each booking request</p>
                                        </div>
                                        <div className="flex items-center">
                                          <div className="text-[#FFD700] mr-1.5 w-4 flex-shrink-0 text-center">★</div>
                                          <p className="text-xs text-gray-300">Approve, reschedule, or decline bookings before confirmation</p>
                                        </div>
                                        <div className="flex items-center">
                                          <div className="text-[#FFD700] mr-1.5 w-4 flex-shrink-0 text-center">★</div>
                                          <p className="text-xs text-gray-300">Perfect for professionals who need scheduling flexibility</p>
                                        </div>
                                        <div className="flex items-center">
                                          <div className="text-[#FFD700] mr-1.5 w-4 flex-shrink-0 text-center">★</div>
                                          <p className="text-xs text-gray-300">Exclusive feature that gives you complete control</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                ))}

                {/* Add Service Card */}
                <Card 
                  className="border-2 border-dashed border-gray-600 bg-[#2D3748] hover:bg-[#3A4A63] flex flex-col items-center justify-center p-6 cursor-pointer"
          onClick={addService}
                >
                  <div className="w-12 h-12 rounded-full bg-[#1A202C] flex items-center justify-center mb-2">
                    <Plus className="h-6 w-6 text-[#4895AA]" />
                  </div>
                  <h3 className="text-lg font-medium text-white">Add Service</h3>
                  <p className="text-sm text-gray-400 text-center mt-1">
                    Create a new service for your clients
                  </p>
                </Card>
              </div>
            </>
          )}
        </div>
        
        {/* Bottom action buttons */}
        <div className="mt-8 flex flex-col gap-4">
        {isNewUser ? (
          <button
            onClick={handleSubmit}
              disabled={isSubmitting}
              className={`w-full h-[50px] ${isSubmitting ? 'bg-gray-600' : 'bg-[#FFD700] hover:bg-[#E6C200]'} text-black rounded-lg font-medium text-lg transition-colors`}
              aria-label="Save profile"
          >
              {isSubmitting ? 'Saving...' : 'Save and Return'}
          </button>
        ) : (
          <button
            onClick={handleUpdate}
              disabled={isSubmitting}
              className={`w-full h-[50px] ${isSubmitting ? 'bg-gray-600' : 'bg-[#4895AA] hover:bg-[#3A7A8A]'} text-white rounded-lg font-medium text-lg transition-colors`}
              aria-label="Update profile"
          >
              {isSubmitting ? 'Updating...' : 'Update Profile'}
          </button>
        )}
          
          <button
            onClick={handleBack}
            className="w-full h-[50px] bg-[#2D3748] hover:bg-[#3A4A63] text-white rounded-lg font-medium text-lg flex items-center justify-center transition-colors"
            aria-label="Go back"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back
          </button>
      </div>

        {/* Unsaved Changes Modal */}
        {showUnsavedChangesModal && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
            <div className="bg-[#1A202C] p-6 rounded-xl shadow-lg max-w-md w-full">
              <h3 className="text-xl font-bold text-white mb-4">Unsaved Changes</h3>
              <p className="text-gray-300 mb-6">You have unsaved changes. What would you like to do?</p>
              <div className="flex flex-col sm:flex-row gap-3">
                <button
                  onClick={() => setShowUnsavedChangesModal(false)}
                  className="flex-1 py-2 bg-[#2D3748] hover:bg-[#3A4A63] text-white rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={goBackWithoutSaving}
                  className="flex-1 py-2 bg-[#2D3748] hover:bg-[#3A4A63] text-white rounded-lg transition-colors"
                >
                  Discard Changes
                </button>
                <button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                    isNewUser ? handleSubmit() : handleUpdate();
                  }}
                  className="flex-1 py-2 bg-[#4895AA] hover:bg-[#3A7A8A] text-white rounded-lg transition-colors"
                >
                  Save & Continue
                </button>
        </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingPro;
