import { useState } from 'react'
import PhoneInput from '../components/PhoneInput'
import axios from 'axios'
import countries from "countries"

export default function Home() {
    const [country, setCountry] = useState('BE')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isValid, setIsValid] = useState(false)

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newNumber = e.target.value
        setPhoneNumber(newNumber)
        setIsValid(newNumber.length > 0)
    }

    const handleSubmit = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'https://api.klickie.me';
            const selectedCountry = countries.find(c => c.code === country);

            if (!selectedCountry) {
                console.error('Invalid country selected:', country);
                alert('Invalid country selected.');
                return;
            }

            const cleanPhoneNumber = phoneNumber.replace(/\D/g, '');
            const fullPhoneNumber = `${selectedCountry.prefix}${cleanPhoneNumber}`;

            const response = await axios.post(`${API_URL}/new-phone-number`, {
                phoneNumber: cleanPhoneNumber,
                country,
                fullPhoneNumber
            });

            if (response.data.success) {
                setPhoneNumber('');
                alert('Thank you! We will contact you shortly via WhatsApp.');
            } else {
                throw new Error(response.data.message || 'Unknown error occurred');
            }
        } catch (error: any) {
            console.error('Error submitting phone number:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to submit phone number';
            alert(`Error: ${errorMessage}. Please try again.`);
        }
    };

    return (
        <div className="min-h-screen bg-white flex flex-col items-center justify-center p-4">
            <div className="max-w-md w-full space-y-8 text-center">
                <div className="space-y-4">
                    <h1 className="text-3xl font-bold text-gray-900">
                        Klickie
                    </h1>
                    <p className="text-gray-600">
                        Klickie makes it effortless to find and book trusted solo pros using the tools you already use. We're currently in <span className="font-bold">stealth mode</span>, launching to a limited number of users.
                    </p>
                </div>

                <div className="mt-8 space-y-4">
                    <div className="flex flex-col items-center space-y-4">
                        <PhoneInput
                            phoneNumber={phoneNumber}
                            handlePhoneChange={handlePhoneChange}
                            country={country}
                            setCountry={setCountry}
                            isValid={isValid}
                            handleSubmit={handleSubmit}
                        />
                        <button
                            onClick={handleSubmit}
                            disabled={!isValid}
                            className={`w-full py-2 px-4 rounded-md text-white ${
                                isValid 
                                ? 'bg-black hover:bg-gray-800' 
                                : 'bg-gray-300 cursor-not-allowed'
                            }`}
                        >
                            Request Access*
                        </button>
                    </div>
                </div>

                <footer className="mt-8 text-sm text-gray-500 space-y-2">
                    <div>
                        <p>Klickie B.V.</p>
                        <p>Lange Houtstraat 13, Zuid Holland 2511CV Den Haag, Netherlands</p>
                        <a href="mailto:sidfer@klickie.me" className="hover:text-gray-700">
                            sidfer@klickie.me
                        </a>
                    </div>
                    <div className="text-xs space-y-2">
                        <p>
                            * By submitting your phone number, you agree to be contacted via WhatsApp. 
                            You can opt out at any time by messaging "STOP".
                        </p>
                        <div className="flex justify-center space-x-4">
                            <a href="/privacy-policy" className="hover:text-gray-700">Privacy Policy</a>
                            <a href="/terms-of-service" className="hover:text-gray-700">Terms of Service</a>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}