import React, {useState, useEffect, JSX} from 'react';
import { useParams } from 'react-router-dom';
import klickieLogo from '../assets/klickie-logo.png';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

interface CalendarCredentials {
    serverUrl: string;
    username: string;
    password: string;
}

type CalendarProvider = {
    name: string;
    serverUrl: string;
    description: string;
    instructions: string[];
}

type CalendarProviders = {
    [key in 'GOOGLE' | 'APPLE']: CalendarProvider;
}

const CALENDAR_PROVIDERS: CalendarProviders = {
    GOOGLE: {
        name: 'Google Calendar',
        serverUrl: 'https://calendar.google.com',
        description: 'Connect with your Google Calendar using an app-specific password',
        instructions: [
            'Your Klickie Bookings calendar has been created in Google Calendar',
            'Click below to open Google Calendar and verify the connection',
            'You should see a new calendar named "Klickie Bookings"',
            'All your Klickie bookings will automatically appear here'
        ]
    },
    APPLE: {
        name: 'Apple Calendar',
        serverUrl: 'https://caldav.icloud.com',
        description: 'Connect with your iCloud Calendar',
        instructions: [
            'Your Klickie Bookings calendar has been created in iCloud Calendar',
            'Click below to open iCloud Calendar and verify the connection',
            'You should see a new calendar named "Klickie Bookings"',
            'You might need to wait a minute for it to appear',
            'All your Klickie bookings will automatically appear here'
        ]
    }
};

interface PasswordInstructions {
    title: string;
    steps: string[];
}

const APP_PASSWORD_INSTRUCTIONS: { [key in keyof CalendarProviders]?: PasswordInstructions } = {
    GOOGLE: {
        title: 'How to get your Google App Password:',
        steps: [
            '1. Go to your Google Account',
            '2. Select Security',
            '3. Under "Signing in to Google," select 2-Step Verification',
            '4. At the bottom of the page, select App passwords',
            '5. Select the app (select "Other (Custom name)" and enter "Klickie")',
            '6. Select the device you\'re using',
            '7. Select Generate',
            '8. Follow the instructions to enter the app password (the 16 character code)',
            '9. Select Done',
            '10. Use your Google email and this app password to connect'
        ]
    },
    APPLE: {
        title: 'How to get your Apple App-Specific Password:',
        steps: [
            '1. Sign in to appleid.apple.com',
            '2. Click "Sign-In and Security"',
            '3. Click "App-Specific Passwords"',
            '4. Click the "+" button to generate a new password',
            '5. Enter "Klickie Calendar" as the password label',
            '6. Click "Create"',
            '7. Copy the generated password',
            '8. Use your Apple ID email and this app-specific password to connect'
        ]
    }
};

// Add new interfaces for sync status
interface SyncStatus {
    state: 'synced' | 'syncing' | 'error' | 'disconnected';
    lastSyncTime?: string;
    lastSyncCount?: number;
    error?: string;
}

// Add interface for Firestore timestamp
interface FirestoreTimestamp {
    _seconds: number;
    _nanoseconds: number;
}

// Update the formatting function
const formatLastSyncTime = (timestamp: FirestoreTimestamp | undefined): string => {
    console.log(timestamp, "timestamp");
    if (!timestamp || !timestamp._seconds) return 'Never';
    
    try {
        // Convert Firestore timestamp to JavaScript Date
        const date = new Date(timestamp._seconds * 1000);
        
        // Check if date is valid
        if (isNaN(date.getTime())) return 'Never';
        
        // Format the date and time
        const dateOptions: Intl.DateTimeFormatOptions = { 
            year: 'numeric', 
            month: 'short', 
            day: 'numeric' 
        };
        const timeOptions: Intl.DateTimeFormatOptions = { 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
        };
        
        const dateStr = date.toLocaleDateString(undefined, dateOptions);
        const timeStr = date.toLocaleTimeString(undefined, timeOptions);
        
        return `${dateStr} at ${timeStr}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return 'Never';
    }
};

export default function CalendarSync(): JSX.Element {
    const { userId } = useParams();
    const [selectedProvider, setSelectedProvider] = useState<keyof CalendarProviders | null>(null);
    const [credentials, setCredentials] = useState<CalendarCredentials>({
        serverUrl: '',
        username: '',
        password: ''
    });
    const [isConnecting, setIsConnecting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showInstructions, setShowInstructions] = useState(false);
    const [connectionSuccess, setConnectionSuccess] = useState(false);
    const [profileData, setProfileData] = useState<any | null>(null);
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [syncStatus, setSyncStatus] = useState<SyncStatus>({
        state: 'synced',
        lastSyncTime: undefined,
        lastSyncCount: undefined
    });

    const formatTimestamp = (seconds: number): string => {
        const date = new Date(seconds * 1000);
        return date.toUTCString();
    };

    useEffect(() => {
        if (typeof window !== "undefined" && window.clarity) {
            window.clarity("event", "calendar_sync_landing", {
                userId: userId || "unknown",
                page: "CalendarSync",
            });
        }
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/profile/${userId}`);
                console.log(response, "response")
                setProfileData(response.data);
                const isCalendarConnected = response?.data?.isCalendarConnected;
                const lastSyncTime = response?.data?.calendar?.updatedAt?._seconds;
                const formattedLastSyncTime = lastSyncTime ? formatTimestamp(lastSyncTime) : null;
                
                console.log(isCalendarConnected, "isCalendarConnected")
                // Track successful profile fetch
                if (typeof window !== "undefined" && window.clarity) {
                    window.clarity("event", "profile_fetch_success", {
                        userId: userId || "unknown",
                        profile_type: response.data.type || "unknown",
                    });
                }
            } catch (error: any) {
                console.error('Error fetching profile:', error);

                // Track profile fetch error
                if (typeof window !== "undefined" && window.clarity) {
                    window.clarity("event", "profile_fetch_failed", {
                        userId: userId || "unknown",
                        error_message: error.message,
                    });
                }
            }
        };
        if (userId) {
            fetchProfile();
        }
    }, [userId]);

    useEffect(() => {
        if (profileData) {
            setSyncStatus({
                state: 'synced',
                lastSyncTime: profileData.lastSyncTime,
                lastSyncCount: profileData.lastSyncCount
            });
        }
    }, [profileData]);

    const handleProviderSelect = (provider: keyof CalendarProviders) => {
        if (provider === 'GOOGLE') {
            window.location.href = `${API_URL}/api/calendar/connect-google/${userId}`
            return;
        }
        setSelectedProvider(provider);
        setCredentials(prev => ({
            ...prev,
            serverUrl: CALENDAR_PROVIDERS[provider].serverUrl
        }));
    };

    const handleConnect = async () => {
        try {
            setIsConnecting(true);
            setError(null);

            console.log('Attempting to connect to:', `${API_URL}/api/calendar/connect`);

            const response = await fetch(`${API_URL}/api/calendar/connect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId,
                    provider: selectedProvider,
                    credentials
                })
            });

            console.log('Response status:', response.status);
            console.log('Response headers:', Object.fromEntries(response.headers.entries()));

            if (response.ok) {
                setConnectionSuccess(true);
            } else {
                const responseText = await response.text();
                console.error('Error response:', responseText);

                try {
                    const data = JSON.parse(responseText);
                    throw new Error(data.error || 'Failed to connect calendar');
                } catch (parseError) {
                    throw new Error(`Server returned invalid response: ${responseText.substring(0, 100)}...`);
                }
            }
        } catch (error: unknown) {
            console.error('Calendar connection error:', error);
            setError(error instanceof Error ? error.message : 'Failed to connect calendar');
        } finally {
            setIsConnecting(false);
        }
    };

    const handleDisconnect = async () => {
        try {
            setIsDisconnecting(true);
            setError(null);

            const response = await fetch(`${API_URL}/api/calendar/disconnect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId })
            });

            if (response.ok) {
                // Refresh profile data to update calendar connection status
                const profileResponse = await axios.get(`${API_URL}/api/profile/${userId}`);
                setProfileData(profileResponse.data);
                
                // Track successful disconnection in Clarity
                if (typeof window !== "undefined" && window.clarity) {
                    window.clarity("event", "calendar_disconnect_success", {
                        userId: userId || "unknown"
                    });
                }
            } else {
                throw new Error('Failed to disconnect calendar');
            }
        } catch (error) {
            console.error('Calendar disconnection error:', error);
            setError(error instanceof Error ? error.message : 'Failed to disconnect calendar');
            
            // Track disconnection error in Clarity
            if (typeof window !== "undefined" && window.clarity) {
                window.clarity("event", "calendar_disconnect_failed", {
                    userId: userId || "unknown",
                    error: error instanceof Error ? error.message : 'Unknown error'
                });
            }
        } finally {
            setIsDisconnecting(false);
        }
    };

    const getStatusIcon = (state: SyncStatus['state']) => {
        switch (state) {
            case 'synced':
                return (
                    <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center">
                        <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                );
            case 'syncing':
                return (
                    <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center">
                        <svg className="w-8 h-8 text-white animate-spin" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                    </div>
                );
            case 'error':
                return (
                    <div className="w-16 h-16 bg-yellow-500 rounded-full flex items-center justify-center">
                        <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderContent = () => {
        if (isConnecting && selectedProvider) {
            return (
                <div className="w-full space-y-6">
                    <div className="text-center space-y-4">
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-16 h-16 bg-[#4895AA] rounded-full flex items-center justify-center animate-pulse">
                                <svg className="w-8 h-8 text-white animate-spin" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        </div>
                        <p className="text-gray-300 text-lg">Connecting to {selectedProvider === 'GOOGLE' ? 'Google' : 'iCloud'} Calendar...</p>
                        <p className="text-gray-400 text-sm">This might take a few seconds</p>
                    </div>
                </div>
            );
        }

        if (error) {
            return (
                <div className="w-full space-y-6">
                    <div className="text-center space-y-4">
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-16 h-16 bg-red-500 rounded-full flex items-center justify-center">
                                <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                        <h2 className="text-red-400 text-xl font-semibold">Connection Failed</h2>
                        <div className="bg-red-500/10 border border-red-500/20 rounded-xl p-4">
                            <p className="text-red-400 text-sm">{error}</p>
                        </div>
                        <div className="text-gray-300 space-y-2">
                            <p>Common issues:</p>
                            <ul className="text-sm space-y-1">
                                <li>• Make sure you're using an app-specific password</li>
                                <li>• Check if your email address is correct</li>
                                <li>• Try generating a new app password</li>
                            </ul>
                        </div>
                    </div>

                    <button
                        onClick={() => {
                            setError(null);
                            setCredentials(prev => ({ ...prev, password: '' }));
                        }}
                        className="w-full relative group"
                    >
                        <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                        <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                            <div className="flex items-center justify-center">
                                <span>TRY AGAIN</span>
                            </div>
                        </div>
                    </button>

                    <button
                        onClick={() => setSelectedProvider(null)}
                        className="w-full text-gray-400 hover:text-white transition-colors"
                    >
                        Back to providers
                    </button>
                </div>
            );
        }

        if (connectionSuccess && selectedProvider) {
            const provider = CALENDAR_PROVIDERS[selectedProvider];
            return (
                <div className="w-full space-y-6">
                    <div className="text-center space-y-4">
                        <div className="flex items-center justify-center mb-4">
                            <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center">
                                <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                        </div>
                        {provider.instructions.map((instruction: string, index: number) => (
                            <p key={index} className="text-gray-300">{instruction}</p>
                        ))}
                    </div>

                    <button
                        onClick={() => window.open(provider.serverUrl, '_blank')}
                        className="w-full relative group"
                    >
                        <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                        <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                            <div className="flex items-center justify-center">
                                <span>OPEN {selectedProvider === 'GOOGLE' ? 'GOOGLE' : 'iCLOUD'} CALENDAR</span>
                            </div>
                        </div>
                    </button>

                    <button
                        onClick={() => window.close()}
                        className="w-full relative group"
                    >
                        <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                        <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                            <div className="flex items-center justify-center">
                                <span>CLOSE</span>
                            </div>
                        </div>
                    </button>
                </div>
            );
        }

        if (profileData?.isCalendarConnected) {
            return (
                <div className="w-full space-y-6">
                    {/* Status Header */}
                    <div className="flex flex-col items-center justify-center space-y-4">
                        <div className="flex justify-center">
                            {getStatusIcon(syncStatus.state)}
                        </div>
                        <h2 className="text-gray-300 text-xl">Calendar Status</h2>
                    </div>

                    {/* Status Details */}
                    <div className="bg-white/5 rounded-xl p-6 space-y-4">
                        <div className="flex justify-between items-center">
                            <span className="text-gray-400">Status</span>
                            <span className="text-white capitalize">{syncStatus.state}</span>
                        </div>
                        
                        <div className="flex justify-between items-center">
                            <span className="text-gray-400">Last Sync</span>
                            <div className="text-right">
                                <span className="text-white">
                                    {formatLastSyncTime(profileData.calendar?.updatedAt)}
                                </span>
                                {syncStatus.state === 'syncing' && (
                                    <span className="text-blue-400 text-sm block">
                                        Sync in progress...
                                    </span>
                                )}
                            </div>
                        </div>
                        
                        {profileData.calendar?.lastSyncCount !== undefined && (
                            <div className="flex justify-between items-center">
                                <span className="text-gray-400">Events Synced</span>
                                <span className="text-white">{profileData.calendar.lastSyncCount}</span>
                            </div>
                        )}

                        {syncStatus.error && (
                            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 mt-4">
                                <p className="text-red-400 text-sm">{syncStatus.error}</p>
                            </div>
                        )}
                    </div>

                    {/* Disconnect Button */}
                    <button
                        onClick={() => setShowDisconnectModal(true)}
                        className="w-full relative group"
                    >
                        <div className="absolute inset-0 bg-red-600 rounded-xl translate-x-1 translate-y-1" />
                        <div className="relative w-full bg-red-500 text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-red-400 transition-colors">
                            <span>DISCONNECT CALENDAR</span>
                        </div>
                    </button>

                    {/* Disconnect Confirmation Modal */}
                    {showDisconnectModal && (
                        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                            <div className="bg-[#1E2738] rounded-xl p-6 max-w-md w-full space-y-4">
                                <h3 className="text-xl text-white font-semibold">Disconnect Calendar?</h3>
                                <p className="text-gray-400">
                                    Are you sure you want to disconnect your calendar? Your events will no longer sync automatically.
                                </p>
                                <div className="flex space-x-4">
                                    <button
                                        onClick={() => setShowDisconnectModal(false)}
                                        className="flex-1 px-4 py-2 rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleDisconnect();
                                            setShowDisconnectModal(false);
                                        }}
                                        className="flex-1 px-4 py-2 rounded-lg bg-red-500 text-white hover:bg-red-400 transition-colors"
                                    >
                                        Disconnect
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <button
                        onClick={() => window.close()}
                        className="w-full text-gray-400 hover:text-white transition-colors"
                    >
                        Close
                    </button>
                </div>
            );
        }

        return (
            <>
                {showInstructions && selectedProvider && APP_PASSWORD_INSTRUCTIONS[selectedProvider] && (
                    <div className="w-full space-y-4 mb-8">
                        <h2 className="text-[#FFD700] text-xl font-semibold">
                            {APP_PASSWORD_INSTRUCTIONS[selectedProvider]?.title}
                        </h2>
                        <div className="space-y-2 text-gray-300">
                            {APP_PASSWORD_INSTRUCTIONS[selectedProvider]?.steps.map((step, index) => (
                                <p key={index} className="text-sm">{step}</p>
                            ))}
                        </div>
                        <button
                            onClick={() => setShowInstructions(false)}
                            className="w-full text-gray-400 hover:text-white transition-colors"
                        >
                            Back to login
                        </button>
                    </div>
                )}

                {!showInstructions && (
                    <>
                        {/* Description */}
                        <div className="text-center mb-8">
                            <p className="text-gray-400">
                                Connect your calendar to automatically sync your bookings
                            </p>
                        </div>

                        {/* Error Message */}
                        {error && (
                            <div className="w-full p-4 mb-4 bg-red-500/10 border border-red-500/20 rounded-xl">
                                <p className="text-red-400 text-sm text-center">{error}</p>
                            </div>
                        )}

                        {!selectedProvider ? (
                            /* Calendar Provider Selection */
                            <div className="w-full space-y-4">
                                {Object.entries(CALENDAR_PROVIDERS).map(([key, provider]) => (
                                    <button
                                        key={key}
                                        onClick={() => handleProviderSelect(key as keyof CalendarProviders)}
                                        className="w-full relative group"
                                    >
                                        <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                                        <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                                            <div className="flex items-center justify-center space-x-2">
                                                <span>{provider.name}</span>
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        ) : (
                            /* Credentials Form */
                            <div className="w-full space-y-4">
                                <div className="space-y-4">
                                    <input
                                        type="email"
                                        placeholder="Email/Username"
                                        value={credentials.username}
                                        onChange={(e) => setCredentials(prev => ({ ...prev, username: e.target.value }))}
                                        className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 text-white placeholder-gray-400"
                                    />
                                    <input
                                        type={isConnecting ? "password" : "text"}
                                        placeholder="App Password (e.g. xxxx-xxxx-xxxx-xxxx)"
                                        value={credentials.password}
                                        onChange={(e) => {
                                            // Remove any spaces or dashes from input
                                            const cleanPassword = e.target.value.replace(/[\s-]/g, '');
                                            // Format password with dashes every 4 characters
                                            const formattedPassword = cleanPassword.match(/.{1,4}/g)?.join('-') || cleanPassword;
                                            setCredentials(prev => ({ ...prev, password: formattedPassword }));
                                        }}
                                        className="w-full bg-white/5 border border-white/10 rounded-xl px-4 py-3 text-white placeholder-gray-400 font-mono"
                                    />
                                    <p className="text-sm text-gray-400 mt-1">
                                        {isConnecting ?
                                            "Connecting to calendar..." :
                                            "Enter your app password - it will be visible while you type"
                                        }
                                    </p>
                                    {selectedProvider && (
                                        <button
                                            onClick={() => setShowInstructions(true)}
                                            className="w-full text-sm text-gray-400 hover:text-white transition-colors mt-2"
                                        >
                                            How to get an App Password?
                                        </button>
                                    )}
                                </div>

                                <button
                                    onClick={handleConnect}
                                    disabled={isConnecting || !credentials.username || !credentials.password}
                                    className="w-full relative group disabled:opacity-50"
                                >
                                    <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                                    <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                                        <div className="flex items-center justify-center">
                                            <span>{isConnecting ? 'CONNECTING...' : 'CONNECT'}</span>
                                        </div>
                                    </div>
                                </button>

                                <button
                                    onClick={() => setSelectedProvider(null)}
                                    className="w-full text-gray-400 hover:text-white transition-colors"
                                >
                                    Back to providers
                                </button>
                            </div>
                        )}

                        {/* Skip Button */}
                        <button
                            onClick={() => window.close()}
                            className="w-full relative group mt-8"
                        >
                            <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                            <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                                <div className="flex items-center justify-center">
                                    <span>MAYBE LATER</span>
                                </div>
                            </div>
                        </button>
                    </>
                )}
            </>
        );
    };

    return (
        <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
            <div className="w-full max-w-md mx-auto space-y-6 py-12">
                <div className="bg-[#1E2738] rounded-[32px] border-2 border-white/10 p-8">
                    <div className="flex flex-col items-center">
                        {/* Logo */}
                        <div className="relative w-32 h-32 mb-6">
                            <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
                            <img
                                src={klickieLogo}
                                alt="Klickie"
                                className="absolute inset-0 w-full h-full object-contain rounded-full z-10"
                            />
                        </div>

                        {/* Title */}
                        <h1 className="text-[#FFD700] text-4xl text-center font-['Klickie_Font',_Arial,_sans-serif] mb-8">
                            {connectionSuccess ? 'CALENDAR CONNECTED!' : 'SYNC YOUR CALENDAR'}
                        </h1>

                        {renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
}