import React, {useState, useEffect, JSX} from 'react';
import { useParams } from 'react-router-dom';
import { Bell } from 'lucide-react';
import axios from 'axios';

// Define the Profile Data type
interface ProfileData {
    is2HourNotification: boolean;
    is24HourNotification: boolean;
    // Add other fields if necessary
}

const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

export default function Notifications(): JSX.Element {
    const { userId } = useParams();
    const [reminder2h, setReminder2h] = useState(true);
    const [reminder24h, setReminder24h] = useState(true);
    const [profileData, setProfileData] = useState<ProfileData | null>(null); // Type `profileData`

    // Fetch profile data when the component mounts
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                console.log("userId", userId);
                const response = await axios.get(`${API_URL}/api/profile/${userId}`);
                console.log(response);
                const profile = response.data as ProfileData; // Cast the response to the expected type
                console.log(profile);
                setProfileData(profile); // Store fetched profile data
                const { is2HourNotification, is24HourNotification } = profile;
                setReminder2h(is2HourNotification);
                setReminder24h(is24HourNotification);
            } catch (error) {
                console.error('Error fetching notification settings:', error);
            }
        };

        fetchNotifications();
    }, [userId]);

    const handleToggle = async (type: '2h' | '24h') => {
        const newValue = type === '2h' ? !reminder2h : !reminder24h;

        if (profileData) {
            try {
                // Construct updated profile data with the toggle value
                const updatedProfileData = {
                    ...profileData,
                    userId,
                    is2HourNotification: type === '2h' ? newValue : profileData.is2HourNotification,
                    is24HourNotification: type === '24h' ? newValue : profileData.is24HourNotification,
                };

                // Send the updated profile data to the API
                await axios.post(`${API_URL}/api/update-profile`, updatedProfileData);

                // Update local state with the new toggle value
                if (type === '2h') {
                    setReminder2h(newValue);
                } else {
                    setReminder24h(newValue);
                }

                // Track the event for analytics
                if (window.clarity) {
                    window.clarity("event", "notification_setting_changed", {
                        userId,
                        type,
                        enabled: newValue
                    });
                }
            } catch (error) {
                console.error('Error updating notification settings:', error);
            }
        } else {
            console.error('Profile data is not loaded yet');
        }
    };

    return (
        <div className="min-h-screen bg-[#1E2738] text-white p-8">
            <div className="max-w-2xl mx-auto">
                {/* Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-4">Notification Settings</h1>
                    <p className="text-gray-300 text-lg mb-8">
                        Manage your notification preferences for upcoming appointments
                    </p>
                </div>

                {/* Toggle Buttons */}
                <div className="grid gap-4">
                    {[{
                        title: "2 HOUR REMINDER",
                        description: "Get notified 2 hours before your appointments",
                        state: reminder2h,
                        toggle: () => handleToggle('2h')
                    }, {
                        title: "24 HOUR REMINDER",
                        description: "Get notified 24 hours before your appointments",
                        state: reminder24h,
                        toggle: () => handleToggle('24h')
                    }].map((setting, index) => (
                        <button
                            key={index}
                            onClick={setting.toggle}
                            className="w-full relative group"
                        >
                            <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                            <div className={`relative w-full ${setting.state ? 'bg-[#4895AA]' : 'bg-gray-600'} text-white py-4 px-6 rounded-xl font-semibold hover:opacity-90 transition-colors`}>
                                <div className="flex items-start space-x-4">
                                    <div className="bg-white/10 p-3 rounded-lg">
                                        <Bell className="w-6 h-6" />
                                    </div>
                                    <div className="text-left flex-1">
                                        <div className="font-bold text-lg">{setting.title}</div>
                                        <div className="text-gray-300 text-sm">{setting.description}</div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className={`w-12 h-6 rounded-full p-1 transition-colors ${setting.state ? 'bg-green-500' : 'bg-gray-400'}`}>
                                            <div className={`w-4 h-4 bg-white rounded-full transition-transform ${setting.state ? 'translate-x-6' : 'translate-x-0'}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}
