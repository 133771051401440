import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import OnboardingPro from './pages/OnboardingPro';
import OnboardingProSchedule from './pages/OnboardingProSchedule';
import Preview from './pages/Preview';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Test from 'Test';
import CalendarSync from './pages/CalendarSync';
import WhatNow from './pages/WhatNow';
import WhatNowCustomer from './pages/WhatNowCustomer';
import CustomerBookingHistory from './pages/CustomerBookingHistory';
import ProBookingHistory from './pages/ProBookingHistory';
import Callbacks from './pages/Callbacks';
import Network from './pages/Network';
import Cancellations from './pages/Cancellations';
import AdminDashboard from './pages/AdminDashboard';
import { useEffect } from 'react';
import Notifications from './pages/Notifications';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const TrackPageViews = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.clarity) {
      window.clarity("event", "page_view", {
        userId: localStorage.getItem("userId") || "unknown",
        page: location.pathname,
        referrer: document.referrer, // The previous page the user came from
        timestamp: new Date().toISOString(), // Timestamp of the visit
      });
    }
  }, [location]);
  return null;
};

function App() {
  return (
    <BrowserRouter>
      <TrackPageViews />
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="onboarding/:userId" element={<OnboardingPro />} />
          <Route path="schedule-availability/:userId" element={<OnboardingProSchedule />} />
          <Route path="preview/:userId" element={<Preview />} />
          <Route path="k/:userId" element={<Preview />} />
          <Route path="calendar-sync/:userId" element={<CalendarSync />} />
          <Route path="what-now/:userId" element={<WhatNow />} />
          <Route path="what-now-customer/:userId" element={<WhatNow />} />
          <Route path="customer-booking-history/:userId" element={<CustomerBookingHistory />} />
          <Route path="pro-booking-history/:clientId" element={<ProBookingHistory />} />
          <Route path="callbacks/:userId" element={<Callbacks />} />
          <Route path="network/:userId" element={<Network />} />
          <Route path="cancellations/:userId" element={<Cancellations />} />
          <Route path="test" element={<Test />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="notifications/:userId" element={<Notifications />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;