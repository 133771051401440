import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div className="min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
                <p className="text-sm text-gray-500 mb-6">Effective Date: March 30, 2025</p>

                <div className="space-y-8">
                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
                        <p className="text-gray-600">
                            Klickie B.V. ("Klickie," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect your personal data when you interact with our services via WhatsApp, SMS, or our website.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Information We Collect</h2>
                        <p className="text-gray-600 mb-4">We collect the following types of information:</p>
                        <ul className="list-disc pl-6 text-gray-600 space-y-2">
                            <li>Personal Information: Name, phone number, and any information you provide when using our service.</li>
                            <li>Usage Data: Messages sent through WhatsApp, SMS interactions, and service preferences.</li>
                            <li>Technical Data: Device information, IP address, and browser type.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">3. How We Use Your Information</h2>
                        <p className="text-gray-600 mb-4">We use your data for:</p>
                        <ul className="list-disc pl-6 text-gray-600 space-y-2">
                            <li>Providing and improving our services</li>
                            <li>Sending booking confirmations, reminders, and updates via WhatsApp or SMS</li>
                            <li>Customer support and troubleshooting</li>
                            <li>Compliance with legal requirements</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Data Sharing</h2>
                        <p className="text-gray-600">
                            We do not sell or rent your data. We may share data with:
                        </p>
                        <ul className="list-disc pl-6 text-gray-600 space-y-2">
                            <li>Service providers (e.g., WhatsApp API, cloud hosting)</li>
                            <li>Legal authorities if required by law</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Data Storage & Security</h2>
                        <p className="text-gray-600">
                            Your data is stored securely and only retained as long as necessary to provide our services. We implement safeguards to protect against unauthorized access or misuse.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Your Rights</h2>
                        <p className="text-gray-600 mb-4">You can:</p>
                        <ul className="list-disc pl-6 text-gray-600 space-y-2">
                            <li>Request access to your data</li>
                            <li>Request deletion of your data</li>
                            <li>Opt out of WhatsApp messages by sending "STOP"</li>
                        </ul>
                        <p className="text-gray-600 mt-4">
                            For requests, contact us at <a href="mailto:sidfer@klickie.me" className="text-blue-600 hover:underline">sidfer@klickie.me</a>.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Changes to This Policy</h2>
                        <p className="text-gray-600">
                            We may update this Privacy Policy. Changes will be posted on our website.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">8. Contact Us</h2>
                        <div className="text-gray-600">
                            <p>Klickie B.V.</p>
                            <p>Lange Houtstraat 13, 2511CV 's-Gravenhage, Netherlands</p>
                            <p>KVK: 96140712 | VAT: 867485863</p>
                            <p>Email: <a href="mailto:sidfer@klickie.me" className="text-blue-600 hover:underline">sidfer@klickie.me</a></p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
} 