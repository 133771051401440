import React, { useState } from 'react';
import PhoneInput from './PhoneInput';
import { countries } from '../utils/countries';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

interface SelfBookingModalProps {
  onClose: () => void;
  onConfirm: (clientData: any) => void;
  proName: string;
  userId: string;
}

export default function SelfBookingModal({ onClose, onConfirm, proName, userId }: SelfBookingModalProps) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedNumber, setFormattedNumber] = useState('');
  const [country, setCountry] = useState('BE');
  const [showNameInput, setShowNameInput] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [foundUser, setFoundUser] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handlePhoneSubmit = async () => {
    if (!formattedNumber) return;
    setError(null);

    try {
      // Check if user exists
      const response = await axios.get(`${API_URL}/api/profile/${formattedNumber}`);
      
      if (response.data) {
        // User found - show confirmation
        setFoundUser(response.data);
        setShowConfirmation(true);
      }
    } catch (err: any) {
      if (err.response?.status === 404) {
        // User not found - show name input
        setShowNameInput(true);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  const handleCreateUser = async () => {
    if (!name || !formattedNumber) return;
    setError(null);

    try {
      // Create new user with PRO type
      const response = await axios.post(`${API_URL}/api/users`, {
        name,
        phoneNumber: formattedNumber,
        userType: 'PRO'
      });

      if (response.data.success) {
        // Store temporary customer data for booking
        localStorage.setItem('tempCustomerData', JSON.stringify({
          userId: formattedNumber,
          userData: { name }
        }));
        onConfirm({
          userId: formattedNumber,
          name,
          isNewUser: true
        });
      } else {
        setError('Failed to create account');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred');
    }
  };

  const handleConfirmExistingUser = () => {
    // Store temporary customer data for booking
    localStorage.setItem('tempCustomerData', JSON.stringify({
      userId: formattedNumber,
      userData: foundUser
    }));
    onConfirm({
      userId: formattedNumber,
      name: foundUser.name,
      isNewUser: false
    });
  };

  const handleClose = () => {
    // Clear any temporary customer data
    localStorage.removeItem('tempCustomerData');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1E2738] rounded-3xl p-8 max-w-md w-full relative">
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          ✕
        </button>

        <h2 className="text-2xl font-bold text-[#FFD700] mb-6 text-center">
          Book for Client
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500 rounded-xl text-red-500 text-sm">
            {error}
          </div>
        )}

        {!showNameInput && !showConfirmation && (
          <div className="space-y-4">
            <p className="text-gray-300 text-center mb-4">
              Enter your client's phone number to proceed with the booking
            </p>
            <PhoneInput
              country={country}
              setCountry={setCountry}
              phoneNumber={phoneNumber}
              handlePhoneChange={(e) => setPhoneNumber(e.target.value)}
              isValid={phoneNumber.length > 0}
              onFormatted={setFormattedNumber}
            />
            {formattedNumber === userId && (
              <div className="mb-4 p-3 bg-yellow-500/10 border border-yellow-500 rounded-xl text-yellow-500 text-sm">
                You are trying to book your own service.
              </div>
            )}
            <button
              onClick={handlePhoneSubmit}
              disabled={formattedNumber === userId}
              className={`w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold transition-colors ${
                formattedNumber === userId ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#4895AA]/90'
              }`}
            >
              Continue
            </button>
          </div>
        )}

        {showNameInput && (
          <div className="space-y-4">
            <p className="text-gray-300 text-center mb-4">
              This client is new to Klickie. Please enter their name to create their account.
            </p>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter client's name"
              className="w-full bg-[#2A3447] text-white py-3 px-6 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#4895AA]"
            />
            <button
              onClick={handleCreateUser}
              className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
            >
              Create Account & Continue
            </button>
          </div>
        )}

        {showConfirmation && foundUser && (
          <div className="space-y-4">
            <p className="text-gray-300 text-center mb-4">
              Confirm booking for:
            </p>
            <div className="bg-[#2A3447] p-4 rounded-xl">
              <p className="text-white font-semibold">{foundUser.name}</p>
              <p className="text-gray-400">{formattedNumber}</p>
            </div>
            <button
              onClick={handleConfirmExistingUser}
              className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
            >
              Confirm & Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
} 