import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft } from 'lucide-react';
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

// Define types for better TypeScript support
interface TimeSlot {
  start: string;
  end: string;
}

interface ExceptionDay {
  timeSlots: TimeSlot[];
  breaks: TimeSlot[];
}

interface Exceptions {
  [key: string]: ExceptionDay;
}

export default function OnboardingProSchedule() {
  const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";
  const { userId } = useParams() as { userId: string };
  
  // State management
  const [availableDays, setAvailableDays] = useState<string[]>([]);
  const [defaultTimeSlot, setDefaultTimeSlot] = useState<TimeSlot>({ start: '09:00', end: '17:00' });
  const [exceptions, setExceptions] = useState<Exceptions>({});
  const [minNotice, setMinNotice] = useState('2');
  const [cancellationPolicy, setCancellationPolicy] = useState('2');
  const [maxBookingWindow, setMaxBookingWindow] = useState('3');
  const [minimumIntervalSlots, setMinimumIntervalSlots] = useState('30');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const [optimizationStatus, setOptimizationStatus] = useState('Without-optimization');
  const [extraCost, setExtraCost] = useState<string>('0');
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  
  // UI state for collapsible sections
  const [expandedSections, setExpandedSections] = useState({
    daysAvailable: true,
    defaultSchedule: true,
    exceptions: false,
    bookingRules: false,
    optimization: false
  });

  // UI state for editing booking rules
  const [editingMinNotice, setEditingMinNotice] = useState(false);
  const [editingCancellation, setEditingCancellation] = useState(false);
  const [editingBookingWindow, setEditingBookingWindow] = useState(false);
  const [editingTimeSlots, setEditingTimeSlots] = useState(false);

  // Toggle section expansion
  const toggleSection = (section: keyof typeof expandedSections) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const normalizedUserId = userId.startsWith('+') ? userId : `+${userId}`;
        console.log(`Fetching availability for userId: ${normalizedUserId}`);

        const response = await axios.get(`${API_URL}/api/profile/${normalizedUserId}`);
        const data = response.data;
        console.log('Full API response:', data);

        if (data && data.availability) {
          setIsNewUser(false);
          setAvailableDays(data.availability?.days || []);
          setDefaultTimeSlot(data.availability?.defaultTimeSlot || { start: '09:00', end: '17:00' });
          setExceptions(data.availability?.exceptions || {});
          setMinNotice(data.availability?.bookingRules?.minNotice || '2');
          setCancellationPolicy(data.availability?.bookingRules?.cancellationPolicy || '2');
          setMaxBookingWindow(data.availability?.bookingRules?.maxBookingWindow || '3');
          setMinimumIntervalSlots(data.availability?.minimumIntervalSlots || '30');
          
          // Initialize optimization states
          let optStatus = data.availability?.optimizationStatus || 'Without-optimization';
          if (optStatus === "With-optimization,standardizedTimes,Without-optimization") {
            optStatus = "Without-optimization";
          }
          setOptimizationStatus(optStatus);
          setExtraCost(data.availability?.extraCost || '0');
        } else {
          console.warn('No availability data found for this user. Proceeding with default values.');
          setIsNewUser(true);
        }
      } catch (error: any) {
        if (error.response?.status === 404) {
          console.warn('No availability data found for this user. Proceeding with default values.');
          setIsNewUser(true);
        } else {
          console.error('Error fetching availability data:', error);
          alert('Failed to fetch availability data. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchAvailability();
  }, [userId, API_URL]);

  const toggleDay = (day: string) => {
    setAvailableDays((prev: string[]) =>
      prev.includes(day) ? prev.filter((d: string) => d !== day) : [...prev, day]
    );
  };

  const updateDefaultTimeSlot = (field: 'start' | 'end', value: string) => {
    setDefaultTimeSlot((prev: TimeSlot) => ({ ...prev, [field]: value }));
  };

  const addException = (day: string) => {
    setExceptions((prev: Exceptions) => ({
      ...prev,
      [day]: { timeSlots: [{ ...defaultTimeSlot }], breaks: [{ ...defaultTimeSlot }] }
    }));
  };

  const updateException = (day: string, type: 'timeSlots' | 'breaks', index: number, field: 'start' | 'end', value: string) => {
    setExceptions((prev: Exceptions) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [type]: prev[day][type].map((item: TimeSlot, i: number) =>
          i === index ? { ...item, [field]: value } : item
        )
      }
    }));
  };

  const removeException = (day: string) => {
    setExceptions((prev: Exceptions) => {
      const { [day]: _, ...rest } = prev;
      return rest;
    });
  };

  const validateForm = () => {
    if (availableDays.length === 0) {
      alert('Please select at least one day of availability');
      return false;
    }

    if (!defaultTimeSlot.start || !defaultTimeSlot.end) {
      alert('Please set your default working hours');
      return false;
    }

    if (!minNotice || !cancellationPolicy || !maxBookingWindow) {
      alert('Please fill in all booking rules');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    try {
      setIsSubmitting(true);
      const normalizedUserId = userId.startsWith('+') ? userId : `+${userId}`;

      const availabilityData = {
        userId: normalizedUserId,
        availableDays,
        defaultTimeSlot,
        exceptions,
        bookingRules: {
          minNotice,
          cancellationPolicy,
          maxBookingWindow,
        },
        minimumIntervalSlots,
        optimizationStatus,
        extraCost: optimizationStatus === "With-optimization" ? Number(extraCost) : 0,
        sendPreview: true
      };

      const availabilityResponse = await axios.post(`${API_URL}/api/availability`, availabilityData);
      if (!availabilityResponse.data.success) {
        throw new Error(availabilityResponse.data.error || 'Failed to save availability');
      }

      window.location.href = 'https://api.whatsapp.com/send/?phone=3197010257400&type=phone_number&app_absent=0';
    } catch (error: any) {
      console.error('Error submitting form:', error);
      alert(error.response?.data?.error || 'Error saving availability. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const sanitizeData = (data: any) => {
    return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== undefined));
  };

  const handleUpdate = async () => {
    if (!validateForm()) return;
    
    try {
      setIsSubmitting(true);
      const responses = await axios.get(`${API_URL}/api/profile/${userId}`);
      console.log('Users data:', responses.data);
      
      const normalizedUserId = userId.startsWith('+') ? userId : `+${userId}`;
      const availabilityData = sanitizeData({
        userId: normalizedUserId,
        availableDays,
        defaultTimeSlot,
        exceptions,
        bookingRules: {
          minNotice,
          cancellationPolicy,
          maxBookingWindow,
        },
        minimumIntervalSlots,
        optimizationStatus,
        extraCost: optimizationStatus === "Without-optimization" ? 0 : Number(extraCost),
        sendPreview: true,
      });
      
      const response = await axios.post(`${API_URL}/api/availability`, availabilityData);
      if (!response.data.success) {
        throw new Error(response.data.error || "Failed to update profile.");
      }
      
      alert("Schedule updated successfully!");
      window.location.href = `/what-now/${userId}`;
    } catch (error: any) {
      console.error("Error updating profile:", error);
      alert(error.response?.data?.error || "Error updating profile. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Section header component for consistency
  const SectionHeader = ({ title, section }: { title: string, section: keyof typeof expandedSections }) => (
    <div 
      className="flex justify-between items-center cursor-pointer py-2" 
      onClick={() => toggleSection(section)}
    >
      <h2 className="text-white text-2xl">{title}</h2>
      <div className="text-white text-xl">
        {expandedSections[section] ? '−' : '+'}
      </div>
    </div>
  );

  // Check for unsaved changes
  const checkUnsavedChanges = () => {
    // Simple check - if we've modified any state, we have changes
    // In a real app, you might compare with original values
    return hasChanges;
  };

  // Handle back button click
  const handleBack = () => {
    if (checkUnsavedChanges()) {
      setShowUnsavedChangesModal(true);
    } else {
      window.history.back();
    }
  };

  // Go back without saving
  const goBackWithoutSaving = () => {
    setShowUnsavedChangesModal(false);
    window.history.back();
  };

  // Set hasChanges to true whenever a state change occurs
  useEffect(() => {
    if (!isLoading) {
      setHasChanges(true);
    }
  }, [availableDays, defaultTimeSlot, exceptions, minNotice, cancellationPolicy, maxBookingWindow, minimumIntervalSlots, optimizationStatus]);

  // Reset hasChanges after successful save/update
  useEffect(() => {
    if (!isSubmitting && !isLoading) {
      setHasChanges(false);
    }
  }, [isSubmitting]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#1E2738] flex flex-col items-center justify-center p-4">
        <div className="text-white text-xl">Loading your schedule settings...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
      <div className="w-full max-w-4xl space-y-6 py-6">
        <h1 className="text-[#FFD700] text-3xl mb-4 font-bold text-center">
          Set Your Availability
        </h1>
        
        {/* Days Available Section */}
        <div className="bg-[#1A202C] p-4 rounded-xl shadow-lg">
          <SectionHeader title="Days You're Available" section="daysAvailable" />
          
          {expandedSections.daysAvailable && (
            <div className="mt-3">
              <p className="text-gray-300 mb-4 text-sm">Select the days when you're available for bookings</p>
              
              <div className="grid grid-cols-7 gap-1 bg-[#2D3748] p-2 rounded-lg">
                {daysOfWeek.map(day => {
                  const isSelected = availableDays.includes(day);
                  const shortDay = day.substring(0, 3);
                  
                  return (
                    <div 
                key={day}
                onClick={() => toggleDay(day)}
                      className={`
                        flex flex-col items-center justify-center cursor-pointer transition-all duration-200
                        ${isSelected ? 'bg-[#4895AA]' : 'bg-[#1A202C] hover:bg-[#2A3749]'}
                        rounded-lg p-2 relative
                      `}
                    >
                      <div className={`
                        absolute top-1 right-1 w-2 h-2 rounded-full
                        ${isSelected ? 'bg-[#FFD700]' : 'bg-transparent'}
                      `}></div>
                      <span className={`text-xs font-medium ${isSelected ? 'text-white' : 'text-gray-400'}`}>
                        {shortDay}
                      </span>
                      <span className={`text-lg font-bold mt-1 ${isSelected ? 'text-white' : 'text-gray-300'}`}>
                        {day.charAt(0)}
                      </span>
                      <div className={`
                        mt-2 w-6 h-1 rounded-full
                        ${isSelected ? 'bg-white' : 'bg-transparent'}
                      `}></div>
                    </div>
                  );
                })}
              </div>
              
              <div className="flex justify-between mt-4">
                <button
                  onClick={() => setAvailableDays([])}
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Clear All
                </button>
                <button
                  onClick={() => setAvailableDays([...daysOfWeek])}
                  className="text-sm text-[#4895AA] hover:text-[#5AACBF] transition-colors"
                >
                  Select All Weekdays
              </button>
              </div>
          </div>
          )}
        </div>

        {/* Default Schedule Section with Integrated Exceptions */}
        <div className="bg-[#1A202C] p-4 rounded-xl shadow-lg">
          <SectionHeader title="Working Hours" section="defaultSchedule" />
          
          {expandedSections.defaultSchedule && (
            <div className="mt-3">
              <div className="flex justify-between items-center mb-4">
                <p className="text-gray-300 text-sm">Set your working hours for all selected days</p>
                <div className="flex items-center">
                  <span className="text-xs text-gray-400 mr-2">Same hours for all days</span>
                  <div 
                    className={`w-10 h-5 rounded-full relative cursor-pointer transition-colors duration-200 ${Object.keys(exceptions).length === 0 ? 'bg-[#4895AA]' : 'bg-gray-600'}`}
                    onClick={() => {
                      if (Object.keys(exceptions).length > 0) {
                        // Clear all exceptions
                        setExceptions({});
                      } else if (availableDays.length > 0) {
                        // Create exceptions for all available days with default time
                        const newExceptions: Exceptions = {};
                        availableDays.forEach(day => {
                          newExceptions[day] = {
                            timeSlots: [{ ...defaultTimeSlot }],
                            breaks: [{ start: '12:00', end: '13:00' }]
                          };
                        });
                        setExceptions(newExceptions);
                      }
                    }}
                  >
                    <div 
                      className={`absolute top-0.5 left-0.5 w-4 h-4 rounded-full bg-white transform transition-transform duration-200 ${Object.keys(exceptions).length === 0 ? 'translate-x-5' : 'translate-x-0'}`}
                    ></div>
                  </div>
                </div>
              </div>
              
              {/* Default Schedule for All Days */}
              <div className="bg-[#2D3748] p-4 rounded-lg mb-4">
                <h3 className="text-white text-base mb-3">Default Hours</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="text-white text-sm block mb-1">Start Time</label>
                  <input
                    type="time"
                    value={defaultTimeSlot.start}
                    onChange={(e) => updateDefaultTimeSlot('start', e.target.value)}
                    className="w-full bg-white rounded-xl p-2"
                      aria-label="Default start time"
                  />
                </div>
                <div>
                    <label className="text-white text-sm block mb-1">End Time</label>
                  <input
                    type="time"
                    value={defaultTimeSlot.end}
                    onChange={(e) => updateDefaultTimeSlot('end', e.target.value)}
                    className="w-full bg-white rounded-xl p-2"
                      aria-label="Default end time"
                  />
                  </div>
                </div>
              </div>
              
              {/* Day-Specific Schedules */}
              {availableDays.length > 0 && (
                <div>
                  {Object.keys(exceptions).length > 0 ? (
                    <div className="mb-3 flex justify-between items-center">
                      <h3 className="text-white text-base">Day-Specific Hours</h3>
                      <button
                        onClick={() => setExceptions({})}
                        className="text-xs text-[#4895AA] hover:text-[#5AACBF]"
                      >
                        Reset All to Default
                      </button>
                    </div>
                  ) : (
                    <div className="bg-[#1A202C] border border-gray-700 rounded-lg p-3 mb-3">
                      <div className="flex justify-between items-center">
                        <div>
                          <h3 className="text-white text-base">Need Different Hours for Specific Days?</h3>
                          <p className="text-xs text-gray-400 mt-1">Customize hours for individual days of the week</p>
            </div>
                        <button
                          onClick={() => {
                            // Create exceptions for all available days with default time
                            const newExceptions: Exceptions = {};
                            availableDays.forEach(day => {
                              newExceptions[day] = {
                                timeSlots: [{ ...defaultTimeSlot }],
                                breaks: [{ start: '12:00', end: '13:00' }]
                              };
                            });
                            setExceptions(newExceptions);
                          }}
                          className="px-3 py-1.5 bg-[#4895AA] text-white rounded-lg text-sm hover:bg-[#5AACBF] transition-colors"
                        >
                          Customize Days
                        </button>
          </div>
        </div>
                  )}

                  {Object.keys(exceptions).length > 0 && (
                    <div className="space-y-3">
          {availableDays.map(day => (
                        <div key={day} className="bg-[#2D3748] p-3 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center">
                              <div className="w-2 h-2 rounded-full bg-[#4895AA] mr-2"></div>
                              <h4 className="text-white font-medium">{day}</h4>
                            </div>
                            <div className="flex items-center gap-2">
                {exceptions[day] ? (
                  <button
                    onClick={() => removeException(day)}
                                  className="text-xs text-gray-300 hover:text-white"
                  >
                                  Reset
                  </button>
                ) : (
                  <button
                    onClick={() => addException(day)}
                                  className="text-xs text-[#4895AA] hover:text-[#5AACBF]"
                  >
                                  Customize
                  </button>
                )}
              </div>
                          </div>
                          
                          {exceptions[day] ? (
                            <div className="space-y-3">
                              <div>
                                <div className="grid grid-cols-2 gap-3">
                  <div>
                                    <label className="text-white text-xs block mb-1">Start</label>
                      <input
                        type="time"
                        value={exceptions[day].timeSlots[0].start}
                        onChange={(e) => updateException(day, 'timeSlots', 0, 'start', e.target.value)}
                                      className="w-full bg-white rounded-lg p-2 text-sm"
                      />
                                  </div>
                                  <div>
                                    <label className="text-white text-xs block mb-1">End</label>
                      <input
                        type="time"
                        value={exceptions[day].timeSlots[0].end}
                        onChange={(e) => updateException(day, 'timeSlots', 0, 'end', e.target.value)}
                                      className="w-full bg-white rounded-lg p-2 text-sm"
                      />
                    </div>
                  </div>
                                
                                <div className="mt-2">
                                  <div className="flex items-center justify-between">
                                    <label className="text-white text-xs">Break Time</label>
                                    <button className="text-xs text-gray-400 hover:text-white">
                                      {exceptions[day].breaks[0].start === exceptions[day].breaks[0].end ? 'Add Break' : 'Remove Break'}
                                    </button>
                                  </div>
                                  
                                  {exceptions[day].breaks[0].start !== exceptions[day].breaks[0].end && (
                                    <div className="grid grid-cols-2 gap-3 mt-1">
                  <div>
                      <input
                        type="time"
                        value={exceptions[day].breaks[0].start}
                        onChange={(e) => updateException(day, 'breaks', 0, 'start', e.target.value)}
                                          className="w-full bg-[#1A202C] border border-gray-600 rounded-lg p-2 text-sm text-white"
                      />
                                      </div>
                                      <div>
                      <input
                        type="time"
                        value={exceptions[day].breaks[0].end}
                        onChange={(e) => updateException(day, 'breaks', 0, 'end', e.target.value)}
                                          className="w-full bg-[#1A202C] border border-gray-600 rounded-lg p-2 text-sm text-white"
                      />
                    </div>
                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-xs text-gray-400 italic">Using default hours</div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Booking Rules Section */}
        <div className="bg-[#1A202C] p-4 rounded-xl shadow-lg">
          <SectionHeader title="Booking Rules" section="bookingRules" />
          
          {expandedSections.bookingRules && (
            <div className="mt-3">
              <p className="text-gray-300 mb-4 text-sm">Set rules for how customers can book your services</p>
              
              {/* Minimum Notice */}
              <div className="mb-5 bg-[#2D3748] p-3 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <div>
                    <h4 className="text-white text-sm font-medium">Minimum Advance Booking Time</h4>
                    <p className="text-xs text-gray-300">
                      {minNotice === "0" ? "No advance notice required" :
                       `${minNotice} hours before appointment`}
                    </p>
                  </div>
                  <button 
                    onClick={() => setEditingMinNotice(!editingMinNotice)}
                    className="text-xs text-blue-400 hover:text-blue-300 flex items-center"
                  >
                    {editingMinNotice ? "Done" : "Edit"}
                  </button>
                </div>
                
                {editingMinNotice && (
                  <div className="mt-2 pt-2 border-t border-gray-600">
                    <div className="flex flex-wrap gap-2">
                      <button
                        onClick={() => setMinNotice("0")} 
                        className={`px-2 py-1 text-xs rounded ${minNotice === "0" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        No notice
                      </button>
                      <button
                        onClick={() => setMinNotice("2")} 
                        className={`px-2 py-1 text-xs rounded ${minNotice === "2" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        2 hours
                      </button>
                      <button
                        onClick={() => setMinNotice("12")} 
                        className={`px-2 py-1 text-xs rounded ${minNotice === "12" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        12 hours
                      </button>
                      <button
                        onClick={() => setMinNotice("24")} 
                        className={`px-2 py-1 text-xs rounded ${minNotice === "24" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        24 hours
                      </button>
                      <button
                        onClick={() => setMinNotice("48")} 
                        className={`px-2 py-1 text-xs rounded ${minNotice === "48" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        48 hours
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* Cancellation Policy */}
              <div className="mb-5 bg-[#2D3748] p-3 rounded-lg">
                <div className="flex justify-between items-center mb-2">
            <div>
                    <h4 className="text-white text-sm font-medium">Cancellation Policy</h4>
                    <p className="text-xs text-gray-300">
                      {cancellationPolicy === "0" ? "Customers can cancel anytime without charge" :
                       `Customers must cancel ${cancellationPolicy} hours before to avoid full charge`}
                    </p>
                  </div>
                  <button 
                    onClick={() => setEditingCancellation(!editingCancellation)}
                    className="text-xs text-blue-400 hover:text-blue-300 flex items-center"
                  >
                    {editingCancellation ? "Done" : "Edit"}
                  </button>
                </div>
                
                {editingCancellation && (
                  <div className="mt-2 pt-2 border-t border-gray-600">
                    <p className="text-xs text-gray-400 mb-2">Set the minimum time before an appointment that customers can cancel without incurring the full cost of the service.</p>
                    <div className="flex flex-wrap gap-2">
                      <button
                        onClick={() => setCancellationPolicy("0")} 
                        className={`px-2 py-1 text-xs rounded ${cancellationPolicy === "0" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        Anytime
                      </button>
                      <button
                        onClick={() => setCancellationPolicy("2")} 
                        className={`px-2 py-1 text-xs rounded ${cancellationPolicy === "2" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        2 hours
                      </button>
                      <button
                        onClick={() => setCancellationPolicy("6")} 
                        className={`px-2 py-1 text-xs rounded ${cancellationPolicy === "6" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        6 hours
                      </button>
                      <button
                        onClick={() => setCancellationPolicy("12")} 
                        className={`px-2 py-1 text-xs rounded ${cancellationPolicy === "12" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        12 hours
                      </button>
                      <button
                        onClick={() => setCancellationPolicy("24")} 
                        className={`px-2 py-1 text-xs rounded ${cancellationPolicy === "24" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        24 hours
                      </button>
                    </div>
                  </div>
                )}
            </div>

              {/* Maximum Booking Window */}
              <div className="mb-5 bg-[#2D3748] p-3 rounded-lg">
                <div className="flex justify-between items-center mb-2">
            <div>
                    <h4 className="text-white text-sm font-medium">Maximum Booking Window</h4>
                    <p className="text-xs text-gray-300">
                      {maxBookingWindow === "30" ? "Up to 1 month in advance" :
                       maxBookingWindow === "3" ? "Up to 3 months in advance" :
                       maxBookingWindow === "6" ? "Up to 6 months in advance" :
                       "Up to 1 year in advance"}
                    </p>
                  </div>
                  <button 
                    onClick={() => setEditingBookingWindow(!editingBookingWindow)}
                    className="text-xs text-blue-400 hover:text-blue-300 flex items-center"
                  >
                    {editingBookingWindow ? "Done" : "Edit"}
                  </button>
                </div>
                
                {editingBookingWindow && (
                  <div className="mt-2 pt-2 border-t border-gray-600">
                    <div className="flex flex-wrap gap-2">
                      <button
                        onClick={() => setMaxBookingWindow("30")} 
                        className={`px-2 py-1 text-xs rounded ${maxBookingWindow === "30" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        1 month
                      </button>
                      <button
                        onClick={() => setMaxBookingWindow("3")} 
                        className={`px-2 py-1 text-xs rounded ${maxBookingWindow === "3" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        3 months
                      </button>
                      <button
                        onClick={() => setMaxBookingWindow("6")} 
                        className={`px-2 py-1 text-xs rounded ${maxBookingWindow === "6" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        6 months
                      </button>
                      <button
                        onClick={() => setMaxBookingWindow("12")} 
                        className={`px-2 py-1 text-xs rounded ${maxBookingWindow === "12" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        1 year
                      </button>
                    </div>
                  </div>
                )}
            </div>

              {/* Time Slot Visualization */}
              <div className="bg-[#2D3748] p-3 rounded-lg">
                <div className="flex justify-between items-center mb-2">
            <div>
                    <h4 className="text-white text-sm font-medium">Booking Time Slots</h4>
                    <p className="text-xs text-gray-300">
                      {minimumIntervalSlots === "15" ? "Every 15 minutes" :
                       minimumIntervalSlots === "30" ? "Every 30 minutes" :
                       "Every 60 minutes"}
                    </p>
                  </div>
                  <button 
                    onClick={() => setEditingTimeSlots(!editingTimeSlots)}
                    className="text-xs text-blue-400 hover:text-blue-300 flex items-center"
                  >
                    {editingTimeSlots ? "Done" : "Edit"}
                  </button>
                </div>
                
                {editingTimeSlots && (
                  <div className="mt-2 pt-2 border-t border-gray-600">
                    <div className="flex flex-wrap gap-2 mb-4">
                      <button
                        onClick={() => setMinimumIntervalSlots("15")} 
                        className={`px-2 py-1 text-xs rounded ${minimumIntervalSlots === "15" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        Every 15 minutes
                      </button>
                      <button
                        onClick={() => setMinimumIntervalSlots("30")} 
                        className={`px-2 py-1 text-xs rounded ${minimumIntervalSlots === "30" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        Every 30 minutes
                      </button>
                      <button
                        onClick={() => setMinimumIntervalSlots("60")} 
                        className={`px-2 py-1 text-xs rounded ${minimumIntervalSlots === "60" ? 'bg-blue-500 text-white' : 'bg-[#1A202C] text-gray-300 hover:bg-[#3D4A5C]'}`}
                      >
                        Every 60 minutes
                      </button>
                    </div>
                    
                    <div className="bg-[#1A202C] p-3 rounded-lg">
                      <div className="flex justify-between mb-2">
                        <span className="text-white text-xs">9:00</span>
                        <span className="text-white text-xs">10:00</span>
                      </div>
                      <div className="relative h-8 bg-gray-700 rounded-lg overflow-hidden">
                        {minimumIntervalSlots === "15" && (
                          <>
                            <div className="absolute top-0 bottom-0 left-0 w-1/4 border-r border-gray-600"></div>
                            <div className="absolute top-0 bottom-0 left-1/4 w-1/4 border-r border-gray-600"></div>
                            <div className="absolute top-0 bottom-0 left-2/4 w-1/4 border-r border-gray-600"></div>
                            <div className="absolute top-0 bottom-0 left-0 right-0 flex">
                              <div className="flex-1 flex items-center justify-center text-xs text-white">:15</div>
                              <div className="flex-1 flex items-center justify-center text-xs text-white">:30</div>
                              <div className="flex-1 flex items-center justify-center text-xs text-white">:45</div>
                              <div className="flex-1 flex items-center justify-center text-xs text-white">:00</div>
                            </div>
                          </>
                        )}
                        {minimumIntervalSlots === "30" && (
                          <>
                            <div className="absolute top-0 bottom-0 left-0 w-1/2 border-r border-gray-600"></div>
                            <div className="absolute top-0 bottom-0 left-0 right-0 flex">
                              <div className="flex-1 flex items-center justify-center text-xs text-white">:30</div>
                              <div className="flex-1 flex items-center justify-center text-xs text-white">:00</div>
                            </div>
                          </>
                        )}
                        {minimumIntervalSlots === "60" && (
                          <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-xs text-white">
                            Hourly Slots Only
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Optimization Preferences Section */}
        <div className="bg-[#1A202C] p-4 rounded-xl shadow-lg border-2 border-[#FFD700] relative">
          <div className="absolute top-0 right-0 bg-[#FFD700] text-black text-xs font-bold py-1 px-2 rounded-bl-lg">
            EXCLUSIVE FEATURE
          </div>
          <SectionHeader title="Smart Agenda Optimization" section="optimization" />
          
          {expandedSections.optimization && (
            <div className="mt-3">
              <p className="text-gray-300 mb-3">Our unique technology that maximizes your productivity</p>
              
              <div className="mb-4 bg-[#2D3748] p-3 rounded-lg border-l-4 border-[#FFD700]">
                <p className="text-white text-sm font-medium">✨ Professionals using Smart Scheduling report up to 30% fewer gaps in their day</p>
          </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="bg-[#2D3748] p-3 rounded-lg">
                  <h3 className="text-white font-medium mb-2">Without Optimization</h3>
                  <div className="flex flex-col gap-1 text-xs text-gray-300">
                    <div className="flex items-center gap-1">
                      <div className="w-2 h-2 rounded-full bg-red-500"></div>
                      <p>9:00 - 10:00 Client A</p>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="w-2 h-2 rounded-full bg-gray-500"></div>
                      <p>10:00 - 12:00 Empty Gap</p>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                      <p>12:00 - 13:00 Client B</p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#2D3748] p-3 rounded-lg">
                  <h3 className="text-white font-medium mb-2">With Smart Optimization</h3>
                  <div className="flex flex-col gap-1 text-xs text-gray-300">
                    <div className="flex items-center gap-1">
                      <div className="w-2 h-2 rounded-full bg-red-500"></div>
                      <p>9:00 - 10:00 Client A</p>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                      <p>10:00 - 11:00 Client B</p>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="w-2 h-2 rounded-full bg-green-500"></div>
                      <p>11:00 - 12:00 Available for new bookings</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="space-y-4">
                <div 
                  className={`relative overflow-hidden transition-all duration-300 rounded-xl ${
                    optimizationStatus === "With-optimization" 
                      ? 'bg-gradient-to-r from-[#4895AA] to-[#2D3748] border-2 border-[#4895AA] shadow-lg' 
                      : 'bg-[#2D3748] border border-gray-600 opacity-80'
                  } p-4`}
                >
                  <div className="absolute top-0 right-0">
                    <div className={`text-xs font-bold py-1 px-3 rounded-bl-lg ${
                      optimizationStatus === "With-optimization"
                        ? 'bg-[#FFD700] text-black'
                        : 'bg-gray-600 text-white'
                    }`}>
                      {optimizationStatus === "With-optimization" ? 'ACTIVE' : 'INACTIVE'}
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <div 
                      className={`flex-shrink-0 w-6 h-6 rounded-full border-2 ${
                        optimizationStatus === "With-optimization" 
                          ? 'border-[#FFD700] bg-[#FFD700]' 
                          : 'border-gray-500'
                      } flex items-center justify-center cursor-pointer`}
                      onClick={() => setOptimizationStatus("With-optimization")}
                    >
                      {optimizationStatus === "With-optimization" && (
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                      )}
                    </div>
                    <div className="flex-grow">
                      <label 
                        htmlFor="With-optimization" 
                        className={`block font-medium text-lg cursor-pointer ${
                          optimizationStatus === "With-optimization" ? 'text-white' : 'text-gray-400'
                        }`}
                        onClick={() => setOptimizationStatus("With-optimization")}
                      >
                        Smart Scheduling
                      </label>
                      <p className={`text-sm mt-1 ${
                        optimizationStatus === "With-optimization" ? 'text-white' : 'text-gray-400'
                      }`}>
                        Our AI automatically suggests booking times that minimize gaps in your schedule
                      </p>
                      
                {optimizationStatus === "With-optimization" && (
                        <div className="mt-3 space-y-2">
                          <ul className="list-disc list-inside text-sm text-white space-y-1 pl-1">
                            <li>Maximize your earnings by eliminating wasted time</li>
                            <li>Reduce travel time between appointments</li>
                            <li>Create more efficient workdays with fewer interruptions</li>
                          </ul>
                          
                          <div className="mt-4 bg-[#1A202C] p-3 rounded-lg border border-[#4895AA]">
                            <label className="text-white font-medium block">Non-Adjacent Booking Fee</label>
                            <div className="flex items-center mt-2">
                              <div className="flex-grow">
                                <p className="text-xs text-gray-300">Set an optional fee for customers who want to book non-adjacent time slots</p>
                                <p className="text-xs text-gray-400 mt-1">
                                  When Smart Scheduling is enabled, customers will be encouraged to book slots next to existing appointments. 
                                  If they prefer a time slot that creates a gap, they'll see a message and can choose to pay this extra fee.
                                </p>
                              </div>
                              <div className="flex items-center gap-2">
                                <span className="text-white text-lg">€</span>
                    <input
                      type="number"
                      min="0"
                      value={extraCost}
                      onChange={(e) => setExtraCost(e.target.value)}
                                  className="w-20 bg-white rounded-lg p-2 text-center font-medium"
                                  placeholder="0.00"
                                />
                              </div>
                            </div>
                            <p className="text-xs text-gray-400 mt-2 italic">
                              Set to €0 to encourage adjacent bookings without charging extra
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                
                <div 
                  className={`relative overflow-hidden transition-all duration-300 rounded-xl ${
                    optimizationStatus === "Without-optimization" 
                      ? 'bg-[#2D3748] border-2 border-gray-400' 
                      : 'bg-[#1A202C] border border-gray-600 opacity-80'
                  } p-4`}
                >
                  <div className="absolute top-0 right-0">
                    <div className={`text-xs font-bold py-1 px-3 rounded-bl-lg ${
                      optimizationStatus === "Without-optimization"
                        ? 'bg-gray-400 text-black'
                        : 'bg-gray-600 text-white'
                    }`}>
                      DEFAULT
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <div 
                      className={`flex-shrink-0 w-6 h-6 rounded-full border-2 ${
                        optimizationStatus === "Without-optimization" 
                          ? 'border-white bg-white' 
                          : 'border-gray-500'
                      } flex items-center justify-center cursor-pointer`}
                      onClick={() => setOptimizationStatus("Without-optimization")}
                    >
                      {optimizationStatus === "Without-optimization" && (
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                )}
              </div>
                    <div>
                      <label 
                        htmlFor="Without-optimization" 
                        className={`block font-medium cursor-pointer ${
                          optimizationStatus === "Without-optimization" ? 'text-white' : 'text-gray-400'
                        }`}
                        onClick={() => setOptimizationStatus("Without-optimization")}
                      >
                        Standard Scheduling
                </label>
                      <p className={`text-sm ${
                        optimizationStatus === "Without-optimization" ? 'text-gray-300' : 'text-gray-500'
                      }`}>
                        Customers can book any available time slot
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {/* Bottom action buttons */}
        <div className="mt-8 flex flex-col gap-4">
        {isNewUser ? (
          <button
            onClick={handleSubmit}
              disabled={isSubmitting}
              className={`w-full h-[50px] ${isSubmitting ? 'bg-gray-500' : 'bg-[#FFD700] hover:bg-[#FFD700]/90'} text-black rounded-xl font-bold text-xl`}
          >
              {isSubmitting ? 'Saving...' : 'Save Availability Settings'}
          </button>
        ) : (
          <button
            onClick={handleUpdate}
              disabled={isSubmitting}
              className={`w-full h-[50px] ${isSubmitting ? 'bg-gray-500' : 'bg-[#4895AA]'} text-white rounded-xl font-bold text-xl`}
            >
              {isSubmitting ? 'Updating...' : 'Update Schedule'}
            </button>
          )}
          
          <button
            onClick={handleBack}
            className="w-full h-[50px] bg-[#2D3748] hover:bg-[#3A4A63] text-white rounded-lg font-medium text-lg flex items-center justify-center transition-colors"
            aria-label="Go back"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back
          </button>
        </div>
        
        {/* Unsaved Changes Modal */}
        {showUnsavedChangesModal && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
            <div className="bg-[#1A202C] p-6 rounded-xl shadow-lg max-w-md w-full">
              <h3 className="text-xl font-bold text-white mb-4">Unsaved Changes</h3>
              <p className="text-gray-300 mb-6">You have unsaved changes. What would you like to do?</p>
              <div className="flex flex-col sm:flex-row gap-3">
                <button
                  onClick={() => setShowUnsavedChangesModal(false)}
                  className="flex-1 py-2 bg-[#2D3748] hover:bg-[#3A4A63] text-white rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={goBackWithoutSaving}
                  className="flex-1 py-2 bg-[#2D3748] hover:bg-[#3A4A63] text-white rounded-lg transition-colors"
                >
                  Discard Changes
                </button>
                <button
                  onClick={() => {
                    setShowUnsavedChangesModal(false);
                    isNewUser ? handleSubmit() : handleUpdate();
                  }}
                  className="flex-1 py-2 bg-[#4895AA] hover:bg-[#3A7A8A] text-white rounded-lg transition-colors"
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}