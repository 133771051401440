import React, { useState } from 'react';
import { countries } from '../utils/countries'; // This import can be removed if no longer needed

interface KlickieModalProps {
  onSubmit: (phoneNumber: string) => void; 
  onClose: () => void;
}

export default function KlickieModal({ onSubmit, onClose }: KlickieModalProps) {
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1E2738] rounded-3xl p-8 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          ✕
        </button>

        <h2 className="text-2xl font-bold text-[#FFD700] mb-6 text-center">
          Get Started
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500 rounded-xl text-red-500 text-sm">
            {error}
          </div>
        )}

        <div className="space-y-4">
          <p className="text-gray-300 mb-4 text-center">
            The user will be notified and you will be able to book services on this Klickie.
          </p>

          <button
           onClick={() => onSubmit("phoneNumber")} 
            className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
          >
            Confirm
          </button>

          <button
            onClick={onClose}
            className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
