import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { ChevronDown, Clock, Info } from "lucide-react";
import axios from "axios";
// import { isDisabled } from "@testing-library/user-event/dist/utils";

console.log(process.env.REACT_APP_API_URL);
console.log(process.env.NODE_ENV);

const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

interface TimeSlot {
  startTime: string;
  endTime: string;
}

interface CalendarEvent {
  id: string;
  summary: string;
  description: string;
  startDate: string;
  endDate: string;
  calendarName: string;
}

type WeekOption = "this-week" | "next-week" | "in-two-weeks" | "in-three-weeks";
interface TimeSlotWithExtraCost extends TimeSlot {
  extraCost: number;
}

interface AvailabilityMap {
  [date: string]: (TimeSlot & {
    isDisabled: boolean;
    isNextAvailable: boolean;
  })[]; // Add isNextAvailable flag
}

// Add interface for the ref
export interface TimeSelectorRef {
  resetBookingState: () => void;
}

// Convert to forwardRef
const TimeSlotSelector = forwardRef<TimeSelectorRef, {
  userId: string;
  serviceName: string;
  duration: string;
  onSelect: (slot: TimeSlot) => void;
  onCancel: () => void;
}>(({
  userId,
  serviceName,
  duration,
  onSelect,
  onCancel,
}, ref) => {
  // Define the shimmer animation keyframes
  const shimmerKeyframes = `
    @keyframes shimmer {
      0% { background-position: 200% 0; }
      100% { background-position: -200% 0; }
    }
    
    @keyframes loadingDots {
      0%, 20% {
        content: ".";
      }
      40%, 60% {
        content: "..";
      }
      80%, 100% {
        content: "...";
      }
    }
    
    .loading-dots::after {
      content: "";
      display: inline-block;
      animation: loadingDots 1.5s infinite;
    }
  `;

  const [selectedWeek, setSelectedWeek] = useState<WeekOption>("this-week");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [availabilityMap, setAvailabilityMap] = useState<AvailabilityMap>({});
  const [calendarEvents, setCalendarEvents] = useState<TimeSlot[]>([]);
  const [showAllSlots, setShowAllSlots] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedSlotTime, setSelectedSlotTime] = useState<any | null>(null);
  const [isNonOptimizedSlot, setIsNonOptimizedSlot] = useState(false);
  const [extraCost, setExtraCost] = useState<number>(0); // Assuming extra cost logic
  const [optimizationStatus, setOptimizationStatus] = useState<
    "With-optimization" | "Without-optimization"
  >("With-optimization"); // Store optimization status
  const [proName, setProName] = useState<string>("");
  const [processingProgress, setProcessingProgress] = useState<number>(0);
  const [processingStage, setProcessingStage] = useState<string>("");
  const [isBookingLoading, setIsBookingLoading] = useState(false);

  // Expose methods via the forwardRef
  useImperativeHandle(ref, () => ({
    resetBookingState: () => {
      setIsBookingLoading(false);
      setShowBookingModal(false);
    }
  }));

  const weeks: WeekOption[] = [
    "this-week",
    "next-week",
    "in-two-weeks",
    "in-three-weeks",
  ];
  const weekLabels: { [key in WeekOption]: string } = {
    "this-week": "THIS WEEK",
    "next-week": "NEXT WEEK",
    "in-two-weeks": "IN TWO WEEKS",
    "in-three-weeks": "IN THREE WEEKS",
  };

  const getWeekRange = (week: WeekOption) => {
    const today = new Date();
    const startDate = new Date(today);
    // First determine the offset in weeks
    let weekOffset = 0;
    switch (week) {
      case "next-week":
        weekOffset = 1;
        break;
      case "in-two-weeks":
        weekOffset = 2;
        break;
      case "in-three-weeks":
        weekOffset = 3;
        break;
    }
    // Add the week offset
    startDate.setDate(today.getDate() + (weekOffset * 7));
    // Adjust to the start of the week (Monday)
    const day = startDate.getDay();
    const diff = startDate.getDate() - day + (day === 0 ? -6 : 1); // Adjust for Monday as first day
    startDate.setDate(diff);
    // Set time to 00:00:00.000
    startDate.setUTCHours(0, 0, 0, 0);
    // Calculate end date (6 days after start date)
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    // Set to 23:59:59.999 (end of day)
    endDate.setUTCHours(23, 59, 59, 999);
    return { startDate, endDate };
  };

  const fetchAvailability = async () => {
    try {
      setProcessingStage(""); // Clear any previous message
      setProcessingProgress(0); // Reset progress
      setLoading(true);
      
      // Start with a clean state and wait a bit for UI to update
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // Initial stage
      setProcessingProgress(15);
      setProcessingStage("📅 Loading your booking calendar...");
      await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation
      
      const { startDate, endDate } = getWeekRange(selectedWeek);
      
      // Fetch calendar events
      setProcessingProgress(25);
      setProcessingStage("🔍 Checking availability in the calendar...");
      await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation
      
      let processedEvents: Array<{
        startTime: string;
        endTime: string;
        isDisabled: boolean;
        isNextAvailable: boolean;
      }> = [];
      
      try {
        const calendarResponse = await axios.get(
            `${API_URL}/api/calendar/events`,
            {
                params: {
                    userId,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                }
            }
        );
        
        // Update progress after network request completes
        setProcessingProgress(40);
        setProcessingStage("⏱️ Processing events and appointments...");
        await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation
        
        // Check if response is valid and contains expected data
        if (!calendarResponse.data || !calendarResponse.data.success) {
            throw new Error('Failed to fetch calendar events');
        }
        
        // Process all events, including expanded recurring instances
        calendarResponse.data.events
            .filter((event:any) => event.calendarName !== "Klickie Bookings")
            .forEach((event:any) => {
                // Check for Thursday events at 12:00 or 14:00
                const eventStart = new Date(event.startDate);
                const eventHour = eventStart.getUTCHours();
                const isThursday = eventStart.getDay() === 4; // 4 = Thursday
                
                if (isThursday && (eventHour === 12 || eventHour === 14)) {
                    console.log("FOUND THURSDAY EVENT AT 12:00 OR 14:00:", {
                        summary: event.summary,
                        description: event.description,
                        startDate: event.startDate,
                        endDate: event.endDate,
                        calendarName: event.calendarName,
                        isRecurring: event.isRecurring,
                        isRecurringInstance: event.isRecurringInstance,
                        baseEventId: event.baseEventId,
                        id: event.id,
                        allData: event
                    });
                }
                
                // Add each event (original or recurring instance)
                processedEvents.push({
                    startTime: new Date(event.startDate).toISOString(),
                    endTime: new Date(event.endDate).toISOString(),
                    isDisabled: true,
                    isNextAvailable: false
                });
                
                // Log recurring event information for debugging
                if (event.isRecurring) {
                    console.log(`Processing ${event.isRecurringInstance ? 'recurring instance' : 'base event'}: ${event.summary}`);
                    console.log(`  Start: ${event.startDate}, End: ${event.endDate}`);
                    if (event.isRecurringInstance) {
                        console.log(`  Instance of base event: ${event.baseEventId}`);
                    }
                }
            });
        
        console.log("Calendar events to be disabled:", processedEvents);
        // Update state with all processed events
        setCalendarEvents(processedEvents);
        setProcessingProgress(45);
        await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation
        
    } catch (error:any) {
        console.error("Error fetching calendar events:", error.message || error);
    }
    
    // Fetch profile data
    setProcessingStage("👤 Getting professional's details...");
    setProcessingProgress(50);
    await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation
    
    const profileResponse = await axios.get(
      `${API_URL}/api/profile/${userId}`
    );
    
    const proAvailabilityTimeIntervals = profileResponse.data?.availability;
    const services = profileResponse.data?.services || [];
    const defaultServiceIndex = services.findIndex(
      (service: any) => service.service_name === serviceName
    );
    const defaultService =
      defaultServiceIndex !== -1 ? services[defaultServiceIndex] : null;

    const extraCost = profileResponse.data?.availability?.extraCost || 0;
    setExtraCost(extraCost);

    // Extract the optimization status
    const optimizationStatus = proAvailabilityTimeIntervals?.optimizationStatus || "No-status";
    if (optimizationStatus === "Without-optimization") {
      setOptimizationStatus("Without-optimization");
    } else if (optimizationStatus.includes("With-optimization")) {
      setOptimizationStatus(optimizationStatus);
    } else {
      setOptimizationStatus("Without-optimization");
    }

    // Fetch available slots
    setProcessingStage(`🗓️ Creating your personalized schedule...`);
    setProcessingProgress(65);
    await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation
    
    const response = await axios.get(
      `${API_URL}/api/available-slots/${userId}`,
      {
        params: {
          startRange: startDate.toISOString(),
          endRange: endDate.toISOString(),
          duration: duration
        },
      }
    );

    const { availableSlots, existingBookings } = response.data;

    // Step 1: Combine ALL Slots (including existing bookings)
    const combinedSlots = [
      ...availableSlots.map((slot: TimeSlot) => ({
        ...slot,
        isDisabled: false,
        isNextAvailable: false,
      })),
      ...existingBookings.map((slot: TimeSlot) => ({
        ...slot,
        isDisabled: true,  // Mark existing bookings as disabled
        isNextAvailable: false,
      })),
    ];

    // Processing slots
    setProcessingStage("⚙️ Finding the best times for you...");
    setProcessingProgress(75);
    await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation

    // Step 2: Sort ALL Slots by startTime
    const sortedSlots = combinedSlots.sort(
      (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    );

    // Step 1: Reset all slots to non-available first
    const updatedSlots = sortedSlots.map((slot, index, slots) => {
      slot.isNextAvailable = false;
      return slot;
    });

    // Step 2: Group slots by day and process each day separately
    const slotsByDay = updatedSlots.reduce((acc: { [key: string]: any[] }, slot) => {
      const date = new Date(slot.startTime).toISOString().split('T')[0];
      if (!acc[date]) acc[date] = [];
      acc[date].push(slot);
      return acc;
    }, {});

    // Step 3: Process each day's slots
    Object.entries(slotsByDay).forEach(([date, daySlots]) => {
      const hasBookedSlots = daySlots.some(slot => slot.isDisabled);
      console.log(`Day ${date} has ${daySlots.filter(slot => slot.isDisabled).length} booked slots`);

      if (!hasBookedSlots) {
        // If no booked slots in the day, mark all as available (green)
        daySlots.forEach(slot => {
          slot.isNextAvailable = true;
        });
      } else {
        // If there are booked slots, only mark adjacent slots as available
        // Convert duration string to minutes
        const durationInMinutes = parseInt(duration);
        
        daySlots.forEach((slot, index) => {
          if (slot.isDisabled) {
            // Mark slot before booked slot as available if it would end exactly when the booked slot starts
            if (index > 0 && !daySlots[index - 1].isDisabled) {
              const slotStart = new Date(daySlots[index - 1].startTime);
              const slotEnd = new Date(slotStart);
              slotEnd.setMinutes(slotEnd.getMinutes() + durationInMinutes);
              
              const bookedSlotStart = new Date(slot.startTime);
              
              // Check if this slot would end exactly when the booked slot starts
              if (slotEnd.getTime() === bookedSlotStart.getTime()) {
                daySlots[index - 1].isNextAvailable = true;
              }
            }
            
            // Mark slot after booked slot as available if it would start exactly when the booked slot ends
            if (index < daySlots.length - 1 && !daySlots[index + 1].isDisabled) {
              const bookedSlotEnd = new Date(slot.endTime);
              const nextSlotStart = new Date(daySlots[index + 1].startTime);
              
              // Check if this slot would start exactly when the booked slot ends
              if (bookedSlotEnd.getTime() === nextSlotStart.getTime()) {
                daySlots[index + 1].isNextAvailable = true;
              }
            }
          }
        });
      }
    });

    console.log("Updated slots:", updatedSlots);
    // Ensure minimumIntervalSlots is a valid number
    const minimumIntervalSlots = Number(proAvailabilityTimeIntervals.minimumIntervalSlots);
    if (isNaN(minimumIntervalSlots) || minimumIntervalSlots <= 0) {
      console.error("Invalid minimum interval slots. Please provide a positive number.");
      return; // Stop further processing if the interval is invalid
    }
    console.log(minimumIntervalSlots, "minimumIntervalSlots")        // Step 3: Divide each slot into 30-minute intervals while preventing duplicates
    const uniqueSlotSet = new Set(); // Store unique startTime-endTime pairs
    const dividedSlots = updatedSlots.flatMap(slot => {
      const slots: (TimeSlot & { isDisabled: boolean; isNextAvailable: boolean })[] = [];
      let start = new Date(slot.startTime);
      const end = new Date(slot.endTime);
      const originalIsNextAvailable = slot.isNextAvailable; // Store original value

      while (start < end) {
        let nextSlotEnd = new Date(start);
        nextSlotEnd.setMinutes(nextSlotEnd.getMinutes() + minimumIntervalSlots); // Ensure minimum interval

        if (nextSlotEnd > end) {
          nextSlotEnd = end;
        }

        const slotKey = `${start.toISOString()}_${nextSlotEnd.toISOString()}`;
        if (!uniqueSlotSet.has(slotKey)) {
          uniqueSlotSet.add(slotKey);
          slots.push({
            ...slot,
            startTime: start.toISOString(),
            endTime: nextSlotEnd.toISOString(),
            isNextAvailable: originalIsNextAvailable, // Use original value for all sub-slots
            isDisabled: slot.isDisabled ?? false, // Ensure it has isDisabled property
          });
        }
        start = nextSlotEnd;
      }

      return slots;
    });

    console.log("Divided Slots:", dividedSlots);

    // Step 2: Identify missing slots (gaps between consecutive slots)
    const missingSlots: { startTime: string; endTime: string; isDisabled: boolean; isNextAvailable: boolean }[] = [];

    // Sort slots by startTime to ensure correct sequence
    const orderedSlots = [...dividedSlots].sort(
      (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    );

    // Loop through sorted slots and find gaps
    for (let i = 0; i < orderedSlots.length - 1; i++) {
      const currentSlotEnd = new Date(orderedSlots[i].endTime);
      const nextSlotStart = new Date(orderedSlots[i + 1].startTime);

      // If there's a gap between consecutive slots
      while (currentSlotEnd < nextSlotStart) {
        let gapEnd = new Date(currentSlotEnd);
        gapEnd.setMinutes(gapEnd.getMinutes() + minimumIntervalSlots); // Maintain minimum interval

        if (gapEnd > nextSlotStart) {
          gapEnd = nextSlotStart;
        }

        missingSlots.push({
          startTime: currentSlotEnd.toISOString(),
          endTime: gapEnd.toISOString(),
          isDisabled: false, // Default to false
          isNextAvailable: false, // Default to false
        });

        currentSlotEnd.setMinutes(currentSlotEnd.getMinutes() + minimumIntervalSlots);
      }
    }

    console.log("Missing Slots:", missingSlots);

    // Step 3: Merge missing slots into dividedSlots and sort them
    const finalSlots = [...dividedSlots, ...missingSlots]
      .map(slot => ({
        ...slot,
        isDisabled: slot.isDisabled ?? false,
        isNextAvailable: slot.isNextAvailable ?? false,
      }))
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

    console.log("Final Slots after Merging Missing Slots:", finalSlots);

    const defaultTimeSlot = proAvailabilityTimeIntervals.defaultTimeSlot;

    console.log(defaultTimeSlot, "defaultTimeSlot = {'start': '12:00','end': '18:00'}");

    // Convert default start and end to minutes (UTC)
    const [startHour, startMinute] = defaultTimeSlot.start.split(":").map(Number);
    const defaultStartMinutes = startHour * 60 + startMinute;

    const [endHour, endMinute] = defaultTimeSlot.end.split(":").map(Number);
    const defaultEndMinutes = endHour * 60 + endMinute;

    console.log(defaultStartMinutes, "defaultStartMinutes");
    console.log(defaultEndMinutes, "defaultEndMinutes");
    finalSlots.forEach(slot => {
      processedEvents.forEach((event: any) => {
        let slotStart = new Date(slot.startTime).getTime();
        let slotEnd = new Date(slot.endTime).getTime();
        let eventStart = new Date(event.startTime).getTime();
        let eventEnd = new Date(event.endTime).getTime();
        
        // ✅ Ensure that full & partial overlap are considered
        if (
          (slotStart >= eventStart && slotStart < eventEnd) ||  // Slot starts inside event
          (slotEnd > eventStart && slotEnd <= eventEnd) ||      // Slot ends inside event
          (slotStart <= eventStart && slotEnd >= eventEnd)      // Slot fully covers event
        ) {
          slot.isDisabled = true;
          slot.isNextAvailable = false;
        }
      });
    });

    // Check for overlaps with the booking duration
    const durationInMinutes = parseInt(duration);
    finalSlots.forEach(slot => {
      // Skip already disabled slots
      if (slot.isDisabled) return;
      
      // Check if this slot would overlap with any booked slot based on the booking duration
      const slotStart = new Date(slot.startTime);
      const slotEndWithDuration = new Date(slotStart);
      slotEndWithDuration.setMinutes(slotEndWithDuration.getMinutes() + durationInMinutes);
      
      // Check against all booked slots
      const bookedSlots = finalSlots.filter(s => s.isDisabled);
      for (const bookedSlot of bookedSlots) {
        const bookedStart = new Date(bookedSlot.startTime);
        const bookedEnd = new Date(bookedSlot.endTime);
        
        // Check if this slot would overlap with the booked slot
        if (
          (slotStart < bookedEnd && slotEndWithDuration > bookedStart) // Overlap
        ) {
          slot.isDisabled = true;
          slot.isNextAvailable = false;
          break;
        }
      }
      
      // Also check against calendar events
      processedEvents.forEach((event: any) => {
        let eventStart = new Date(event.startTime).getTime();
        let eventEnd = new Date(event.endTime).getTime();
        
        // Check if this slot would overlap with the event
        if (
          (slotStart.getTime() < eventEnd && slotEndWithDuration.getTime() > eventStart) // Overlap
        ) {
          slot.isDisabled = true;
          slot.isNextAvailable = false;
        }
      });
    });

    // Filter slots to keep only those within the defined range
    const filteredSlots = finalSlots.filter(slot => {
      const slotStart = new Date(slot.startTime);
      const slotEnd = new Date(slot.endTime);

      // Create reference date objects for this slot's date
      const slotDate = slotStart.toISOString().split('T')[0];

      // Create default start and end times for this specific date
      const defaultStartTime = new Date(`${slotDate}T${defaultTimeSlot.start}:00Z`);
      const defaultEndTime = new Date(`${slotDate}T${defaultTimeSlot.end}:00Z`);

      // Compare full timestamps
      return slotStart >= defaultStartTime && slotEnd <= defaultEndTime;
    });

    console.log("Final Filtered Slots:", filteredSlots);

    // Filter out slots from previous days
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of today
    
    const currentDayFilteredSlots = filteredSlots.filter(slot => {
      const slotStart = new Date(slot.startTime);
      
      // Extract just the date part (without time)
      const slotDate = new Date(slotStart);
      slotDate.setHours(0, 0, 0, 0);
      
      // Keep only slots from today or future days
      return slotDate >= today;
    });
    
    console.log("Slots after filtering previous days:", currentDayFilteredSlots);

    // After filtering previous days, apply the correct optimization logic
    console.log("Applying optimization logic with status:", optimizationStatus);
    
    // First, check if we need to process days without bookings
    const processedDays = new Set<string>();
    
    // Process each slot
    currentDayFilteredSlots.forEach(slot => {
      if (slot.isDisabled) return; // Skip disabled slots
      
      const slotDate = new Date(slot.startTime).toISOString().split('T')[0];
      
      // Check if we've already processed this day
      if (processedDays.has(slotDate)) return;
      
      // Get all slots for this day
      const slotsForThisDay = currentDayFilteredSlots.filter(s => 
        new Date(s.startTime).toISOString().split('T')[0] === slotDate
      );
      
      // Check if this day has any booked slots
      const hasBookedSlots = slotsForThisDay.some(s => s.isDisabled);
      console.log(`Processing day ${slotDate}: ${hasBookedSlots ? 'Has booked slots' : 'No booked slots'}`);
      
      if (!hasBookedSlots) {
        // If no booked slots for this day, mark all as optimized
        console.log(`Marking all slots as optimized for day ${slotDate} (no bookings)`);
        slotsForThisDay.forEach(s => {
          if (!s.isDisabled) {
            s.isNextAvailable = true;
          }
        });
      } else {
        // If there are booked slots, first reset all to non-optimized
        slotsForThisDay.forEach(s => {
          if (!s.isDisabled) {
            s.isNextAvailable = false;
          }
        });
        
        // Then mark only those adjacent to booked slots as optimized
        const bookedSlots = slotsForThisDay.filter(s => s.isDisabled);
        console.log(`Found ${bookedSlots.length} booked slots for day ${slotDate}`);
        
        slotsForThisDay.forEach(s => {
          if (s.isDisabled) return;
          
          const slotStart = new Date(s.startTime);
          const slotEndWithDuration = new Date(slotStart);
          slotEndWithDuration.setMinutes(slotEndWithDuration.getMinutes() + parseInt(duration));
          
          // Check if this slot is adjacent to any booked slot
          const isAdjacent = slotsForThisDay.some(otherSlot => {
            if (!otherSlot.isDisabled) return false;
            
            const otherStart = new Date(otherSlot.startTime);
            const otherEnd = new Date(otherSlot.endTime);
            
            // Check if this slot ends exactly when the booked slot starts
            // or starts exactly when the booked slot ends
            const endsWhenBookedStarts = slotEndWithDuration.getTime() === otherStart.getTime();
            const startsWhenBookedEnds = slotStart.getTime() === otherEnd.getTime();
            
            // Debug logging
            if (endsWhenBookedStarts || startsWhenBookedEnds) {
              console.log(`Checking adjacency for slot ${slotStart.toUTCString()}:`);
              console.log(`  - Slot ends at: ${slotEndWithDuration.toUTCString()}`);
              console.log(`  - Booked slot starts at: ${otherStart.toUTCString()}`);
              console.log(`  - Booked slot ends at: ${otherEnd.toUTCString()}`);
              console.log(`  - endsWhenBookedStarts: ${endsWhenBookedStarts}`);
              console.log(`  - startsWhenBookedEnds: ${startsWhenBookedEnds}`);
              console.log(`  - Slot minutes: ${slotStart.getMinutes()}`);
              console.log(`  - Duration: ${duration} minutes`);
              console.log(`  - Minimum interval: ${minimumIntervalSlots} minutes`);
            }
            
            // Special handling for 60-minute durations based on interval
            const durationNum = parseInt(duration);
            
            if (durationNum === 60) {
              if (endsWhenBookedStarts) {
                const slotMinutes = slotStart.getMinutes();
                
                // For 15-minute intervals, only slots starting at :45 are optimized
                if (minimumIntervalSlots === 15) {
                  return slotMinutes === 45;
                }
                
                // For 30-minute intervals, only slots starting at :30 are optimized
                if (minimumIntervalSlots === 30) {
                  return slotMinutes === 30;
                }
                
                // For other intervals, use default behavior
                return true;
              } else if (startsWhenBookedEnds) {
                // Slots that start exactly when a booking ends are always optimized
                return true;
              }
              return false;
            }
            
            // For other duration/interval combinations, use the general adjacency logic
            return endsWhenBookedStarts || startsWhenBookedEnds;
          });
          
          if (isAdjacent) {
            s.isNextAvailable = true;
            console.log(`Marked slot at ${slotStart.toUTCString()} as optimized (adjacent to booking)`);
            
            // Add more detailed logging
            const slotMinutes = slotStart.getMinutes();
            console.log(`  - Slot details: Hour=${slotStart.getHours()}, Minutes=${slotMinutes}`);
            console.log(`  - Duration: ${duration} minutes`);
            console.log(`  - Interval size: ${minimumIntervalSlots} minutes`);
            
            if (parseInt(duration) === 60) {
              if (minimumIntervalSlots === 15) {
                console.log(`  - Optimization rule: For 60-min duration with 15-min intervals, only slots starting at :45 are optimized`);
              } else if (minimumIntervalSlots === 30) {
                console.log(`  - Optimization rule: For 60-min duration with 30-min intervals, only slots starting at :30 are optimized`);
              } else {
                console.log(`  - Optimization rule: Using default optimization logic for this interval size`);
              }
            } else {
              console.log(`  - Optimization rule: Using default optimization logic for this duration`);
            }
          }
        });
      }
      
      // Mark this day as processed
      processedDays.add(slotDate);
    });

    // Add debugging logs for optimization status
    if (optimizationStatus === "With-optimization") {
      console.log("Optimization is ENABLED");
      
      // Log all optimized slots for debugging
      const optimizedSlots = currentDayFilteredSlots.filter(slot => 
        !slot.isDisabled && slot.isNextAvailable
      );
      
      console.log(`Found ${optimizedSlots.length} optimized slots:`);
      optimizedSlots.forEach(slot => {
        const slotStart = new Date(slot.startTime);
        console.log(`Optimized slot: ${slotStart.toUTCString()}`);
      });
    } else {
      console.log("Optimization is DISABLED");
    }

    console.log("Updated Final Slots:", currentDayFilteredSlots);

    setProcessingStage("✨ Finalizing your booking options...");
    setProcessingProgress(95);
    await new Promise(resolve => setTimeout(resolve, 300)); // Brief pause for animation

    console.log("Updated Final Slots:", currentDayFilteredSlots);

    // Step 5: Group slots by date
    const newAvailabilityMap: AvailabilityMap = {};
    currentDayFilteredSlots.forEach((slot: any) => {
      const slotDate = new Date(slot.startTime).toISOString().split("T")[0];

      if (!newAvailabilityMap[slotDate]) {
        newAvailabilityMap[slotDate] = [];
      }
      newAvailabilityMap[slotDate].push(slot);
    });

    console.log("Final availability map:", newAvailabilityMap);
    setProcessingProgress(100);
    setProcessingStage("✅ Ready to book!");
    
    // Short delay before setting the new availability map and finishing loading
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Set data and finish loading
    setAvailabilityMap(newAvailabilityMap);
    
    // Use the name from the profile response
    const proName = profileResponse.data?.name || "Professional";
    console.log(proName, "proName")
    setProName(proName);

  } catch (err) {
    console.error("Error fetching availability:", err);
    setError(
      err instanceof Error ? err.message : "Failed to load availability"
    );
  } finally {
    setLoading(false);
    setProcessingProgress(0);
  }
};

useEffect(() => {
  fetchAvailability();
}, [selectedWeek, userId, duration, serviceName] as const);

const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 24-hour format if needed
  });
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString([], {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
};

const handleConfirmBooking = () => {
  if (selectedSlotTime) {
    setIsBookingLoading(true); // Set loading state
    
    // Create a new slot with the correct end time based on duration
    const startTime = selectedSlotTime.startTime;
    const startDate = new Date(startTime);
    const endDate = new Date(startTime);
    endDate.setMinutes(startDate.getMinutes() + parseInt(duration));

    const bookingSlot = {
      startTime: startTime,
      endTime: endDate.toISOString(),
    };

    // Pass the slot to the parent component's handler
    onSelect(bookingSlot);
    
    // The modal will remain open with loading state
    // The parent component will need to handle closing it
  }
};

const toggleShowAllSlots = (date: string) => {
  setShowAllSlots((prev) => ({
    ...prev,
    [date]: !prev[date],
  }));
};

return (
  <>
    <style>{shimmerKeyframes}</style>
    <div className="fixed inset-0 bg-[#1E2738] overflow-y-auto pt-4 pb-20">
      <div className="w-full max-w-md mx-auto bg-[#1E2738] rounded-3xl p-8 relative">
        {/* Header */}
        <div className="text-center mb-6">
          <h1 className="text-[#FFD700] text-3xl font-bold mb-2">
            {serviceName}
          </h1>
          <div className="text-gray-400 mb-2">Select an available time slot</div>
          
          {/* Service Details */}
          <div className="bg-[#1A202C] p-3 rounded-lg mt-3 inline-block">
            <div className="flex items-center justify-center">
              <Clock className="w-4 h-4 text-[#4895AA] mr-2" />
              <span className="text-white text-sm">{duration} minutes with {proName}</span>
            </div>
          </div>
        </div>

        {/* Legend for Slot Types */}
        {optimizationStatus === "With-optimization" && (
          <div className="bg-[#1A202C] p-4 rounded-xl mb-6">
            <div className="flex items-center mb-2">
              <Info className="w-5 h-5 text-[#FFD700] mr-2" />
              <h3 className="text-white font-semibold">Slot Types</h3>
            </div>
            <div className="space-y-2 text-sm">
              <div className="flex items-center">
                <div className="w-4 h-4 bg-[#4895AA] rounded-full mr-2"></div>
                <span className="text-white">Optimized slots - Adjacent to existing bookings</span>
              </div>
              <div className="flex items-center">
                <div className="w-4 h-4 bg-[#FCBF11] rounded-full mr-2"></div>
                <span className="text-white">Standard slots - May create gaps in schedule</span>
              </div>
              <div className="flex items-center">
                <div className="w-4 h-4 bg-gray-400 rounded-full mr-2"></div>
                <span className="text-white">Unavailable - Already booked or outside hours</span>
              </div>
            </div>
          </div>
        )}

        {/* Week Selection */}
        <div className="space-y-3 mb-6">
          <div className="text-white font-medium mb-2">Select a week:</div>
          <div className="grid grid-cols-2 gap-3">
            {weeks.map((week) => (
              <button
                key={week}
                onClick={() => setSelectedWeek(week)}
                className={`relative rounded-xl border-2 p-3 transition-colors ${
                  selectedWeek === week
                    ? "bg-[#1A202C] border-[#4895AA] text-white"
                    : "bg-[#1A202C] border-[#2D3748] text-gray-400"
                }`}
              >
                <div className="text-center">
                  <span className="text-sm font-medium">{weekLabels[week]}</span>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Time Slots */}
        <div className="space-y-4">
          {loading ? (
            <div className="bg-[#1A202C] p-8 rounded-xl text-center">
              <div className="flex flex-col items-center mb-4">
                <div className="w-12 h-12 rounded-full bg-[#2D3748] flex items-center justify-center mb-5 animate-pulse">
                  <Clock className="w-7 h-7 text-[#4895AA]" />
                </div>
                
                {processingStage && (
                  <div className="text-white text-lg font-medium mb-3">{processingStage}</div>
                )}
                
                <div className="w-full bg-[#2D3748] rounded-full h-3 mb-4 overflow-hidden">
                  <div 
                    className="bg-[#4895AA] h-3 rounded-full relative" 
                    style={{ 
                      width: `${processingProgress}%`,
                      transition: 'width 0.5s ease-in-out'
                    }}
                  >
                    <div 
                      className="absolute inset-0 bg-white opacity-10" 
                      style={{
                        animation: 'shimmer 2s infinite linear',
                        background: 'linear-gradient(to right, transparent 0%, rgba(255,255,255,0.2) 50%, transparent 100%)',
                        backgroundSize: '200% 100%',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              
              <p className="text-white">We're preparing your booking options<span className="loading-dots"></span></p>
              <p className="text-gray-400 text-sm mt-2">This might take a few seconds</p>
            </div>
          ) : error ? (
            <div className="bg-[#1A202C] p-8 rounded-xl text-center">
              <div className="text-[#FCBF11] mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <p className="text-white mb-2">Error Loading Slots</p>
              <p className="text-gray-400 text-sm">{error}</p>
            </div>
          ) : Object.keys(availabilityMap).length === 0 ? (
            <div className="bg-[#1A202C] p-8 rounded-xl text-center">
              <div className="text-[#4895AA] mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
              <p className="text-white mb-2">No Available Slots</p>
              <p className="text-gray-400 text-sm">There are no available slots for this week. Please try another week.</p>
            </div>
          ) : (
            Object.entries(availabilityMap).map(([date, slots]) => {
              const showAll = showAllSlots[date] || false;
              const visibleSlots = showAll ? slots : slots.slice(0, 5);
              const hasMore = slots.length > 5;
              const availableCount = slots.filter(slot => !slot.isDisabled).length;

              // Group slots by time of day
              const morningSlots = visibleSlots.filter(slot => {
                const hour = new Date(slot.startTime).getUTCHours();
                return hour >= 0 && hour < 12;
              });
              
              const afternoonSlots = visibleSlots.filter(slot => {
                const hour = new Date(slot.startTime).getUTCHours();
                return hour >= 12 && hour < 17;
              });
              
              const eveningSlots = visibleSlots.filter(slot => {
                const hour = new Date(slot.startTime).getUTCHours();
                return hour >= 17 && hour < 24;
              });

              return (
                <div key={date} className="bg-[#1A202C] p-4 rounded-xl space-y-3 mb-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-[#FFD700] font-semibold">
                      {formatDate(date)}
                    </h3>
                    <span className="text-white text-sm bg-[#2D3748] px-2 py-1 rounded-full">
                      {availableCount} available
                    </span>
                  </div>
                  
                  {morningSlots.length > 0 && (
                    <div className="mb-3">
                      <div className="text-[#4895AA] text-xs font-medium mb-2 uppercase">Morning</div>
                      <div className="space-y-2">
                        {morningSlots.map((slot, index) => (
                          optimizationStatus === "With-optimization" ? (
                            <button key={index} onClick={() => {
                              if (!slot.isDisabled) {
                                setSelectedSlotTime(slot);
                                setIsNonOptimizedSlot(!slot.isNextAvailable);
                                setShowBookingModal(true);
                              }
                            }}
                              className={`w-full flex items-center mb-2 ${slot.isDisabled ? "opacity-70 cursor-not-allowed" : ""}`}
                              disabled={slot.isDisabled}>
                              <div className={`flex-1 rounded-xl border-2 ${
                                slot.isDisabled 
                                  ? "bg-gray-700 border-gray-600" 
                                  : slot.isNextAvailable 
                                    ? "bg-[#1A202C] border-[#4895AA]" 
                                    : "bg-[#1A202C] border-[#FCBF11]"
                              } p-3`}>
                                <div className="flex items-center">
                                  <div className={`w-3 h-3 rounded-full mr-2 ${
                                    slot.isDisabled 
                                      ? "bg-gray-400" 
                                      : slot.isNextAvailable 
                                        ? "bg-[#4895AA]" 
                                        : "bg-[#FCBF11]"
                                  }`}></div>
                                  <Clock className="w-4 h-4 text-white mr-2" />
                                  <span className="text-white font-medium">{formatTime(slot.startTime)}</span>
                                </div>
                              </div>
                            </button>)
                            :
                            (<button key={index}
                              onClick={() => {
                                if (!slot.isDisabled) {
                                  setSelectedSlotTime(slot);
                                  setIsNonOptimizedSlot(false);
                                  setShowBookingModal(true);
                                }
                              }}
                              className={`w-full flex items-center mb-2 ${slot.isDisabled ? "opacity-70 cursor-not-allowed" : ""}`}
                              disabled={slot.isDisabled}>
                              <div className={`flex-1 rounded-xl border-2 ${
                                slot.isDisabled 
                                  ? "bg-gray-700 border-gray-600" 
                                  : "bg-[#1A202C] border-[#4895AA]"
                              } p-3`}>
                                <div className="flex items-center">
                                  <div className={`w-3 h-3 rounded-full mr-2 ${
                                    slot.isDisabled 
                                      ? "bg-gray-400" 
                                      : "bg-[#4895AA]"
                                  }`}></div>
                                  <Clock className="w-4 h-4 text-white mr-2" />
                                  <span className="text-white font-medium">{formatTime(slot.startTime)}</span>
                                </div>
                              </div>
                            </button>)
                        ))}
                      </div>
                    </div>
                  )}
                  
                  {afternoonSlots.length > 0 && (
                    <div className="mb-3">
                      <div className="text-[#4895AA] text-xs font-medium mb-2 uppercase">Afternoon</div>
                      <div className="space-y-2">
                        {afternoonSlots.map((slot, index) => (
                          optimizationStatus === "With-optimization" ? (
                            <button key={index} onClick={() => {
                              if (!slot.isDisabled) {
                                setSelectedSlotTime(slot);
                                setIsNonOptimizedSlot(!slot.isNextAvailable);
                                setShowBookingModal(true);
                              }
                            }}
                              className={`w-full flex items-center mb-2 ${slot.isDisabled ? "opacity-70 cursor-not-allowed" : ""}`}
                              disabled={slot.isDisabled}>
                              <div className={`flex-1 rounded-xl border-2 ${
                                slot.isDisabled 
                                  ? "bg-gray-700 border-gray-600" 
                                  : slot.isNextAvailable 
                                    ? "bg-[#1A202C] border-[#4895AA]" 
                                    : "bg-[#1A202C] border-[#FCBF11]"
                              } p-3`}>
                                <div className="flex items-center">
                                  <div className={`w-3 h-3 rounded-full mr-2 ${
                                    slot.isDisabled 
                                      ? "bg-gray-400" 
                                      : slot.isNextAvailable 
                                        ? "bg-[#4895AA]" 
                                        : "bg-[#FCBF11]"
                                  }`}></div>
                                  <Clock className="w-4 h-4 text-white mr-2" />
                                  <span className="text-white font-medium">{formatTime(slot.startTime)}</span>
                                </div>
                              </div>
                            </button>)
                            :
                            (<button key={index}
                              onClick={() => {
                                if (!slot.isDisabled) {
                                  setSelectedSlotTime(slot);
                                  setIsNonOptimizedSlot(false);
                                  setShowBookingModal(true);
                                }
                              }}
                              className={`w-full flex items-center mb-2 ${slot.isDisabled ? "opacity-70 cursor-not-allowed" : ""}`}
                              disabled={slot.isDisabled}>
                              <div className={`flex-1 rounded-xl border-2 ${
                                slot.isDisabled 
                                  ? "bg-gray-700 border-gray-600" 
                                  : "bg-[#1A202C] border-[#4895AA]"
                              } p-3`}>
                                <div className="flex items-center">
                                  <div className={`w-3 h-3 rounded-full mr-2 ${
                                    slot.isDisabled 
                                      ? "bg-gray-400" 
                                      : "bg-[#4895AA]"
                                  }`}></div>
                                  <Clock className="w-4 h-4 text-white mr-2" />
                                  <span className="text-white font-medium">{formatTime(slot.startTime)}</span>
                                </div>
                              </div>
                            </button>)
                        ))}
                      </div>
                    </div>
                  )}
                  
                  {eveningSlots.length > 0 && (
                    <div className="mb-3">
                      <div className="text-[#4895AA] text-xs font-medium mb-2 uppercase">Evening</div>
                      <div className="space-y-2">
                        {eveningSlots.map((slot, index) => (
                          optimizationStatus === "With-optimization" ? (
                            <button key={index} onClick={() => {
                              if (!slot.isDisabled) {
                                setSelectedSlotTime(slot);
                                setIsNonOptimizedSlot(!slot.isNextAvailable);
                                setShowBookingModal(true);
                              }
                            }}
                              className={`w-full flex items-center mb-2 ${slot.isDisabled ? "opacity-70 cursor-not-allowed" : ""}`}
                              disabled={slot.isDisabled}>
                              <div className={`flex-1 rounded-xl border-2 ${
                                slot.isDisabled 
                                  ? "bg-gray-700 border-gray-600" 
                                  : slot.isNextAvailable 
                                    ? "bg-[#1A202C] border-[#4895AA]" 
                                    : "bg-[#1A202C] border-[#FCBF11]"
                              } p-3`}>
                                <div className="flex items-center">
                                  <div className={`w-3 h-3 rounded-full mr-2 ${
                                    slot.isDisabled 
                                      ? "bg-gray-400" 
                                      : slot.isNextAvailable 
                                        ? "bg-[#4895AA]" 
                                        : "bg-[#FCBF11]"
                                  }`}></div>
                                  <Clock className="w-4 h-4 text-white mr-2" />
                                  <span className="text-white font-medium">{formatTime(slot.startTime)}</span>
                                </div>
                              </div>
                            </button>)
                            :
                            (<button key={index}
                              onClick={() => {
                                if (!slot.isDisabled) {
                                  setSelectedSlotTime(slot);
                                  setIsNonOptimizedSlot(false);
                                  setShowBookingModal(true);
                                }
                              }}
                              className={`w-full flex items-center mb-2 ${slot.isDisabled ? "opacity-70 cursor-not-allowed" : ""}`}
                              disabled={slot.isDisabled}>
                              <div className={`flex-1 rounded-xl border-2 ${
                                slot.isDisabled 
                                  ? "bg-gray-700 border-gray-600" 
                                  : "bg-[#1A202C] border-[#4895AA]"
                              } p-3`}>
                                <div className="flex items-center">
                                  <div className={`w-3 h-3 rounded-full mr-2 ${
                                    slot.isDisabled 
                                      ? "bg-gray-400" 
                                      : "bg-[#4895AA]"
                                  }`}></div>
                                  <Clock className="w-4 h-4 text-white mr-2" />
                                  <span className="text-white font-medium">{formatTime(slot.startTime)}</span>
                                </div>
                              </div>
                            </button>)
                        ))}
                      </div>
                    </div>
                  )}

                  {hasMore && !showAll && (
                    <button
                      onClick={() => toggleShowAllSlots(date)}
                      className="w-full text-[#FFD700] py-2 flex items-center justify-center space-x-2 hover:text-[#FFD700]/80 transition-colors"
                    >
                        <span>SHOW MORE SLOTS</span>
                        <ChevronDown className="w-4 h-4" />
                      </button>
                  )}
                </div>
              );
            })
          )}
        </div>

        {/* Back Button */}
        <button onClick={onCancel} className="w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg transition-colors mt-6">
          <span>BACK</span>
        </button>
      </div>
    </div>

    {showBookingModal && (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-[#1E2738] rounded-3xl p-8 max-w-md w-full relative">
          <h2 className="text-2xl font-bold text-[#FFD700] mb-4 text-center">
            Confirm Booking
          </h2>
          <div className="space-y-4">
            <div className="bg-[#1A202C] p-4 rounded-xl">
              <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-between">
                  <span className="text-gray-400">Service:</span>
                  <span className="text-white font-medium">{serviceName}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-gray-400">Date:</span>
                  <span className="text-white font-medium">
                    {selectedSlotTime && formatDate(selectedSlotTime.startTime)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-gray-400">Time:</span>
                  <span className="text-white font-medium">
                    {selectedSlotTime && formatTime(selectedSlotTime.startTime)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-gray-400">Duration:</span>
                  <span className="text-white font-medium">{duration} minutes</span>
                </div>
              </div>
            </div>
            
            {isNonOptimizedSlot && (
              <div className="bg-[#FCBF11]/10 border border-[#FCBF11] rounded-xl p-4">
                <div className="flex items-start">
                  <div className="text-[#FCBF11] mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="text-sm text-[#FCBF11]">
                    This time slot is not optimized. It may leave gaps in {proName}'s schedule.
                    {extraCost > 0 && (
                      <span className="block mt-1 font-medium">Additional fee: €{extraCost}</span>
                    )}
                  </div>
                </div>
              </div>
            )}
            
            <div className="flex space-x-4">
              <button
                onClick={() => setShowBookingModal(false)}
                disabled={isBookingLoading}
                className={`flex-1 border border-[#4895AA] text-[#4895AA] py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/10 transition-colors ${isBookingLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmBooking}
                disabled={isBookingLoading}
                className="flex-1 bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors relative"
              >
                {isBookingLoading ? (
                  <div className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>Processing...</span>
                  </div>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);
});

// Add display name for better debugging
TimeSlotSelector.displayName = 'TimeSlotSelector';

export default TimeSlotSelector;

