import React, {useState, useEffect, JSX} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Calendar, Clock, ArrowLeft, AlertCircle, CheckCircle, XCircle, Clock3, Phone, Filter, ChevronDown, ChevronUp, Calendar as CalendarIcon, ArrowRight } from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';
import { useNavigate } from "react-router-dom";
import BookingCalendar from '../components/BookingCalendar';

const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

interface ServiceDetails {
    name: string;
    price: number;
    duration: number;
    service_name: string;
    service_type: string;
    rule: string
}

interface rescheduleData {
    reason: string,
    rescheduleStartTime: string,
    requestedByType: string,
    rescheduleDate: string,
    rescheduleRequestedBy: string,
    rescheduleEndTime: string
}

interface Booking {
    id: string;
    bookingId: string;
    clientName: string;
    startTime: string;
    endTime: string;
    status: string;
    proName: string;
    serviceName: string;
    serviceDetails: ServiceDetails;
    rescheduleData: rescheduleData;
    clientId: string;
}

interface BaseBooking {
    id: string;
    startTime: string;
    endTime: string;
    status: string;
    proName: string;
    clientName: string;
    serviceName: string;
}

// Add this helper function before the component
const toBaseBooking = (booking: Booking) => ({
    id: booking.id,
    startTime: booking.startTime,
    endTime: booking.endTime,
    status: booking.status,
    proName: booking.proName,
    clientName: booking.clientName,
    serviceName: booking.serviceName
});

const getStatusBadge = (status: string) => {
    const baseClasses = "px-2 py-1 rounded-full text-xs font-medium inline-flex items-center gap-1";
    switch (status.toLowerCase()) {
        case 'confirmed':
            return (
                <span className={`${baseClasses} bg-green-100 text-green-800`}>
                    <CheckCircle className="w-3 h-3" />
                    Confirmed
                </span>
            );
        case 'pending':
            return (
                <span className={`${baseClasses} bg-yellow-100 text-yellow-800`}>
                    <Clock3 className="w-3 h-3" />
                    Pending
                </span>
            );
        case 'cancelled':
        case 'canceled':
            return (
                <span className={`${baseClasses} bg-red-100 text-red-800`}>
                    <XCircle className="w-3 h-3" />
                    Cancelled
                </span>
            );
        default:
            return (
                <span className={`${baseClasses} bg-gray-100 text-gray-800`}>
                    {status}
                </span>
            );
    }
};

export default function ProBookingHistory(): JSX.Element {
    const { clientId } = useParams();
    const location = useLocation();
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedReason, setSelectedReason] = useState<string | null>(null);
    const [selectedReasonForReschedule, setSelectedReasonForReschedule] = useState("");
    const [customReason, setCustomReason] = useState<string>('');
    const [bookingToCancel, setBookingToCancel] = useState<any | null>(null);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
    const [customMessage, setCustomMessage] = useState("");
    const [view, setView] = useState<'calendar' | 'timeline'>('timeline');
    const [filterExpanded, setFilterExpanded] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>(['pending', 'confirmed']);
    const [dateRange, setDateRange] = useState<'today' | 'week' | 'month' | 'all'>('week');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showPendingModal, setShowPendingModal] = useState(false);

    useEffect(() => {
        // Check for showPending query parameter
        const queryParams = new URLSearchParams(location.search);
        const shouldShowPending = queryParams.get('showPending') === 'true';
        
        if (shouldShowPending) {
            setShowPendingModal(true);
        }
    }, [location]);

    useEffect(() => {
        const fetchMyBookings = async () => {
            if (!clientId) return; // Return early if clientId is not available
            try {
                const response = await fetch(`${API_URL}/api/pro-bookings/${clientId}`);
                if (!response.ok) throw new Error('Failed to fetch bookings');
                const data: Booking[] = await response.json(); // Explicitly define the type


                const updatedData: Booking[] = data.map((booking: Booking) => ({
                    ...booking,
                    status: (booking.serviceDetails.rule === 'Consent' && booking.status === 'consent') ? 'pending' : booking.status
                }));


                setBookings(updatedData);
                console.log("updated Data:", updatedData)
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to load bookings');
            } finally {
                setLoading(false);
            }
        };

        fetchMyBookings();
    }, [clientId]);

    const formatBookingTime = (booking: Booking) => {
        const formatOptions: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // Ensures AM/PM format
            timeZone: 'UTC', // Forces UTC time
        };

        if (booking?.rescheduleData?.rescheduleStartTime) {
            const rescheduleStart = new Date(booking.rescheduleData.rescheduleStartTime).toLocaleString('en-US', formatOptions);
            const rescheduleEnd = new Date(booking.rescheduleData.rescheduleEndTime).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
                timeZone: 'UTC'
            });

            return (
                <span>
                    <span style={{ color: 'orange' }}>Rescheduled:</span> {rescheduleStart} - {rescheduleEnd}
                </span>
            );
        }

        const start = new Date(booking.startTime).toLocaleString('en-US', formatOptions);
        const end = new Date(booking.endTime).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC'
        });

        return `${start} - ${end}`;
    };

    const handleCancelBooking = async (selectedBooking: any, reason: string) => {
        if (!selectedBooking || !selectedBooking.id || !selectedBooking.proName || !selectedBooking.startTime) {
            console.error('Invalid booking data:', selectedBooking);
            alert('Failed to cancel booking. Invalid booking details.');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/pro-cancel-booking`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    bookingId: selectedBooking.id,
                    clientPhoneNumber: selectedBooking.clientId,
                    message: reason
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to cancel booking: ${errorText}`);
            }

            const data = await response.json(); // Parse the response body if needed

            // Update the booking status to "canceled"
            setBookings((prevBookings) =>
                prevBookings.map((b) =>
                    b.id === selectedBooking.id ? { ...b, status: 'canceled' } : b
                )
            );

            setShowCancelModal(false);
            setSelectedReason(null);
            setCustomReason('');
            setSuccessModalVisible(true); // Show success modal
            setTimeout(() => setSuccessModalVisible(false), 1000); // Hide modal after 2 seconds

        } catch (err) {
            console.error('Error canceling booking:', err);
            alert('Failed to cancel booking. Please try again later.');
        }
    };

    const handleBookingClick = (booking: Booking | BaseBooking): void => {
        let targetBooking: Booking | undefined;
        
        if ('serviceDetails' in booking) {
            targetBooking = booking as Booking;
        } else {
            targetBooking = bookings.find(b => b.id === booking.id);
        }
        
        if (!targetBooking) return;
        
        // Only show the details modal, don't automatically open cancel modal
        setSelectedBooking(targetBooking);
    };

    const openModal = (booking: Booking) => {
        // Close the details modal before showing cancel modal
        setSelectedBooking(null);
        setShowCancelModal(true);
        setBookingToCancel(booking);
    };

    const handleConfirmBooking = async (selectedBooking: any) => {
        if (!selectedBooking || !selectedBooking.id || !selectedBooking.proName || !selectedBooking.startTime) {
            console.error('Invalid booking data:', selectedBooking);
            alert('Failed to confirm booking. Invalid booking details.');
            return;
        }

        const isConsentBooking = (
            selectedBooking.serviceDetails?.rule === 'Consent' &&
            (!selectedBooking?.rescheduleData || Object.keys(selectedBooking.rescheduleData).length === 0)
        );

        console.log("isConsentBooking ---> ", isConsentBooking)
        try {
            const apiUrl = isConsentBooking
                ? `${API_URL}/api/consent-confirm/${selectedBooking.id}`
                : `${API_URL}/api/reschedule-confirm/${selectedBooking.id}`
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to confirm booking: ${errorText}`);
            }

            const data = await response.json(); // Parse the response body if needed

            // Update the booking status to "confirmed"
            setBookings((prevBookings) =>
                prevBookings.map((b) =>
                    b.id === selectedBooking.id ? { ...b, status: 'confirmed' } : b
                )
            );

            alert(data.message || "Booking confirmed successfully!"); // Show a success message

        } catch (err) {
            console.error('Error confirming booking:', err);
            alert('Failed to confirm booking. Please try again later.');
        }
    };

    const navigateToReschedulePage = (booking: any, rescheduleReason: any) => {
        if (!booking) {
            console.error("Invalid booking object", booking);
            return;
        }
        // Call handleBookingAgain and pass both booking and reschedule reason
        handleBookingAgain(booking, rescheduleReason);
    };
    const navigate = useNavigate();
    const handleBookingAgain = (service: any, rescheduleReason: any) => {
        if (!service?.proId) {
            console.error("Invalid service object", service);
            return;
        }
        service.requested_by_reschedule = 'pro'
        // Navigate to the desired path with state including the reason
        navigate(`/k/${service.proId}`, { state: { service, rescheduleReason } });
    };

    // Function to format date for display
    const formatDateHeader = (date: Date) => {
        return date.toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    // Function to get bookings for selected date
    const getBookingsForDate = () => {
        return bookings
            .filter(b => {
                const bookingDate = new Date(b.startTime);
                return bookingDate.toDateString() === selectedDate.toDateString();
            })
            .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
    };

    // Function to navigate days
    const navigateDay = (direction: 'prev' | 'next') => {
        const newDate = new Date(selectedDate);
        if (direction === 'next') {
            newDate.setDate(newDate.getDate() + 1);
        } else {
            newDate.setDate(newDate.getDate() - 1);
        }
        setSelectedDate(newDate);
    };

    // Filter bookings based on current filters
    const getFilteredBookings = () => {
        let filtered = [...bookings];

        // Filter by status
        if (selectedStatuses.length > 0) {
            filtered = filtered.filter(b => selectedStatuses.includes(b.status.toLowerCase()));
        }

        // Filter by date range
        const today = new Date();
        switch (dateRange) {
            case 'today':
                filtered = filtered.filter(b => 
                    new Date(b.startTime).toDateString() === today.toDateString()
                );
                break;
            case 'week':
                const weekFromNow = new Date(today);
                weekFromNow.setDate(today.getDate() + 7);
                filtered = filtered.filter(b => 
                    new Date(b.startTime) >= today && new Date(b.startTime) <= weekFromNow
                );
                break;
            case 'month':
                const monthFromNow = new Date(today);
                monthFromNow.setMonth(today.getMonth() + 1);
                filtered = filtered.filter(b => 
                    new Date(b.startTime) >= today && new Date(b.startTime) <= monthFromNow
                );
                break;
            // 'all' case doesn't need filtering
        }

        return filtered;
    };

    const pendingCount = bookings.filter(b => 
        b.status === 'pending' && new Date(b.startTime) > new Date()
    ).length;

    if (loading) return (
        <div className="min-h-screen bg-[#1E2738] flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#4895AA]"></div>
        </div>
    );

    if (error) return (
        <div className="min-h-screen bg-[#1E2738] flex items-center justify-center">
            <div className="bg-red-500/10 border border-red-500 rounded-xl p-4 text-red-500">
                {error}
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-[#1E2738] p-4">
            {/* Header Section */}
            <div className="max-w-7xl mx-auto mb-8">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                    <div>
                        <h1 className="text-3xl font-bold text-white mb-2">
                            Customer Bookings
                        </h1>
                        <p className="text-gray-400">
                            {pendingCount > 0 
                                ? `You have ${pendingCount} pending ${pendingCount === 1 ? 'booking' : 'bookings'} that need your attention`
                                : 'All your bookings are up to date'}
                        </p>
                    </div>
                    <div className="flex gap-2">
                        <button
                            onClick={() => setView('timeline')}
                            className={`px-4 py-2 rounded-xl font-medium transition-colors ${
                                view === 'timeline' 
                                    ? 'bg-[#4895AA] text-white' 
                                    : 'bg-[#4895AA]/10 text-[#4895AA] hover:bg-[#4895AA]/20'
                            }`}
                        >
                            Today
                        </button>
                        <button
                            onClick={() => setView('calendar')}
                            className={`px-4 py-2 rounded-xl font-medium transition-colors ${
                                view === 'calendar' 
                                    ? 'bg-[#4895AA] text-white' 
                                    : 'bg-[#4895AA]/10 text-[#4895AA] hover:bg-[#4895AA]/20'
                            }`}
                        >
                            Calendar
                        </button>
                        <button
                            onClick={() => setFilterExpanded(!filterExpanded)}
                            className="px-4 py-2 rounded-xl font-medium bg-[#4895AA]/10 text-[#4895AA] hover:bg-[#4895AA]/20 transition-colors"
                        >
                            <Filter className="w-5 h-5" />
                        </button>
                    </div>
                </div>

                {/* Filters Panel */}
                {filterExpanded && (
                    <div className="mt-4 bg-[#4895AA]/10 rounded-xl p-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-400 mb-2">
                                    Status
                                </label>
                                <div className="space-y-2">
                                    {['pending', 'confirmed', 'cancelled'].map(status => (
                                        <label key={status} className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={selectedStatuses.includes(status)}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        setSelectedStatuses([...selectedStatuses, status]);
                                                    } else {
                                                        setSelectedStatuses(selectedStatuses.filter(s => s !== status));
                                                    }
                                                }}
                                                className="rounded border-gray-600 text-[#4895AA] focus:ring-[#4895AA]"
                                            />
                                            <span className="text-white capitalize">{status}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-400 mb-2">
                                    Date Range
                                </label>
                                <div className="space-y-2">
                                    {[
                                        { value: 'today', label: 'Today' },
                                        { value: 'week', label: 'Next 7 Days' },
                                        { value: 'month', label: 'Next 30 Days' },
                                        { value: 'all', label: 'All Time' }
                                    ].map(option => (
                                        <label key={option.value} className="flex items-center space-x-2">
                                            <input
                                                type="radio"
                                                checked={dateRange === option.value}
                                                onChange={() => setDateRange(option.value as any)}
                                                className="text-[#4895AA] focus:ring-[#4895AA]"
                                            />
                                            <span className="text-white">{option.label}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Floating Pending Button */}
            {pendingCount > 0 && (
                <div className="fixed bottom-6 right-6 z-50">
                    <button
                        onClick={() => setShowPendingModal(true)}
                        className="relative group"
                    >
                        <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                        <div className="relative inline-flex items-center justify-center px-6 py-3 bg-[#4895AA] text-white rounded-xl font-semibold hover:opacity-90 transition-colors shadow-lg">
                            <Clock3 className="w-5 h-5 mr-2" />
                            {pendingCount} Pending {pendingCount === 1 ? 'Booking' : 'Bookings'}
                        </div>
                    </button>
                </div>
            )}

            {/* Pending Bookings Modal */}
            {showPendingModal && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-[#1E2738] rounded-xl p-6 w-full max-w-2xl max-h-[80vh] overflow-y-auto mx-4">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-[#FFD700]">Pending Bookings</h2>
                            <button 
                                onClick={() => setShowPendingModal(false)}
                                className="text-gray-400 hover:text-white"
                            >
                                ✕
                            </button>
                        </div>
                        <div className="space-y-4">
                            {bookings
                                .filter(b => b.status === 'pending' && new Date(b.startTime) > new Date())
                                .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
                                .map(booking => (
                                    <div 
                                        key={booking.id}
                                        className="bg-[#4895AA]/10 rounded-xl p-4 hover:bg-[#4895AA]/20 transition-colors"
                                    >
                                        <div className="flex justify-between items-start">
                                            <div className="space-y-2">
                                                <div className="flex items-center gap-2">
                                                    <h3 className="font-medium text-lg text-white">
                                                        {booking.clientName}
                                                    </h3>
                                                    {getStatusBadge(booking.status)}
                                                </div>
                                                <div className="flex items-center gap-2 text-gray-400">
                                                    <Clock className="w-4 h-4" />
                                                    <span>{formatBookingTime(booking)}</span>
                                                </div>
                                                <div className="flex items-center gap-2 text-gray-400">
                                                    <Calendar className="w-4 h-4" />
                                                    <span>{booking.serviceName}</span>
                                                </div>
                                                <div className="flex items-center gap-2 text-gray-400">
                                                    <Phone className="w-4 h-4" />
                                                    <span>{booking.clientId}</span>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                {booking.status === 'pending' && (
                                                    <div className="w-full">
                                                        <button
                                                            onClick={() => handleConfirmBooking(booking)}
                                                            className="relative group w-full"
                                                        >
                                                            <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                            <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                                <CheckCircle className="w-4 h-4 mr-2" />
                                                                Confirm
                                                            </div>
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="w-full">
                                                    <button
                                                        onClick={() => {
                                                            setShowPendingModal(false);
                                                            setSelectedBooking(booking);
                                                            setShowRescheduleModal(true);
                                                        }}
                                                        className="relative group w-full"
                                                    >
                                                        <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                        <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                            <Clock className="w-4 h-4 mr-2" />
                                                            Reschedule
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="w-full">
                                                    <button
                                                        onClick={() => {
                                                            setShowPendingModal(false);
                                                            openModal(booking);
                                                        }}
                                                        className="relative group w-full"
                                                    >
                                                        <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                        <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                            <XCircle className="w-4 h-4 mr-2" />
                                                            Cancel
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <div className="max-w-7xl mx-auto">
                {view === 'calendar' ? (
                    <div className="bg-[#4895AA]/10 rounded-xl overflow-hidden">
                        <BookingCalendar 
                            bookings={getFilteredBookings().map(toBaseBooking)}
                            onBookingClick={handleBookingClick}
                        />
                    </div>
                ) : (
                    <div className="bg-[#4895AA]/10 rounded-xl overflow-hidden">
                        {/* Date Navigation */}
                        <div className="p-4 border-b border-[#4895AA]/20 flex items-center justify-between">
                            <button
                                onClick={() => navigateDay('prev')}
                                className="p-2 rounded-lg hover:bg-[#4895AA]/10 transition-colors"
                            >
                                <ArrowLeft className="w-5 h-5 text-white" />
                            </button>
                            <h2 className="text-xl font-semibold text-white">
                                {formatDateHeader(selectedDate)}
                            </h2>
                            <button
                                onClick={() => navigateDay('next')}
                                className="p-2 rounded-lg hover:bg-[#4895AA]/10 transition-colors"
                            >
                                <ArrowRight className="w-5 h-5 text-white" />
                            </button>
                        </div>

                        {/* Bookings for Selected Date */}
                        <div className="divide-y divide-[#4895AA]/20">
                            {getBookingsForDate().length === 0 ? (
                                <div className="p-8 text-center text-gray-400">
                                    No bookings for this date
                                </div>
                            ) : (
                                getBookingsForDate().map(booking => (
                                    <div 
                                        key={booking.id}
                                        className="p-4 hover:bg-[#4895AA]/5 transition-colors duration-150"
                                    >
                                        <div className="flex justify-between items-start">
                                            <div className="space-y-2">
                                                <div className="flex items-center gap-2">
                                                    <h3 className="font-medium text-lg text-white">
                                                        {booking.clientName}
                                                    </h3>
                                                    {getStatusBadge(booking.status)}
                                                </div>
                                                <div className="flex items-center gap-2 text-gray-400">
                                                    <Clock className="w-4 h-4" />
                                                    <span>{formatBookingTime(booking)}</span>
                                                </div>
                                                <div className="flex items-center gap-2 text-gray-400">
                                                    <Calendar className="w-4 h-4" />
                                                    <span>{booking.serviceName}</span>
                                                </div>
                                                <div className="flex items-center gap-2 text-gray-400">
                                                    <Phone className="w-4 h-4" />
                                                    <span>{booking.clientId}</span>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                {booking.status === 'pending' && (
                                                    <div className="w-full">
                                                        <button
                                                            onClick={() => handleConfirmBooking(booking)}
                                                            className="relative group w-full"
                                                        >
                                                            <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                            <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                                <CheckCircle className="w-4 h-4 mr-2" />
                                                                Confirm
                                                            </div>
                                                        </button>
                                                    </div>
                                                )}
                                                {new Date(booking.startTime) > new Date() && (
                                                    <div className="w-full">
                                                        <button
                                                            onClick={() => {
                                                                setSelectedBooking(booking);
                                                                setShowRescheduleModal(true);
                                                            }}
                                                            className="relative group w-full"
                                                        >
                                                            <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                            <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                                <Clock className="w-4 h-4 mr-2" />
                                                                Reschedule
                                                            </div>
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="w-full">
                                                    <button
                                                        onClick={() => openModal(booking)}
                                                        className="relative group w-full"
                                                    >
                                                        <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                        <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                            <XCircle className="w-4 h-4 mr-2" />
                                                            Cancel
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Booking Details Modal */}
            {selectedBooking && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-40" onClick={() => setSelectedBooking(null)}>
                    <div className="bg-[#1E2738] p-6 rounded-xl w-full max-w-md mx-4" onClick={e => e.stopPropagation()}>
                        <div className="space-y-4">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h3 className="text-xl font-semibold text-white">{selectedBooking.clientName}</h3>
                                    <p className="text-gray-300">{selectedBooking.serviceName}</p>
                                </div>
                                {getStatusBadge(selectedBooking.status)}
                            </div>
                            <div className="space-y-2">
                                <div className="flex items-center gap-2 text-gray-300">
                                    <Calendar className="w-4 h-4" />
                                    <span>{formatBookingTime(selectedBooking)}</span>
                                </div>
                                <div className="flex items-center gap-2 text-gray-300">
                                    <Phone className="w-4 h-4" />
                                    <span>{selectedBooking.clientId}</span>
                                </div>
                            </div>
                            {new Date(selectedBooking.startTime) > new Date() && (
                                <>
                                    {selectedBooking.status === 'pending' && (
                                        <div className="flex gap-2 mt-4">
                                            <button
                                                onClick={() => handleConfirmBooking(selectedBooking)}
                                                className="relative group flex-1"
                                            >
                                                <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                    <CheckCircle className="w-4 h-4 mr-2" />
                                                    Confirm
                                                </div>
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setSelectedBooking(null);
                                                    setShowRescheduleModal(true);
                                                }}
                                                className="relative group flex-1"
                                            >
                                                <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                                <div className="relative inline-flex items-center justify-center w-full px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                                    <Clock className="w-4 h-4 mr-2" />
                                                    Reschedule
                                                </div>
                                            </button>
                                        </div>
                                    )}
                                    {selectedBooking.status !== 'pending' && (
                                        <div className="flex gap-2 mt-4">
                                            <button
                                                onClick={() => {
                                                    setSelectedBooking(null);
                                                    setShowRescheduleModal(true);
                                                }}
                                                className="flex-1 bg-[#4895AA] text-white px-4 py-2 rounded-lg hover:bg-[#4895AA]/90"
                                            >
                                                <Clock className="w-4 h-4 mr-2 inline" />
                                                Reschedule
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Cancel Modal - Update z-index to be higher than booking details modal */}
            {showCancelModal && (
                <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
                    <div className="bg-[#1E2738] p-6 rounded-xl w-full max-w-md mx-4">
                        <h2 className="text-xl font-bold text-white mb-4">Cancel Booking</h2>
                        <p className="text-gray-300 mb-4">Please select a reason for cancellation:</p>
                        <form className="space-y-4">
                            {["Unavailable", "Emergency", "Cancel without reason", "Other"].map((reason, index) => (
                                <div key={index} className="flex items-center space-x-3">
                                    <input
                                        type="radio"
                                        id={`reason-${index}`}
                                        name="cancelReason"
                                        value={reason}
                                        checked={selectedReason === reason}
                                        onChange={(e) => {
                                            setSelectedReason(e.target.value);
                                            if (e.target.value !== 'Other') {
                                                setCustomReason('');
                                            }
                                        }}
                                        className="w-4 h-4 text-[#4895AA]"
                                    />
                                    <label htmlFor={`reason-${index}`} className="text-gray-300">{reason}</label>
                                </div>
                            ))}
                            {selectedReason === 'Other' && (
                                <div className="mt-3">
                                    <label htmlFor="customReason" className="block text-sm font-medium text-gray-300 mb-1">
                                        Please specify your reason:
                                    </label>
                                    <input
                                        type="text"
                                        id="customReason"
                                        value={customReason}
                                        onChange={(e) => setCustomReason(e.target.value)}
                                        className="w-full p-2 bg-[#4895AA]/10 border border-[#4895AA]/20 rounded-lg text-white focus:ring-2 focus:ring-[#4895AA] focus:border-[#4895AA]"
                                        placeholder="Type your reason here..."
                                    />
                                </div>
                            )}
                        </form>
                        <div className="mt-6 flex justify-end space-x-3">
                            <button
                                className="relative group"
                                onClick={() => {
                                    setShowCancelModal(false);
                                    setSelectedReason(null);
                                    setCustomReason('');
                                }}
                            >
                                <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                <div className="relative px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                Cancel
                                </div>
                            </button>
                            <button
                                className={`relative group ${!selectedReason || (selectedReason === 'Other' && !customReason)
                                    ? 'opacity-50 cursor-not-allowed'
                                    : ''
                                    }`}
                                disabled={!selectedReason || (selectedReason === 'Other' && !customReason)}
                                onClick={async () => {
                                    if (bookingToCancel && selectedReason) {
                                        const reason = selectedReason === 'Other' ? customReason : selectedReason;
                                        await handleCancelBooking(bookingToCancel, reason || '');
                                    }
                                }}
                            >
                                <div className="absolute inset-0 bg-[#FCBF11] rounded-lg translate-x-1 translate-y-1" />
                                <div className="relative px-4 py-2 bg-[#4895AA] text-white rounded-lg font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                    Confirm Cancellation
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Success Modal */}
            {successModalVisible && (
                <div className="fixed inset-0 bg-black/80 flex justify-center items-center z-50">
                    <div className="bg-[#1E2738] p-6 rounded-xl max-w-sm w-full mx-4">
                        <div className="text-center">
                            <CheckCircle className="w-12 h-12 text-[#4895AA] mx-auto mb-4" />
                            <h2 className="text-xl font-semibold text-white mb-2">
                            Booking Canceled
                        </h2>
                            <p className="text-gray-300">Your booking was successfully canceled.</p>
                        </div>
                    </div>
                </div>
            )}

            {showRescheduleModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Confirm Rescheduling</h2>
                        <p className="mb-4">Are you sure you want to reschedule the booking?</p>

                        {/* Reason for Rescheduling */}
                        <div className="mb-4">
                            <label className="font-medium">Select Reschedule Reason:</label>
                            <div className="mt-2">
                                {/* Radio Buttons for Reschedule Reasons */}
                                {["Sorry for the inconvenience", "Emergency - Need to reschedule", "Other"].map((reason) => (
                                    <label key={reason} className="flex items-center space-x-4">
                                        <input
                                            type="radio"
                                            value={reason}
                                            checked={selectedReasonForReschedule === reason}
                                            onChange={() => {
                                                setSelectedReasonForReschedule(reason);
                                                if (reason !== "Other") setCustomMessage(""); // Clear custom message if not "Other"
                                            }}
                                            className="mr-2"
                                        />
                                        {reason}
                                    </label>
                                ))}
                            </div>

                            {/* Input for Custom Reason if 'Other' is selected */}
                            {selectedReasonForReschedule === "Other" && (
                                <div className="mt-2">
                                    <label className="font-medium">Please specify your reason:</label>
                                    <textarea
                                        value={customMessage}
                                        onChange={(e) => setCustomMessage(e.target.value)}
                                        placeholder="Enter your custom reason"
                                        className={`w-full px-4 py-2 border rounded-lg mt-1 ${!customMessage ? 'border-red-500' : ''}`}
                                    />
                                    {!customMessage && (
                                        <p className="text-red-500 text-sm">This field is required.</p>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Action Buttons */}
                        <div className="flex space-x-4">
                            <button
                                className={`px-4 py-2 rounded-lg text-white bg-green-500 hover:bg-green-600 ${(selectedReasonForReschedule === "Other" && !customMessage) || !selectedReasonForReschedule
                                    ? 'opacity-50 cursor-not-allowed'
                                    : ''
                                    }`}
                                onClick={() => {
                                    if (selectedReasonForReschedule && (selectedReasonForReschedule !== "Other" || customMessage)) {
                                        setShowRescheduleModal(false); // Close modal
                                        navigateToReschedulePage(selectedBooking, selectedReasonForReschedule === "Other" ? customMessage : selectedReasonForReschedule); // Navigate to rescheduling page
                                    }
                                }}
                                disabled={
                                    (selectedReasonForReschedule === "Other" && !customMessage) || !selectedReasonForReschedule
                                }
                            >
                                Yes, Reschedule
                            </button>
                            <button
                                className="px-4 py-2 rounded-lg text-gray-700 bg-gray-200 hover:bg-gray-300"
                                onClick={() => setShowRescheduleModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

