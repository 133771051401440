import React from 'react';

export default function TermsOfService() {
    return (
        <div className="min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
                <p className="text-sm text-gray-500 mb-6">Effective Date: March 30, 2025</p>

                <div className="space-y-8">
                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Introduction</h2>
                        <p className="text-gray-600">
                            These Terms of Service ("Terms") govern your use of Klickie B.V. services ("Klickie," "we," "us," or "our"). By using Klickie via WhatsApp, SMS, or any related service, you agree to these Terms.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Services Provided</h2>
                        <p className="text-gray-600">
                            Klickie allows users to create, manage, and share business profiles ("Klikkies") for scheduling, communication, and customer interactions.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">3. User Responsibilities</h2>
                        <p className="text-gray-600 mb-4">You agree to:</p>
                        <ul className="list-disc pl-6 text-gray-600 space-y-2">
                            <li>Provide accurate information</li>
                            <li>Use Klickie for lawful purposes only</li>
                            <li>Not spam, misuse, or abuse the platform</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Messaging & Communication</h2>
                        <p className="text-gray-600">
                            By submitting your phone number, you consent to receive messages from Klickie via WhatsApp or SMS. You can opt out anytime by replying with "STOP".
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Limitations of Liability</h2>
                        <p className="text-gray-600 mb-4">
                            Klickie is provided "as is" without warranties. We are not liable for:
                        </p>
                        <ul className="list-disc pl-6 text-gray-600 space-y-2">
                            <li>Service interruptions or delays</li>
                            <li>Loss of data due to third-party failures</li>
                            <li>Any damages from using the service</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Termination</h2>
                        <p className="text-gray-600">
                            We reserve the right to terminate accounts violating our Terms.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Changes to These Terms</h2>
                        <p className="text-gray-600">
                            We may update these Terms. Continued use of Klickie means you accept any changes.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">8. Contact Information</h2>
                        <div className="text-gray-600">
                            <p>Klickie B.V.</p>
                            <p>Lange Houtstraat 13, 2511CV 's-Gravenhage, Netherlands</p>
                            <p>KVK: 96140712 | VAT: 867485863</p>
                            <p>Email: <a href="mailto:sidfer@klickie.me" className="text-blue-600 hover:underline">sidfer@klickie.me</a></p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
} 