import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import { countries } from '../utils/countries';
import LoginModal from '../components/LoginModal';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

interface User {
  phoneNumber: string;
  name?: string;
  email?: string;
  isSelected?: boolean;
  isStaff?: boolean;
  address?: string;
  city?: string;
  country?: string;
  language?: string;
  notes?: string;
  isProfessional?: boolean;
}

interface EditModalProps {
  user: User | null;
  onClose: () => void;
  onSave: (user: User) => Promise<void>;
}

const EditModal: React.FC<EditModalProps> = ({ user, onClose, onSave }) => {
  const [editedUser, setEditedUser] = useState<User>(user || {} as User);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  if (!user) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    setError('');
    try {
      await onSave(editedUser);
      onClose();
    } catch (err) {
      setError('Failed to save changes');
      console.error(err);
    }
    setSaving(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-6 border w-[32rem] shadow-lg rounded-xl bg-[#1E2738] border-white/10">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-[#FFD700] text-2xl font-['Klickie_Font',_Arial,_sans-serif]">Edit User Details</h3>
          <button onClick={onClose} className="text-white/60 hover:text-white">
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-white/80">Phone Number</label>
            <input
              type="tel"
              value={editedUser.phoneNumber}
              disabled
              className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-white/80">Name</label>
            <input
              type="text"
              value={editedUser.name || ''}
              onChange={e => setEditedUser({ ...editedUser, name: e.target.value })}
              className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-white/80">Email</label>
            <input
              type="email"
              value={editedUser.email || ''}
              onChange={e => setEditedUser({ ...editedUser, email: e.target.value })}
              className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-white/80">Address</label>
            <input
              type="text"
              value={editedUser.address || ''}
              onChange={e => setEditedUser({ ...editedUser, address: e.target.value })}
              className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-white/80">City</label>
              <input
                type="text"
                value={editedUser.city || ''}
                onChange={e => setEditedUser({ ...editedUser, city: e.target.value })}
                className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white/80">Country</label>
              <input
                type="text"
                value={editedUser.country || ''}
                onChange={e => setEditedUser({ ...editedUser, country: e.target.value })}
                className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-white/80">Language</label>
            <select
              value={editedUser.language || ''}
              onChange={e => setEditedUser({ ...editedUser, language: e.target.value })}
              className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
            >
              <option value="">Select Language</option>
              <option value="en">English</option>
              <option value="nl">Dutch</option>
              <option value="fr">French</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-white/80">Notes</label>
            <textarea
              value={editedUser.notes || ''}
              onChange={e => setEditedUser({ ...editedUser, notes: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
            />
          </div>

          <div className="flex items-center space-x-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={editedUser.isStaff || false}
                onChange={e => setEditedUser({ ...editedUser, isStaff: e.target.checked })}
                className="rounded border-white/10 bg-white/5 text-[#4895AA] focus:ring-[#4895AA]"
              />
              <span className="ml-2 text-sm text-white/80">Staff Member</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={editedUser.isProfessional || false}
                onChange={e => setEditedUser({ ...editedUser, isProfessional: e.target.checked })}
                className="rounded border-white/10 bg-white/5 text-[#4895AA] focus:ring-[#4895AA]"
              />
              <span className="ml-2 text-sm text-white/80">Professional</span>
            </label>
          </div>

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-white/60 bg-white/10 rounded-xl hover:bg-white/20"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={saving}
              className="w-full relative group"
            >
              <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
              <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                {saving ? 'Saving...' : 'Save Changes'}
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const AdminDashboard = () => {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check for existing session on mount
    const sessionToken = localStorage.getItem('sessionToken');
    const userData = localStorage.getItem('userData');
    if (sessionToken && userData) {
      setIsVerified(true);
      setIsStaff(true);
    }
  }, []);

  useEffect(() => {
    if (isVerified && isStaff) {
      fetchUsers();
    }
  }, [isVerified, isStaff]);

  const handleLogin = async (phoneNumber: string) => {
    try {
      // The verification has already been done in LoginModal
      // and session data has been stored
      const sessionToken = localStorage.getItem('sessionToken');
      const userData = localStorage.getItem('userData');
      if (!sessionToken || !userData) {
        setStatus('Login failed. Please try again.');
        setIsVerified(false);
        setIsStaff(false);
        return;
      }

      setIsVerified(true);
      setIsStaff(true);
      await fetchUsers();
    } catch (error: any) {
      console.error('Error in admin login:', error);
      setStatus(error.response?.data?.error || 'Failed to complete login');
      setIsVerified(false);
      setIsStaff(false);
    }
  };

  const fetchUsers = async () => {
    setLoadingUsers(true);
    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        setStatus('Session expired. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
        return;
      }

      const response = await axios.get(`${API_URL}/api/admin/users`, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });
      setUsers(response.data.users.map((user: User) => ({ ...user, isSelected: false })));
    } catch (error: any) {
      if (error.response?.status === 401) {
        setStatus('Session expired. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
      } else {
        setStatus('Error loading users');
      }
      console.error('Error:', error);
    }
    setLoadingUsers(false);
  };

  const handleSaveUser = async (updatedUser: User) => {
    try {
      const sessionToken = localStorage.getItem('sessionToken');
      const userData = localStorage.getItem('userData');

      if (!sessionToken || !userData) {
        setStatus('Session expired. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
        return;
      }

      const adminData = JSON.parse(userData);
      if (!adminData.phoneNumber) {
        setStatus('Invalid admin data. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
        return;
      }

      await axios.put(
        `${API_URL}/api/admin/users/${updatedUser.phoneNumber}`,
        {
          ...updatedUser,
          adminPhone: adminData.phoneNumber
        },
        {
          headers: {
            'Authorization': `Bearer ${sessionToken}`
          }
        }
      );
      await fetchUsers();
      setStatus('User updated successfully');
    } catch (error: any) {
      if (error.response?.status === 401) {
        setStatus('Session expired. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
      } else {
        console.error('Error updating user:', error);
        throw error;
      }
    }
  };

  const handleBroadcast = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log('=== Starting broadcast ===');

      // Get user data and session token
      const userData = localStorage.getItem('userData');
      const sessionToken = localStorage.getItem('sessionToken');
      console.log('Session token:', sessionToken);
      console.log('User data from storage:', userData);

      if (!userData || !sessionToken) {
        setStatus('Session expired. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
        return;
      }

      const selectedUsers = users
        .filter(u => u.isSelected)
        .map(u => u.phoneNumber.startsWith('whatsapp:') ? u.phoneNumber : `whatsapp:${u.phoneNumber}`);

      console.log('Selected users:', selectedUsers);
      console.log('Original users:', users.filter(u => u.isSelected).map(u => u.phoneNumber));

      if (selectedUsers.length === 0) {
        setStatus('Please select at least one user to send the message to.');
        setLoading(false);
        return;
      }

      if (!message.trim()) {
        setStatus('Please enter a message to send.');
        setLoading(false);
        return;
      }

      const user = JSON.parse(userData);
      console.log('Parsed user data:', user);

      if (!user.phoneNumber) {
        setStatus('Invalid user data. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
        setLoading(false);
        return;
      }

      // Format phone numbers with whatsapp: prefix
      const senderPhone = user.phoneNumber.startsWith('whatsapp:') ?
        user.phoneNumber :
        `whatsapp:${user.phoneNumber}`;

      console.log('Sender phone:', senderPhone);
      console.log('Original phone:', user.phoneNumber);

      // Using the exact field names expected by the server
      const payload = {
        message: message.trim(),
        phoneNumber: senderPhone,
        targetUsers: selectedUsers
      };

      // Validate payload
      if (!payload.message) {
        setStatus('Message is required');
        setLoading(false);
        return;
      }
      if (!payload.phoneNumber) {
        setStatus('Sender phone number is required');
        setLoading(false);
        return;
      }
      if (!payload.targetUsers || !Array.isArray(payload.targetUsers) || payload.targetUsers.length === 0) {
        setStatus('Target users are required');
        setLoading(false);
        return;
      }

      console.log('=== BROADCAST PAYLOAD ===');
      console.log('Message:', payload.message);
      console.log('Sender:', payload.phoneNumber);
      console.log('Target Users:', payload.targetUsers);
      console.log('Final payload being sent:', JSON.stringify(payload, null, 2));

      console.log('Making request to:', `${API_URL}/api/admin/broadcast`);
      const response = await axios.post(
        `${API_URL}/api/admin/broadcast`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${sessionToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Server response:', response.data);

      if (response.data.success) {
        setStatus(`Message sent successfully to ${response.data.totalSent} users. ${response.data.totalFailed} failed.`);
        setMessage('');
        setSelectAll(false);
        setUsers(users.map(u => ({ ...u, isSelected: false })));
      } else {
        setStatus(response.data.error || 'Failed to send message');
      }
    } catch (error: any) {
      console.error('Full error object:', error);
      console.error('Error response:', error.response?.data);
      console.error('Error status:', error.response?.status);
      console.error('Error headers:', error.response?.headers);

      if (error.response?.status === 401) {
        setStatus('Session expired. Please login again.');
        setIsVerified(false);
        setIsStaff(false);
      } else {
        setStatus(error.response?.data?.error || 'Error sending message');
      }
    } finally {
      setLoading(false);
      console.log('=== Broadcast attempt complete ===');
    }
  };

  const handleUserSelect = (phoneNumber: string) => {
    setUsers(users.map(user =>
      user.phoneNumber === phoneNumber
        ? { ...user, isSelected: !user.isSelected }
        : user
    ));
    // Update selectAll state
    const updatedUsers = users.map(user =>
      user.phoneNumber === phoneNumber
        ? { ...user, isSelected: !user.isSelected }
        : user
    );
    setSelectAll(updatedUsers.every(user => user.isSelected));
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setUsers(users.map(user => ({ ...user, isSelected: newSelectAll })));
  };

  const filteredUsers = users.filter(user =>
    user.phoneNumber.includes(searchTerm) ||
    user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.country?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isVerified) {
    return (
      <div className="min-h-screen bg-[#1E2738] py-12 px-4">
        <div className="max-w-md mx-auto bg-[#1E2738] rounded-xl border-2 border-white/10 p-8">
          <h1 className="text-[#FFD700] text-4xl text-center font-['Klickie_Font',_Arial,_sans-serif] mb-8">Staff Verification</h1>

          <LoginModal
            onLogin={handleLogin}
            onClose={() => {}}
            isStaffLogin={true}
            klickieUserName={null}
          />

          {status && (
            <div className="mt-4 p-3 bg-red-500/10 border border-red-500 rounded-xl text-red-500 text-sm">
              {status}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1E2738] py-12 px-4">
      <div className="max-w-6xl mx-auto bg-[#1E2738] rounded-xl border-2 border-white/10 p-8">
        <h1 className="text-[#FFD700] text-4xl text-center font-['Klickie_Font',_Arial,_sans-serif] mb-8">Admin Dashboard</h1>

        <div className="mb-8">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-[#FFD700] text-2xl font-['Klickie_Font',_Arial,_sans-serif]">User Management</h2>
            <div className="flex items-center space-x-4">
              <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
              />
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="rounded border-white/10 bg-white/5 text-[#4895AA] focus:ring-[#4895AA]"
                />
                <span className="text-sm text-white/80">Select All</span>
              </label>
            </div>
          </div>

          {loadingUsers ? (
            <div className="text-center py-4 text-white/60">Loading users...</div>
          ) : (
            <div className="border border-white/10 rounded-xl overflow-hidden">
              <table className="min-w-full divide-y divide-white/10">
                <thead className="bg-white/5">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">Select</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">Phone Number</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">Email</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">Location</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">Type</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-white/10">
                  {filteredUsers.map((user) => (
                    <tr key={user.phoneNumber} className="text-white">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={user.isSelected}
                          onChange={() => handleUserSelect(user.phoneNumber)}
                          className="rounded border-white/10 bg-white/5 text-[#4895AA] focus:ring-[#4895AA]"
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.phoneNumber}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.name || '-'}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.email || '-'}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {user.city && user.country ? `${user.city}, ${user.country}` : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex space-x-2">
                          {user.isProfessional && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#4895AA]/20 text-[#4895AA]">
                              Pro
                            </span>
                          )}
                          {user.isStaff && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-[#FCBF11]/20 text-[#FCBF11]">
                              Staff
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() => setEditingUser(user)}
                          className="text-[#4895AA] hover:text-[#4895AA]/80"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Message Form */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-white/80">
              Message to Selected Users ({users.filter(u => u.isSelected).length} selected)
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="mt-1 block w-full rounded-xl border-white/10 bg-white/5 text-white shadow-sm focus:border-[#4895AA] focus:ring-[#4895AA]"
              rows={4}
              placeholder="Enter your message here..."
              required
            />
          </div>

          <button
            onClick={handleBroadcast}
            disabled={loading || users.filter(u => u.isSelected).length === 0}
            className="w-full relative group"
          >
            <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
            <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
              {loading ? 'Sending...' : `Send Message to ${users.filter(u => u.isSelected).length} Users`}
            </div>
          </button>
        </div>

        {status && (
          <div className={`mt-4 p-3 rounded-xl ${
            status.includes('Success') || status.includes('updated')
              ? 'bg-green-500/20 text-green-300'
              : 'bg-red-500/20 text-red-300'
          }`}>
            {status}
          </div>
        )}

        {editingUser && (
          <EditModal
            user={editingUser}
            onClose={() => setEditingUser(null)}
            onSave={handleSaveUser}
          />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;