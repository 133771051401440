import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initFullStory } from './utils/fullstory';

import proc from "process";
window.process = proc;


// Extend the Window interface to include Clarity
declare global {
  interface Window {
    clarity: (...args: any[]) => void;
  }
}

// Function to initialize Microsoft Clarity safely
const initClarity = (projectId: string) => {
  if (!window.clarity) {
    (function (c: any, l: Document, a: string, r: string, i: string, t?: HTMLScriptElement, y?: Node) {
      c[a] =
        c[a] ||
        function (...args: any[]) {
          (c[a].q = c[a].q || []).push(args);
        };
      t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = `https://www.clarity.ms/tag/${i}`;
      y = l.getElementsByTagName(r)[0];
      if (y && y.parentNode) {
        y.parentNode.insertBefore(t, y);
      }
    })(window, document, 'clarity', 'script', projectId);
  }
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Initialize FullStory in production only
if (process.env.NODE_ENV === 'production') {
  try {
    // Get orgId from environment variable
    const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
    if (!orgId) {
      throw new Error('REACT_APP_FULLSTORY_ORG_ID is not defined');
    }
    initFullStory(orgId);
  } catch (error) {
    console.error('Failed to initialize FullStory:', error);
  }
}

// Initialize Clarity in both production and development
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
  try {
    // Use environment variable if available, otherwise use default Clarity ID
    const clarityProjectId = process.env.REACT_APP_CLARITY_PROJECT_ID ?? 'q4pf4iq2f6';
    initClarity(clarityProjectId);
  } catch (error) {
    console.error('Failed to initialize Clarity:', error);
  }
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
