import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import klickieLogo from '../assets/klickie-logo.png';
import TimeSlotSelector from '../components/TimeSlotSelector';
import LoginModal from '../components/LoginModal';
import KlickieModal from '../components/AddToKlickieModal';
import RequestAccessModal from '../components/RequestAccessModal';
import { useLocation } from "react-router-dom";
import { Share2 } from 'lucide-react';
import QRCodeStyling from "qr-code-styling";
import { countries } from '../utils/countries';
import PhoneInput from '../components/PhoneInput';
import SelfBookingModal from '../components/SelfBookingModal';

type Service = {
  service_name: string;
  price: string;
  duration: string;
  address: string;
};

type ProfileData = {
  userId: string;
  name: string;
  services: Service[];
  activity?: string;
  photoUrl?: string;
  availability?: any;
};

type TimeSlot = {
  startTime: string;
  endTime: string;
};

interface NetworkPro {
  userId: string;
  name: string;
  activity?: string;
  photoUrl?: string;
  services?: Service[];
}

const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

// Add helper for service name formatting
const formatServiceName = (name: string) => {
  return name.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .toUpperCase();
};

const Avatar = ({ photoUrl, name, size = 'large' }: { photoUrl?: string, name: string, size?: 'large' | 'small' }) => {
  const sizeClass = size === 'large' ? 'w-32 h-32' : 'w-16 h-16';
  
  // Use media proxy for Twilio URLs
  const imageUrl = photoUrl?.startsWith('https://api.twilio.com') 
    ? `${API_URL}/api/media-proxy?url=${encodeURIComponent(photoUrl)}`
    : photoUrl;
  
  return (
    <div className={`${sizeClass} relative rounded-full overflow-hidden`}>
      <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
      <img
        src={imageUrl || klickieLogo}
        alt={name}
        className="w-full h-full object-cover rounded-full"
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.src = klickieLogo;
        }}
      />
    </div>
  );
};

const getUserData = () => {
  try {
    const data = localStorage.getItem('userData');
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Error parsing userData from localStorage:', error);
    return null;
  }
};

const userData = getUserData() || {};

export default function Preview() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showKlickieModal, setShowKlickieModal] = useState(false);
  const [pendingAction, setPendingAction] = useState<'book' | 'cancel' | 'callback' | 'addToKlickie' | null>(null);
  const [showRequestAccess, setShowRequestAccess] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [network, setNetwork] = useState<any[]>([]);
  const [klickieUserName, setKlickieUserName] = useState<string | null>(null);
  const [showCallbackModal, setShowCallbackModal] = useState(false);
  const [callbackReason, setCallbackReason] = useState("I would like to know more about your services");
  const [customReason, setCustomReason] = useState(""); // New state for custom input
  const [showSelfBookingModal, setShowSelfBookingModal] = useState(false);
  const [bookingCount, setBookingCount] = useState(0);

  // Get share ID from URL if it exists
  const shareId = searchParams.get('s');
  const location = useLocation();
  const { service, rescheduleReason } = location.state || {}; //
  const [isRescheduling, setIsRescheduling] = useState(false); // Flag
  const shareUrl = `https://klickie.me/k/${userId}`;
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const qrCode = new QRCodeStyling({
    width: 90,
    height: 90,
    data: shareUrl,
    dotsOptions: {
      color: "#60A5FA",
      type: "rounded",
    },
    cornersSquareOptions: {
      color: "#60A5FA",
      type: "extra-rounded",
    },
    cornersDotOptions: {
      color: "white",
    },
    backgroundOptions: {
      color: "#1e2738",
    },
  });

  // Add a ref to access the TimeSlotSelector
  const timeSelectorRef = useRef<any>(null);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("?")) {
      console.log("Question mark (?) is present in the URL");
      localStorage.removeItem("userId")
    } else {
      console.log("No question mark (?) found in the URL");
    }
    // Only append QR code once on the first render
    if (qrCodeRef.current && !qrCodeRef.current.hasChildNodes()) {
      qrCode.append(qrCodeRef.current);
      // Track QR Code Displayed in Clarity

      const parts = window.location.href.split("/k/");  // Split the URL at "/k/"
      const phoneNumber = parts[1];  // Extract the phone number (the part after "/k/")

      if (window.clarity) {
        window.clarity("event", "qr_code_displayed", {
          userId: localStorage.getItem("userId") || phoneNumber,  // Use userId from localStorage or fallback to phoneNumber
          action: "QR Code displayed for sharing",  // Describe the action that occurred
        });
      }
    }
  }, [qrCode]); // Empty dependency array to run only once

  React.useEffect(() => {
    if (service) {
      if (rescheduleReason) {
        setIsRescheduling(true);
        const loggedInUserId = localStorage.getItem("userId");
        console.log("Before state update:", isLoggedIn, isConnected);
        if (loggedInUserId && (!isLoggedIn || !isConnected)) {
          console.log("Setting logged-in state...");
          setIsLoggedIn(true);
          setIsConnected(true);
        } else if (!loggedInUserId && (isLoggedIn || isConnected)) {
          setIsLoggedIn(false);
          setIsConnected(false);
        }
      }
    }
  }, [service, rescheduleReason]);

  React.useEffect(() => {
    if (service && isLoggedIn && isConnected) {
      console.log("Calling handleServiceClick after state update...");
      handleServiceClick(service?.serviceDetails);
    }
  }, [isLoggedIn, isConnected, service]); // Now it runs only after state updates

  // Handle QR code click for sharing
  const handleShareClick = () => {
    try {
      if (navigator.share) {
        navigator
          .share({
            title: `Check out ${data?.name}'s profile on Klickie!`,
            url: shareUrl,
          })
          .catch(console.error);
      } else {
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`
        );
      }
      // Track Klickie share click in Clarity
      if (window.clarity) {
        window.clarity("event", "klickie_shared", {
          userId: localStorage.getItem("userId"),
          action: "QR Code shared",
          shareUrl: shareUrl,
        });
      }
    } catch (error: any) {
      console.error("Error sharing Klickie:", error);
      // Track share failure in Clarity
      if (window.clarity) {
        window.clarity("event", "klickie_share_failed", {
          userId: localStorage.getItem("userId"),
          errorMessage: error.message || "Unknown error",
        });
      }
      alert("Failed to share Klickie. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      
       // Only clear tempCustomerData if coming from Network page
       if (document.referrer.includes('/network/')) {
        localStorage.removeItem('tempCustomerData');
      }

      try {
        const response = await axios.get(`${API_URL}/api/profile/${userId}`);
        console.log('=== PROFILE RESPONSE ===');
        console.log('Full response:', response);
        console.log('Profile data:', response.data);
        console.log('Photo URL:', response.data.photoUrl);
        
        // Ensure we have all required fields from the new API structure
        if (!response.data.name) {
          throw new Error('Invalid profile data: missing name');
        }
        
        setKlickieUserName(response.data.name);
        setData(response.data);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError(err instanceof Error ? err.message : 'Failed to load profile');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    const fetchBookingCount = async () => {
      if (!userId) return;

      try {
        const response = await axios.get(`${API_URL}/api/pro-bookings-count/${userId}`);
        setBookingCount(response.data.total_count || 0);
      } catch (error) {
        console.error('Error fetching booking count:', error);
        // Keep default 0 if there's an error
      }
    };

    fetchBookingCount();
  }, [userId]);

  const handleLogin = async (phoneNumber: string) => {
    try {
      const currentUserId = localStorage.getItem('userId');
      // Ensure phone number has + prefix to match URL format
      const normalizedPhone = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;

      console.log('Phone Number Debug:', {
        rawPhoneNumber: phoneNumber,
        normalizedPhone,
        userId,
        currentUserId,
        isOwnKlickie: normalizedPhone === userId
      });

      if (!currentUserId) {
        setError('User not found.');
        return;
      }

      // If it's your own Klickie, set logged in state immediately
      if (normalizedPhone === userId) {
        console.log('Setting logged in state for own Klickie');
        setIsLoggedIn(true);
        setIsConnected(true);
        // Handle any pending actions immediately
        if (pendingAction === 'book' && selectedService) {
          setShowTimeSelector(true);
        } else if (pendingAction === 'addToKlickie') {
          // No need to show Klickie modal for own profile
          setPendingAction(null);
        }
        return;
      }

      // After successful login, check if there was a pending action
      if (pendingAction === 'addToKlickie') {
        try {
          const networkResponse = await axios.get(`${API_URL}/api/network/${currentUserId}`);
          const isAlreadyConnected = networkResponse.data.some((pro: any) => pro.userId === userId);
          const url = window.location.href;
          if (url.includes("?")) {
            console.log("Question mark (?) is present in the URL");
            const cleanUrl = window.location.href.split("?")[0]; // Get the part before `?`
            // Update the browser URL without reloading
            window.history.replaceState(null, "", cleanUrl);
            if (!localStorage.getItem('userId')) {
              localStorage.setItem('userId', currentUserId);
              console.log("User ID set in localStorage:", currentUserId);
            } else {
              console.log("User ID already exists in localStorage:", localStorage.getItem('userId'));
            }
            console.log("Updated URL:", cleanUrl);
          } else {
            console.log("No question mark (?) found in the URL");
          }
          if (!isAlreadyConnected) {
            setShowKlickieModal(true)
          }
          setNetwork(networkResponse.data);
          setIsConnected(isAlreadyConnected);
        } catch (error) {
          console.error('Error checking network status:', error);
          setError('Failed to verify connection status.');
        }
      } else if (pendingAction === 'book' && selectedService) {
        setShowTimeSelector(true);
      } else if (pendingAction === 'cancel') {
        handleCancelBooking();
      } else if (pendingAction === 'callback') {
        handleCallbackRequest();
      }
      setPendingAction(null);
    } catch (error) {
      console.error('Error after login:', error);
      setError('Failed to complete action');
    }
  };

  const handleConfirm = async () => {
    if (!isLoggedIn) {
      setError('You must be logged in to connect.');
      return;
    }

    if (isConnected) {
      setError('You are already connected.');
      return;
    }

    // setIsLoading(true);
    setError(null);

    try {
      const currentUserId = localStorage.getItem('userId');
      if (!currentUserId) {
        setError('User not found.');
        return;
      }

      await axios.post(`${API_URL}/api/network`, {
        userId: currentUserId,
        connectWithId: userId,
      });

      alert('Connection request sent successfully!');
      setIsConnected(true);
      // onClose();
    } catch (error) {
      console.error('Error connecting:', error);
      setError('Failed to connect. Please try again.');
    } finally {
      // setIsLoading(false);
    }
  };

  const handleRequestConnection = async (phoneNumber: string) => {
    try {
      const clientId = localStorage.getItem('userId');
      if (!clientId) {
        console.error("Client ID not found in localStorage");
        setError("User authentication error");
        return;
      }

      console.log("ClientId:", clientId);
      console.log("ProId:", userId);

      // 🔹 Check if already connected BEFORE making the API request
      const networkResponse = await axios.get(`${API_URL}/api/network/${clientId}`);
      const isAlreadyConnected = networkResponse.data.some((pro: any) => pro.userId === userId);

      if (isAlreadyConnected) {
        setNetwork(networkResponse.data);
        setIsConnected(true);
        alert(`You're already connected!`);
        return;
      }

      // 🔹 Send connection request
      await axios.post(`${API_URL}/api/create-connection`, {
        clientId,
        proId: userId
      });

      // 🔹 Fetch updated network
      const updatedNetworkResponse = await axios.get(`${API_URL}/api/network/${clientId}`);
      setNetwork(updatedNetworkResponse.data);
      setIsConnected(true);
      
      // Show success message
      // alert(`Connection build successfully! ✅`);

    } catch (error: any) {
      console.error('Error building connection:', error.response?.data || error.message);
      setError('Failed to build connection. Please try again later.');
    }
  };

  const checkUserAndNetwork = async () => {
    const currentUserId = localStorage.getItem('userId');
    if (!currentUserId) {
      return { isLoggedIn: false, isConnected: false };
    }

    try {
      // Check if it's the user's own Klickie
      if (currentUserId === userId) {
        console.log('Own Klickie detected - setting logged in and connected states');
        setIsLoggedIn(true);
        setIsConnected(true);
        return { isLoggedIn: true, isConnected: true };
      }

      // Check if user exists and is in network
      const networkResponse = await axios.get<NetworkPro[]>(`${API_URL}/api/network/${currentUserId}`);
      const isConnected = networkResponse.data.some((pro: NetworkPro) => pro.userId === userId);
      setIsLoggedIn(true);
      setIsConnected(isConnected);
      
      return {
        isLoggedIn: true,
        isConnected
      };
    } catch (error) {
      console.error('Error checking user status:', error);
      return { isLoggedIn: false, isConnected: false };
    }
  };

  useEffect(() => {
    if (userId) {
      const currentUserId = localStorage.getItem('userId');
      // Immediately set states if it's own Klickie
      if (currentUserId === userId) {
        setIsLoggedIn(true);
        setIsConnected(true);
      }
      checkUserAndNetwork();
    }
  }, [userId]);

  const handleServiceClick = async (service: Service) => {
    const loggedInUserId = localStorage.getItem("userId");
    if (loggedInUserId) {
      setIsLoggedIn(true);
    }

    // Check if user is booking their own service
    if ((loggedInUserId === userId) && !rescheduleReason) {
      setSelectedService(service);
      setShowSelfBookingModal(true); // Show SelfBookingModal instead of LoginModal
      return;
    }

    console.log("isLoggedinnnn", isLoggedIn)
    console.log("isConnectedddd", isConnected)
    if (!loggedInUserId || !isConnected) {
      return; // Prevents calling handleServiceClick when disabled
    }
    if (!userId) {
      console.log('No userId found, showing error');
      setError('Invalid booking parameters');
      return;
    }

    try {
      // Check user status
      console.log('2. Checking user and network status...');
      const status = await checkUserAndNetwork();
      console.log('3. Network status result:', status);
      
      if (!rescheduleReason) {
      if (!status.isLoggedIn) {
        console.log('4a. User not logged in, showing login modal');
        setSelectedService(service);
        setPendingAction('book');
        setShowLoginModal(true);
        return;
      }

      if (!status.isConnected) {
        console.log('4b. User not connected, showing request access modal');
        setShowRequestAccess(true);
        return;
        }
      }

      // Show time slot selector for connected users
      console.log('4c. User logged in and connected, showing time selector');
      setSelectedService(service);
      setShowTimeSelector(true);
      console.log('5. States updated:', { 
        selectedService: service,
        showTimeSelector: true
      });
    } catch (error) {
      console.error('Error in booking flow:', error);
      setError('Failed to process booking');
    }
  };

  const handleSelfBookingConfirm = (clientData: any) => {
    setShowSelfBookingModal(false);
    // Show time slot selector with the selected service
    setShowTimeSelector(true);
  };

  const proceedWithBooking = async (slot: TimeSlot) => {
    if (!userId || !selectedService) {
      setError("Invalid booking parameters");
      return;
    }
    try {
      // Get logged-in user info
      const loggedInUserId = localStorage.getItem("userId");
      
      // Fetch user profile data
      const profileResponse = await axios.get(`${API_URL}/api/profile/${userId}`);
      const profileData = profileResponse.data;
      // Find selected service in user's services
      const service = profileData.services.find((s: any) => s.service_name === selectedService.service_name);
      // If it's a self-booking (pro booking their own service), force isConsent to false
      const isConsent = loggedInUserId === userId ? false : service?.rule === "Consent";

      // Get logged-in user info
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");

      // **Track Booking Attempt in Clarity**
      if (window.clarity) {
        window.clarity("event", "booking_attempt", {
          userId,
          serviceId: selectedService.service_name,
          clientId: loggedInUserId,
          isConsent,
        });
      }
      const tempCustomerData = JSON.parse(localStorage.getItem('tempCustomerData') || '{}');
      // Proceed with booking request
      const startTime = new Date(slot.startTime);
      // Check if the conversion was successful
      if (isNaN(startTime.getTime())) {
        console.error("Invalid startTime:", slot.startTime);
        throw new Error("Invalid startTime format");
      }
      // Convert duration to a number
      const durationInMinutes = Number(selectedService.duration);
      // Ensure the duration is a valid number
      if (isNaN(durationInMinutes)) {
        throw new Error("Invalid duration format");
      }
      const endTime = new Date(startTime.getTime() + durationInMinutes * 60000); // Adding duration in milliseconds
      // Proceed with booking request
      const response = await axios.post(`${API_URL}/api/book`, {
        userId,
        serviceId: selectedService.service_name,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(), // Format to ISO string if needed
        clientId: tempCustomerData?.userId || loggedInUserId,
        clientName: tempCustomerData?.userData?.name || userData?.name || "Customer",
        isConsent,
      });
      
      if (response.data.success) {
        const formattedDate = new Date(slot.startTime).toUTCString().split(" ").slice(0, 4).join(" ");
        const formattedTime = `${new Date(slot.startTime).getUTCHours() % 12 || 12}:${new Date(slot.startTime).getUTCMinutes().toString().padStart(2, "0")} ${(new Date(slot.startTime).getUTCHours() >= 12 ? "PM" : "AM")}`;
        // **Track Successful Booking in Clarity**
        if (window.clarity) {
          window.clarity("event", "booking_success", {
            userId,
            serviceId: selectedService.service_name,
            clientId: loggedInUserId,
            bookingDate: formattedDate,
            bookingTime: formattedTime,
          });
        }
        // Show success alert
        alert(`Booking confirmed! ✅\n\nService: ${selectedService.service_name}\n${userId === loggedInUserId ? `Booked by: ${tempCustomerData?.userData?.name}` : `With: ${profileData?.name}`}\nDate: ${formattedDate}\nTime: ${formattedTime}\n\nYou'll be redirected to your bookings.`);

        // Reset booking loading state in TimeSlotSelector if it exists
        if (timeSelectorRef.current && timeSelectorRef.current.resetBookingState) {
          timeSelectorRef.current.resetBookingState();
        }

        // Close time selector
        setShowTimeSelector(false);
        // Redirect to bookings overview
        setTimeout(() => {
          const tempCustomerData = JSON.parse(localStorage.getItem('tempCustomerData') || 'null'); // Safe fallback
          const redirectUrl = tempCustomerData && tempCustomerData.userId
            ? `/pro-booking-history/${userId}`
            : response.data?.details?.redirectUrl || "/default-redirect-page"; // Add a default URL if none is provided
          window.location.href = redirectUrl;
          localStorage.removeItem('tempCustomerData')
        }, 1000);
      } else {
        throw new Error(response.data.error || "Failed to confirm booking");
      }
    } catch (error: any) {
      console.error("Error confirming booking:", error);
      const errorMessage = error.response?.data?.error || error.message || "Failed to confirm booking";
      
      // Reset booking loading state in TimeSlotSelector
      if (timeSelectorRef.current && timeSelectorRef.current.resetBookingState) {
        timeSelectorRef.current.resetBookingState();
      }
      
      // **Track Failed Booking in Clarity**
      if (window.clarity) {
        const loggedInUserId = localStorage.getItem("userId");
        window.clarity("event", "booking_failed", {
          userId,
          serviceId: selectedService.service_name,
          clientId: loggedInUserId,
          errorMessage,
        });
      }
      alert(`Booking failed: ${errorMessage}`);
    }
  };

  const handleReschedulingTimeSlotConfirm = async (slot: TimeSlot) => {
    if (!userId || !selectedService) {
      setError('Invalid booking parameters');
      return;
    }

    console.log("selectedService", selectedService)
    console.log("service", service)

    try {
      // Get the stored user ID from localStorage
      const loggedInUserId = localStorage.getItem('userId');
      console.log(loggedInUserId, "loggedInUserId")
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      console.log(userData, "userData")
      console.log(rescheduleReason, "rescheduleReason")

      // Track Reschedule Attempt in Clarity
      if (window.clarity) {
        window.clarity("event", "reschedule_attempt", {
          userId,
          serviceId: selectedService?.service_name,
          rescheduleReason,
          clientId: loggedInUserId,
        });
      }

      // Proceed with rescheduling logic
      try {
        setError(null);
        // Prepare data for the reschedule request
        const rescheduleData = {
          rescheduleRequestedBy: loggedInUserId,
          reason: rescheduleReason || 'No reason provided',
          requestedByType: service?.requested_by_reschedule,
          rescheduleDate: new Date(slot.startTime).toISOString(), // Use ISO string
          rescheduleStartTime: new Date(slot.startTime).toISOString(), // Use ISO string
          rescheduleEndTime: new Date(slot.endTime).toISOString(), // Use ISO string
        };
        // Send the reschedule request
        const response = await axios.put(`${API_URL}/api/reschedule-request/${service?.bookingId}`, rescheduleData);

        if (response.data && response.data.success) {
          // Track Successful Reschedule in Clarity
          if (window.clarity) {
            window.clarity("event", "reschedule_success", {
              userId,
              serviceId: selectedService?.service_name,
              newStartTime: new Date(slot.startTime).toISOString(),
              clientId: loggedInUserId,
            });
          }

          const new_time_UTC_String = new Date(slot.startTime).toUTCString().split(" ").slice(0, 4).join(" ");
          const UTC_Hour = new Date(slot.startTime).getUTCHours() % 12 || 12;
          const UTC_Minutes = new Date(slot.startTime).getUTCMinutes().toString().padStart(2, "0");
          const UTC_HOURS_1 = new Date(slot.startTime).getUTCHours() >= 12 ? "PM" : "AM";
          alert(`Rescheduling confirmed! ✅\n\nService: ${selectedService.service_name}\nWith: ${data?.name}\nNew Time: ${new_time_UTC_String} at ${(UTC_Hour)}:${UTC_Minutes} ${(UTC_HOURS_1)} UTC\n\nYou'll be redirected to your bookings.`);

          setShowTimeSelector(false);
          setTimeout(() => {
            window.location.href = response.data.details.redirectUrl;
          }, 1000);
        } else {
          throw new Error(response.data.error || 'Failed to confirm rescheduling');
        }
      } catch (error: any) {
        console.error('Error confirming booking:', error);

        // Track Reschedule Failure in Clarity
        if (window.clarity) {
          window.clarity("event", "reschedule_failed", {
            userId,
            serviceId: selectedService?.service_name,
            errorMessage: error.response?.data?.error || error.message || 'Failed to confirm booking',
            clientId: loggedInUserId,
          });
        }

        const errorMessage = error.response?.data?.error || error.message || 'Failed to confirm booking';
        alert(`Booking failed: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error confirming booking:', error);

      // Track Reschedule Failure in Clarity (fallback)
      if (window.clarity) {
        window.clarity("event", "reschedule_failed", {
          userId,
          serviceId: selectedService?.service_name,
          errorMessage: 'Failed to fetch user data',
        });
      }

      setError('Failed to confirm booking');
    }
  };

  const handleTimeSlotConfirm = async (slot: TimeSlot) => {
    if (!userId || !selectedService) {
      setError('Invalid booking parameters');
      return;
    }

    try {
      // Get the stored user ID from localStorage
      const loggedInUserId = localStorage.getItem('userId');
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');

      if (isRescheduling) {
        await handleReschedulingTimeSlotConfirm(slot); // Call rescheduling function
      } else {
        await proceedWithBooking(slot); // Call normal booking function
      }
    } catch (error) {
      console.error('Error handling time slot confirmation:', error);
      setError('Failed to confirm time slot.');
    }
  };

  const handleCancelBooking = async () => {
    try {
      const status = await checkUserAndNetwork();

      // Track the cancellation attempt
      if (window.clarity) {
        window.clarity("event", "cancel_booking_attempt", {
          userId: localStorage.getItem("userId"),
          status: "User attempted cancellation",
        });
      }
      
      if (!status.isLoggedIn) {
        setPendingAction("cancel");
        setShowLoginModal(true);
        return;
      }

      if (!status.isConnected) {
        alert("You need to be connected with this pro first");
        setShowRequestAccess(true);
        return;
      }

      // Get current user ID and ensure it has a "+" prefix
      const currentUserId = localStorage.getItem("userId")!;
      const normalizedUserId = currentUserId.startsWith("+") ? currentUserId : `+${currentUserId}`;

      // Show confirmation message
      alert(`You'll be redirected to your cancellations page where you can manage all your bookings.`);

      // Track the successful cancellation redirection
      if (window.clarity) {
        window.clarity("event", "cancel_booking_success", {
          userId: normalizedUserId,
          status: "Redirection to cancellations page",
        });
      }
      
      // Redirect to customer's cancellations overview with normalized ID
      window.location.href = `/cancellations/${normalizedUserId}`;
    } catch (error: any) {
      console.error("Error handling cancellation:", error);
      setError("Failed to process cancellation");

      // Track cancellation failure in Clarity
      if (window.clarity) {
        window.clarity("event", "cancel_booking_failed", {
          userId: localStorage.getItem("userId"),
          errorMessage: error.message || "Unknown error",
        });
      }
    }
  };

  const handleCallbackRequest = async () => {
    try {
      const status = await checkUserAndNetwork();
      
      if (!status.isLoggedIn) {
        // Show login modal for non-logged in users
        setPendingAction('callback');
        setShowLoginModal(true);
        return;
      }

      if (!status.isConnected) {
        alert('You need to be connected with this pro first');
        setShowRequestAccess(true);
        return;
      }

      setShowCallbackModal(true);
    } catch (error) {
      console.error('Error requesting callback:', error);
      setError('Failed to request callback');
    }
  };

  const handleSubmitCallback = async () => {
    try {
      const currentUserId = localStorage.getItem('userId')!;

      const profileResponse = await axios.get(`https://api.klickie.me/api/profile/${currentUserId}`);
      const profileData = profileResponse.data;

      const finalReason = callbackReason === "Custom" ? customReason : callbackReason;
      const response = await axios.post(`${API_URL}/api/request-callback`, {
        proId: userId,
        proName: data?.name,
        clientId: currentUserId,
        clientName: profileData.name || 'Customer',
        reasone: finalReason,
      });

      if (response.data.success) {
        alert(`Callback request sent! ✅\n\n${data?.name} will contact you soon.\n\nYou'll be redirected to your callback requests.`);
        
        const normalizedUserId = currentUserId.startsWith('+') ? currentUserId : `+${currentUserId}`;
        // window.location.href = `/callbacks/${normalizedUserId}`;
        navigate(`/callbacks/${normalizedUserId}`, { state: { callbackRequest: 'client' } });
      }
      console.log("Callback Request Data:", {
        proId: userId,
        proName: data?.name,
        clientId: currentUserId,
        clientName: data?.name || "Customer",
        reasone: finalReason,
      });
    } catch (error) {
      console.error('Error submitting callback request:', error);
      setError('Failed to request callback');
    } finally {
      setShowCallbackModal(false);
    }
  };

  const handleRequestAccess = async (phoneNumber: string) => {
    try {
      // If we get here, user doesn't exist, send connection request
      await axios.post(`${API_URL}/api/request-connection`, {
        proId: userId,
        customerPhone: phoneNumber
      });
      
      // Show success message
      alert(`Request sent! ✅\n\n${data?.name} will review your request and share their Klickie with you.\n\nYou'll receive a WhatsApp message when they accept.`);
      
      setShowRequestAccess(false);
    } catch (error) {
      console.error('Error requesting access:', error);
      setError('Failed to request access');
    }
  };

  const handleBooking = async () => {
    try {
      const status = await checkUserAndNetwork();
      
      if (!status.isLoggedIn) {
        setPendingAction('book');
        setShowLoginModal(true);
        return;
      }

      if (!status.isConnected) {
        alert('You need to be connected with this pro first');
        setShowRequestAccess(true);
        return;
      }

      // Get current user ID and normalize it
      const currentUserId = localStorage.getItem('userId')!;
      const normalizedUserId = currentUserId.startsWith('+') ? currentUserId : `+${currentUserId}`;

      // Redirect to bookings page with normalized ID
      window.location.href = `/bookings/${normalizedUserId}`;
    } catch (error) {
      console.error('Error handling booking:', error);
      setError('Failed to process booking');
    }
  };

  // Handle user login completion
  useEffect(() => {
    if (isLoggedIn && pendingAction === "addToKlickie") {
      setPendingAction(null); // Reset pending action
      setShowKlickieModal(true);
    }
  }, [isLoggedIn, pendingAction]);

  const handleAddToKlickieClick = () => {
    const currentUserId = localStorage.getItem('userId');
    console.log('handleAddToKlickieClick - currentUserId:', currentUserId, 'userId:', userId);
    
    // Check if it's your own Klickie
    if (currentUserId === userId) {
      console.log('Setting logged in and connected state for own Klickie');
      setIsLoggedIn(true);
      setIsConnected(true);
      return;
    }

    if (!isLoggedIn) {
      // Show login modal first and store intent
      setPendingAction("addToKlickie");
      setShowLoginModal(true);
    } else {
      setShowKlickieModal(true); // Open Klickie modal immediately after login
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup function - runs when component unmounts
      localStorage.removeItem('tempCustomerData');
    };
  }, []);

  if (loading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  if (error) return <div className="flex justify-center items-center min-h-screen text-red-500">{error}</div>;
  if (!data || !userId) return <div className="flex justify-center items-center min-h-screen">No profile found</div>;

  return (
    <>
      <div className="min-h-screen bg-[#1E2738] flex items-center justify-center p-4">
        {/* ✅ QR Code Positioned at the Top-Right and Only show QR Code if showTimeSelector is false */}
        {!showTimeSelector && (
          <div className="absolute top-4 right-4 cursor-pointer" onClick={handleShareClick} style={{ zIndex: 9 }}>
            <div className="bg-[#1E293B] rounded-full p-0 flex items-center justify-center shadow-lg" style={{ width: "90px", height: "90px" }}>
              <div ref={qrCodeRef}></div>
            </div>
            <p className="text-gray-400 text-xs text-center">
              Click to share
            </p>
          </div>
        )}
        <div className="w-full max-w-md bg-[#1E2738] rounded-3xl p-8 relative">

          {/* Profile Section */}
          <div className="text-center mb-8">
            <div className="flex justify-center mb-6">
              <Avatar 
                photoUrl={data.photoUrl}
                name={data.name}
                size="large"
              />
            </div>
            <h1 className="text-[#FFD700] text-3xl font-bold mb-2">{data.name}</h1>
            <div className="text-gray-400 flex items-center justify-center space-x-2">
              <span>{data.activity || 'Service Provider'}</span>
              <span>•</span>
              <span>{bookingCount} bookings</span>
            </div>
          </div>

          {/* Services */}
          <div className="space-y-4">
            {/* Add to my klickie btn */}
              <button
              onClick={handleAddToKlickieClick}
              disabled={isConnected || (localStorage.getItem('userId') === userId)}
              className={`w-full relative group ${isConnected || (localStorage.getItem('userId') === userId) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                <div className="flex items-center justify-between">
                  <div className="bg-white/10 p-1 rounded-lg">
                    <Share2 className="w-6 h-6" />
                  </div>
                  <div className="flex-grow text-center">
                    <span>GET STARTED</span>
                  </div>
                  </div>
                </div>
              </button>

            {/* Request Callback Button */}
            <button
              onClick={handleCallbackRequest}
              disabled={!isLoggedIn || !isConnected || (userId === localStorage.getItem('userId'))}
              className={`w-full relative group ${
                !isConnected || (userId === localStorage.getItem('userId')) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
              <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                <div className="flex items-center justify-center">
                  <span>REQUEST CALLBACK</span>
                </div>
              </div>
            </button>

            {data.services.map((service, index) => (
            <button
                key={index}
                onClick={() => {
                  handleServiceClick(service);
                }}
                disabled={!isLoggedIn && !isConnected}
                className={`w-full relative group ${!isConnected ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
              <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                    <div className="flex justify-between items-center w-full">
                      <div className="flex-1 text-center">
                        <span className="block">
                          {service.service_name.toUpperCase()}
                        </span>
                        {service.address && (
                          <span className="line-clamp-2 block text-sm mt-1">
                            ({service.address})
                          </span>
                        )}
                      </div>
                      <div className="flex-shrink-0 ml-4">
                        <span>€{service.price}</span>
                      </div>
                    </div>
              </div>
            </button>
            ))}
          </div>
        </div>
      </div>

      {showCallbackModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Make Callback Request</h2>
            <p className="text-gray-700 mb-4">What is the reason for your callback request?</p>

            {/* Radio Button Options */}
            <div className="space-y-3">
              {[
                "I would like to know more about your services",
                "Custom"
              ].map((option) => (
                <label key={option} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="callbackReason"
                    value={option}
                    checked={callbackReason === option}
                    onChange={(e) => setCallbackReason(e.target.value)}
                  />
                  <span>{option}</span>
                </label>
              ))}

              {callbackReason === "Custom" && (
                <div className="mt-4">
                  <label className="block text-gray-700 mb-2">Please specify your reason:</label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="Type your reason here..."
                    value={customReason}
                    onChange={(e) => setCustomReason(e.target.value)}
                  />
                </div>
              )}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-6 space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"
                onClick={() => setShowCallbackModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-lg"
                onClick={handleSubmitCallback}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      
      {showLoginModal && (
        <LoginModal
          onLogin={handleLogin}
          onClose={() => setShowLoginModal(false)}
          klickieUserName={klickieUserName} // Pass username to LoginModal
        />
      )}

      {showTimeSelector && selectedService && (
        <TimeSlotSelector
          ref={timeSelectorRef} // Add ref to access methods
          userId={userId}
          serviceName={selectedService.service_name}
          duration={selectedService.duration}
          onSelect={handleTimeSlotConfirm}
          onCancel={() => {
            setShowTimeSelector(false);
            setSelectedService(null);
          }}
        />
      )}

      {React.Children.toArray([
        showRequestAccess && (
          <RequestAccessModal
            onSubmit={handleRequestAccess}
            onClose={() => setShowRequestAccess(false)}
            onExistingCustomer={() => {
              setShowLoginModal(true);
              setShowRequestAccess(false);
            }}
          />
        ),
        showKlickieModal
        && ( // Render KlickieModal when showKlickieModal is true
          <KlickieModal
            onClose={() => setShowKlickieModal(false)} // Add a handler to close the modal
            onSubmit={(phoneNumber) => {
              // Handle the submission logic here (e.g., request connection)
              handleRequestConnection(phoneNumber);
              setShowKlickieModal(false); // Close the modal after submission
            }}
          />
        )
      ])}

      {showSelfBookingModal && selectedService && (
        <SelfBookingModal
          onClose={() => setShowSelfBookingModal(false)}
          onConfirm={handleSelfBookingConfirm}
          proName={data?.name || ''}
          userId={userId}
        />
      )}
    </>
  );
} 