import React, { JSX, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    ExternalLink, 
    CalendarCheck, 
    BookMarked, 
    Clock, 
    Settings, 
    Users, 
    Image, 
    CloudOff, 
    UserCog, 
    SettingsIcon, 
    PhoneCall, 
    BellRing, 
    ChevronDown, 
    ChevronUp,
    Calendar,
    MessageSquare,
    X,
    Bot,
    Sparkles,
    ArrowLeft
} from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';
import axios from 'axios';

interface ActionButton {
    icon: React.ReactNode;
    title: string;
    description: string;
    action: () => void;
    isProFeature?: boolean;
}

interface ProfileData {
    name: string;
    photoUrl?: string;
    activity?: string;
    services?: any[]; // Add services to track if user has services
}

const API_URL = process.env.REACT_APP_API_URL || "https://api.klickie.me";

export default function WhatNowPro(): JSX.Element {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState<ProfileData | null>(null);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [hoveredButton, setHoveredButton] = useState<number | null>(null);
    const [chatAssistantOpen, setChatAssistantOpen] = useState(false);
    const chatRef = useRef<HTMLDivElement>(null);

    const getPhoneNumberFromUrl = (url: string): string | null => {
        const match = url.match(/\/what-now\/(\+\d+)/) || url.match(/\/what-now-customer\/(\d+)/);
        return match ? match[1] : null;
    };
    
    useEffect(() => {
        const requestKlickieUrl = (): string | null => {
            const currentUrl = window.location.href;
            console.log(currentUrl)
            return getPhoneNumberFromUrl(currentUrl);
        };
        const phoneNumber = requestKlickieUrl(); // Now calling it
        console.log(phoneNumber, "phoneNumber")
        localStorage.setItem('userId', phoneNumber ? phoneNumber : "unknown");

        if (window.clarity) {
            window.clarity("event", "landing_page_visit", {
                userId: userId || "unknown",
                page: "WhatNow",
            });
        }

        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/profile/${userId}`);
                setProfileData(response.data);
                if (response.data.type === 'PRO' && userId) {
                    localStorage.setItem('userId', userId);
                }
            } catch (error: any) {
                console.error('Error fetching profile:', error);
                if (window.clarity) {
                    window.clarity("event", "profile_fetch_error", {
                        userId: userId || "unknown",
                        errorMessage: error.message,
                    });
                }
            }
        };

        if (userId) {
            fetchProfile();
        }
    }, [userId]);

    const handleCalendarSync = () => {
        const width = 500;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        window.open(
            `/calendar-sync/${userId}`,
            'Calendar Sync',
            `width=${width},height=${height},left=${left},top=${top}`
        );
    };

    const toggleSettings = () => {
        setSettingsOpen(!settingsOpen);
    };

    // Check if user has services
    const hasServices = (): boolean => {
        return !!(profileData?.services && profileData.services.length > 0);
    };

    // Create primary actions array based on user's services
    const getPrimaryActions = (): ActionButton[] => {
        const actions: ActionButton[] = [
            {
                icon: <ExternalLink className="w-6 h-6" />,
                title: "My Booking Page",
                description: "Share your booking page with clients to let them book your services",
                action: () => {
                    if (window.clarity) {
                        window.clarity("event", "klickie_shared", { userId });
                    }
                    navigate(`/k/${userId}`);
                }
            }
        ];

        // Only add provider-specific buttons if user has services
        if (hasServices()) {
            actions.push({
                icon: <CalendarCheck className="w-6 h-6" />,
                title: "My Bookings as Provider",
                description: "View and manage appointments clients have booked with you",
                action: () => {
                    if (window.clarity) {
                        window.clarity("event", "view_bookings_pro", { userId });
                    }
                    navigate(`/pro-booking-history/${userId}`);
                },
                isProFeature: true
            });

            actions.push({
                icon: <PhoneCall className="w-6 h-6" />,
                title: "Callback Requests",
                description: "Manage callback requests from your clients",
                action: () => {
                    if (window.clarity) {
                        window.clarity("event", "view_callbacks", { userId });
                    }
                    navigate(`/callbacks/${userId}`);
                },
                isProFeature: true
            });
        }

        // Add remaining buttons that should always appear
        actions.push({
            icon: <BookMarked className="w-6 h-6" />,
            title: "My Bookings as Client",
            description: "View appointments you've booked with other providers",
            action: () => {
                if (window.clarity) {
                    window.clarity("event", "view_bookings_customer", { userId });
                }
                navigate(`/customer-booking-history/${userId}`);
            }
        });

        actions.push({
            icon: <Users className="w-6 h-6" />,
            title: "My Network",
            description: "View your connections and discover new professionals",
            action: () => {
                if (window.clarity) {
                    window.clarity("event", "view_connections", { userId });
                }
                navigate(`/network/${userId}`);
            }
        });

        return actions;
    };

    const settingsActions: ActionButton[] = [
        {
            icon: <UserCog className="w-6 h-6" />,
            title: "Profile and Services",
            description: "Update your profile information and services",
            action: () => {
                if (window.clarity) {
                    window.clarity("event", "modify_profile", { userId });
                }
                navigate(`/onboarding/${userId}`);
            }
        },
        {
            icon: <Clock className="w-6 h-6" />,
            title: "Availability",
            description: "Manage your working hours and schedule",
            action: () => {
                if (window.clarity) {
                    window.clarity("event", "modify_agenda", { userId });
                }
                navigate(`/schedule-availability/${userId}`);
            }
        },
        {
            icon: <Calendar className="w-6 h-6" />,
            title: "Calendar Integration",
            description: "Connect your personal calendar to sync your availability",
            action: handleCalendarSync
        },
        {
            icon: <BellRing className="w-6 h-6" />,
            title: "Notifications",
            description: "Manage your notification preferences",
            action: () => {
                if (window.clarity) {
                    window.clarity("event", "view_notifications", { userId });
                }
                navigate(`/notifications/${userId}`);
            }
        }
    ];

    // Get time of day for greeting
    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return "Good morning";
        if (hour < 18) return "Good afternoon";
        return "Good evening";
    };

    // Handle click outside chat assistant
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (chatRef.current && !chatRef.current.contains(event.target as Node) && chatAssistantOpen) {
                setChatAssistantOpen(false);
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [chatAssistantOpen]);

    // Function to open WhatsApp
    const openWhatsApp = (): void => {
        // Use the specific Klickie WhatsApp number
        // Remove spaces and '+' as WhatsApp URL uses the format without them
        const klickieNumber = "3197010257400"; // +31 97010257400 without spaces and '+'
        
        // Open WhatsApp with the Klickie number
        window.open(`https://wa.me/${klickieNumber}`, '_blank');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-[#1E2738] to-[#151c28] text-white p-4 md:p-8">
            <div className="max-w-2xl mx-auto">
                {/* Enhanced Header */}
                <div className="bg-[#1A202C]/80 backdrop-blur-sm rounded-xl p-6 mb-8 shadow-lg border border-gray-700/50 relative overflow-hidden">
                    <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#4895AA]/10 to-transparent rounded-bl-full"></div>
                    <div className="flex items-center relative z-10">
                        <div className="relative mr-5">
                            <div className="absolute inset-0 bg-gradient-to-br from-[#4895AA]/30 to-[#1A202C] rounded-full blur-sm transform scale-110"></div>
                            <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden bg-gray-800 flex items-center justify-center border border-gray-700/50 shadow-md relative z-10">
                                <img
                                    src={profileData?.photoUrl || klickieLogo}
                                    alt={profileData?.name || "Klickie"}
                                    className="w-full h-full object-cover"
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.src = klickieLogo;
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <h2 className="text-gray-400 text-sm mb-1">{getGreeting()}</h2>
                            <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">{profileData?.name || "User"}</h1>
                            <p className="text-gray-400">{profileData?.activity || "Professional"}</p>
                        </div>
                    </div>
                </div>

                {/* Primary Actions */}
                <div className="space-y-3 mb-6">
                    {getPrimaryActions().map((button, index) => (
                        <button
                            key={index}
                            onClick={button.action}
                            onMouseEnter={() => setHoveredButton(index)}
                            onMouseLeave={() => setHoveredButton(null)}
                            className={`w-full bg-gradient-to-br from-[#1A202C] to-[#141a24] border ${button.isProFeature ? 'border-[#FFD700]' : 'border-gray-700/50'} rounded-lg p-4 text-left hover:shadow-lg transition-all duration-300 relative group overflow-hidden`}
                        >
                            {button.isProFeature && (
                                <div className="absolute top-0 right-0 bg-[#FFD700] text-black text-xs font-bold py-1 px-2 rounded-bl-lg">
                                    PRO FEATURE
                                </div>
                            )}
                            <div className="absolute inset-0 bg-gradient-to-r from-[#4895AA]/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                            <div className="absolute bottom-0 left-0 h-0.5 w-0 bg-gradient-to-r from-[#4895AA]/50 to-transparent group-hover:w-full transition-all duration-500"></div>
                            
                            <div className="flex items-start relative z-10">
                                <div className={`bg-[#1E2738]/80 p-3 rounded-lg mr-4 shadow-md transition-all duration-300 ${hoveredButton === index ? 'scale-110 bg-[#4895AA]/20' : ''}`}>
                                    {button.icon}
                                </div>
                                <div>
                                    <div className="font-semibold text-lg">{button.title}</div>
                                    <div className="text-gray-400 text-sm">{button.description}</div>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>

                {/* Settings Dropdown */}
                <div className="mb-6">
                    <button
                        onClick={toggleSettings}
                        className="w-full bg-gradient-to-br from-[#1A202C] to-[#141a24] border border-gray-700/50 rounded-lg p-4 text-left hover:shadow-lg transition-all duration-300 relative group overflow-hidden"
                    >
                        <div className="absolute inset-0 bg-gradient-to-r from-[#4895AA]/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                        <div className="absolute bottom-0 left-0 h-0.5 w-0 bg-gradient-to-r from-[#4895AA]/50 to-transparent group-hover:w-full transition-all duration-500"></div>
                        
                        <div className="flex items-center justify-between relative z-10">
                            <div className="flex items-center">
                                <div className="bg-[#1E2738]/80 p-3 rounded-lg mr-4 shadow-md group-hover:bg-[#4895AA]/20 transition-all duration-300">
                                    <UserCog className="w-6 h-6" />
                                </div>
                                <div className="font-semibold text-lg">Setup Hub</div>
                            </div>
                            <div className="transition-transform duration-300">
                                {settingsOpen ? (
                                    <ChevronUp className="w-5 h-5" />
                                ) : (
                                    <ChevronDown className="w-5 h-5" />
                                )}
                            </div>
                        </div>
                    </button>
                    
                    {settingsOpen && (
                        <div className="mt-2 space-y-3 border-l-2 border-gray-700/50 ml-4 pl-4 animate-fadeIn">
                            {settingsActions.map((button, index) => (
                                <button
                                    key={index}
                                    onClick={button.action}
                                    onMouseEnter={() => setHoveredButton(index + 100)} // Offset to avoid conflict with primary actions
                                    onMouseLeave={() => setHoveredButton(null)}
                                    className="w-full bg-gradient-to-br from-[#1A202C] to-[#141a24] border border-gray-700/50 rounded-lg p-4 text-left hover:shadow-lg transition-all duration-300 relative group overflow-hidden"
                                >
                                    <div className="absolute inset-0 bg-gradient-to-r from-[#4895AA]/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                                    <div className="absolute bottom-0 left-0 h-0.5 w-0 bg-gradient-to-r from-[#4895AA]/50 to-transparent group-hover:w-full transition-all duration-500"></div>
                                    
                                    <div className="flex items-start relative z-10">
                                        <div className={`bg-[#1E2738]/80 p-3 rounded-lg mr-4 shadow-md transition-all duration-300 ${hoveredButton === index + 100 ? 'scale-110 bg-[#4895AA]/20' : ''}`}>
                                            {button.icon}
                                        </div>
                                        <div>
                                            <div className="font-semibold text-lg">{button.title}</div>
                                            <div className="text-gray-400 text-sm">{button.description}</div>
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}
                </div>

                {/* WhatsApp Chat Button */}
                <div className="fixed bottom-6 right-6 z-50">
                    <button 
                        onClick={() => setChatAssistantOpen(!chatAssistantOpen)}
                        className="w-14 h-14 rounded-full bg-gradient-to-r from-[#25D366] to-[#128C7E] flex items-center justify-center shadow-lg hover:shadow-xl transition-all duration-300 group relative overflow-hidden"
                    >
                        <div className="absolute inset-0 bg-black/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                        <div className="relative z-10">
                            {chatAssistantOpen ? 
                                <X className="w-6 h-6 text-white" /> : 
                                <MessageSquare className="w-6 h-6 text-white" />
                            }
                        </div>
                        <span className="absolute -top-10 right-0 bg-[#1A202C] text-white px-3 py-1 rounded-lg text-sm font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap shadow-md border border-gray-700/50">
                            WhatsApp Chat
                        </span>
                    </button>
                    
                    {chatAssistantOpen && (
                        <div 
                            ref={chatRef}
                            className="absolute bottom-20 right-0 w-80 md:w-96 bg-gradient-to-br from-[#1A202C] to-[#141a24] rounded-lg shadow-xl overflow-hidden animate-slideUp border border-gray-700/50"
                        >
                            <div className="bg-gradient-to-r from-[#25D366] to-[#128C7E] p-4 relative overflow-hidden">
                                <div className="absolute top-0 right-0 w-20 h-20 bg-white/10 rounded-bl-full"></div>
                                <div className="flex items-center relative z-10">
                                    <MessageSquare className="w-6 h-6 text-white mr-2" />
                                    <h3 className="text-white font-bold">Return to WhatsApp</h3>
                                </div>
                            </div>
                            
                            <div className="p-4">
                                <div className="bg-[#1E2738]/80 p-4 rounded-lg shadow-md border border-gray-700/50">
                                    <p className="text-gray-300 mb-3">
                                        Klickie is based in WhatsApp! Simply message "hi" to return to your conversation.
                                    </p>
                                    
                                    <div className="mb-4 bg-[#1A202C] p-3 rounded-lg border border-gray-700/50">
                                        <h4 className="font-medium text-white mb-2">With Klickie in WhatsApp you can:</h4>
                                        <ul className="text-gray-300 text-sm space-y-2">
                                            <li className="flex items-start">
                                                <span className="text-[#25D366] mr-2">•</span> Get updates on your bookings
                                            </li>
                                            <li className="flex items-start">
                                                <span className="text-[#25D366] mr-2">•</span> Receive helpful tips for your services
                                            </li>
                                            <li className="flex items-start">
                                                <span className="text-[#25D366] mr-2">•</span> Stay connected with your clients
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    <p className="text-gray-300 text-sm mb-4">
                                        <strong>Coming soon:</strong> Manage all your bookings, availability, and services directly from WhatsApp!
                                    </p>
                                    
                                    <button 
                                        onClick={openWhatsApp}
                                        className="w-full py-3 bg-gradient-to-r from-[#25D366] to-[#128C7E] text-white rounded-lg font-medium flex items-center justify-center hover:shadow-lg transition-all duration-300 relative group overflow-hidden"
                                    >
                                        <div className="absolute inset-0 bg-black/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                                        <div className="absolute bottom-0 left-0 h-0.5 w-0 bg-white/30 group-hover:w-full transition-all duration-500"></div>
                                        <div className="relative z-10 flex items-center">
                                            <MessageSquare className="w-5 h-5 mr-2" />
                                            Open WhatsApp
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}