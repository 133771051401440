import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Calendar, Clock } from 'lucide-react';

// Generic booking interface that works with both pro and customer bookings
interface BaseBooking {
    id: string;
    startTime: string;
    endTime: string;
    status: string;
    proName: string;
    clientName: string;
    serviceName: string;
}

interface ServiceDetails {
    name: string;
    price: number;
    duration: number;
    service_name: string;
    service_type: string;
    rule: string;
}

interface RescheduleData {
    reason: string;
    rescheduleStartTime: string;
    requestedByType: string;
    rescheduleDate: string;
    rescheduleRequestedBy: string;
    rescheduleEndTime: string;
}

interface Booking {
    id: string;
    bookingId: string;
    clientName: string;
    startTime: string;
    endTime: string;
    status: string;
    proName: string;
    serviceName: string;
    serviceDetails: ServiceDetails;
    rescheduleData: RescheduleData;
}

interface BookingCalendarProps {
    bookings: BaseBooking[];
    onBookingClick: (booking: BaseBooking) => void;
}

const BookingCalendar: React.FC<BookingCalendarProps> = ({ bookings, onBookingClick }) => {
  const [currentWeekStart, setCurrentWeekStart] = useState(getStartOfWeek(new Date()));
  
  // Get array of 7 days starting from currentWeekStart
  const weekDays = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(currentWeekStart);
    date.setDate(date.getDate() + i);
    return date;
  });

  // Group bookings by date
    const bookingsByDate: { [key: string]: BaseBooking[]; } = {};
    for (const booking of bookings) {
        const date = new Date(booking.startTime).toDateString();
        if (!bookingsByDate[date]) {
            bookingsByDate[date] = [];
        }
        bookingsByDate[date].push(booking);
    }

  const navigateWeek = (direction: 'prev' | 'next') => {
    const newDate = new Date(currentWeekStart);
    newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
    setCurrentWeekStart(newDate);
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'bg-green-500';
      case 'pending':
        return 'bg-yellow-500';
      case 'cancelled':
      case 'canceled':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  const formatTime = (dateString: string) => {
    return new Date(dateString).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {/* Calendar Header */}
      <div className="flex items-center justify-between p-4 border-b">
        <button
          onClick={() => navigateWeek('prev')}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        <h2 className="text-lg font-semibold">
          {currentWeekStart.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
        </h2>
        <button
          onClick={() => navigateWeek('next')}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>

      {/* Week View */}
      <div className="grid grid-cols-7 border-b">
        {weekDays.map((date) => {
          const isToday = date.toDateString() === new Date().toDateString();
          return (
            <div key={date.toISOString()} className="border-r last:border-r-0">
              <div className={`p-2 text-center border-b ${isToday ? 'bg-blue-50' : 'bg-gray-50'}`}>
                <div className="text-sm font-medium">
                  {date.toLocaleDateString('en-US', { weekday: 'short' })}
                </div>
                <div className={`text-2xl ${isToday ? 'text-blue-600 font-bold' : ''}`}>
                  {date.getDate()}
                </div>
              </div>
              <div className={`min-h-[200px] p-1 ${isToday ? 'bg-blue-50/50' : ''}`}>
                {bookingsByDate[date.toDateString()]?.map((booking) => (
                  <div
                    key={booking.id}
                    onClick={() => onBookingClick(booking)}
                    className={`mb-1 p-2 rounded cursor-pointer text-sm hover:opacity-90 ${
                      getStatusColor(booking.status)
                    } text-white ${isToday ? 'text-base' : ''}`}
                  >
                    <div className={`font-medium truncate ${isToday ? 'text-base' : ''}`}>
                      {booking.clientName || booking.proName}
                    </div>
                    <div className={`text-xs opacity-90 ${isToday ? 'text-sm' : ''}`}>
                      {formatTime(booking.startTime)} - {formatTime(booking.endTime)}
                    </div>
                    <div className={`text-xs truncate ${isToday ? 'text-sm' : ''}`}>
                      {booking.serviceName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Helper function to get the start of the week (Sunday)
function getStartOfWeek(date: Date): Date {
  const newDate = new Date(date);
  const day = newDate.getDay();
  newDate.setDate(newDate.getDate() - day);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export default BookingCalendar; 